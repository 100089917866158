import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import * as _ from 'lodash';

import { PageSection } from "../../../../components/templates/PageSection";
import NewModalStyle from "../../../../components/common/NewModalStyledComponents";
import Routes from "../../routes";
import { getExercise, updateExercise, addExercise } from "../../../../store/actions/exercises/exercise-actions";
import { BackButtonTBlue } from "../../../../components/ui/Buttons";
import { Spinner, FlexColumn } from "../../../../components/common/CommonStyledComponent";
import { getExercises } from "../../../../store/actions/exercises/exercises-actions";
import StyledSelect from "../../../../components/ui/Select";


const exTypeOptions = [
    {_id: 1, label: 'Repeats', value: 'reps'},
    {_id: 2, label: 'Seconds', value: 'sec'}
]

const NewExercise = ({ history, match }) => {
    const dispatch = useDispatch();

    const exercises = useSelector(state => state.exercises.exercises);
    const exercise = useSelector(state => state.exercise.exercise);
    const loading = useSelector(state => state.exercises.loading);

    const { addToast } = useToasts();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [repeates, setRepeates] = useState(0);
    const [sets, setSets] = useState(0);
    const [type, setType] = useState(exTypeOptions[0]);

    useEffect(() => {
        if (match.params.eid) {
            dispatch(getExercise(match.params.eid));
        }
        if (exercises.length === 0) {
            dispatch(getExercises(match.params.id));
        }
    }, []);

    useEffect(() => {
        if (exercise && match.params.eid) {
            setName(_.get(exercise, ['fields', 'name', 'mapValue', 'fields', 'he', 'stringValue']));
            setDescription(_.get(exercise, ['fields', 'description', 'mapValue', 'fields', 'he', 'stringValue']));
            setVideoUrl(_.get(exercise, ['fields', 'video', 'stringValue']));
            setRepeates(_.get(exercise, ['fields', 'reps', 'integerValue']));
            setSets(_.get(exercise, ['fields', 'sets', 'integerValue']));
            const t = _.get(exercise, ['fields', 'type', 'stringValue']);
            if (t) {
                setType(exTypeOptions.find((o)=> o.value === t));
            }
            else {
                setType(exTypeOptions[0]);
            }
        }
    }, [exercise]);


    const onNewExercise = () => {
        const e = {
            wid: match.params.id,
            name: name,
            description: description,
            video: videoUrl,
            reps: repeates,
            sets: sets,
            type: type.value
        }
        if (exercise && exercise.name && match.params.eid) {
            dispatch(updateExercise(exercise, e)).then(data => {
                if (!data.error) {
                    addToast("Exercise updated successfully", {appearance: "success", autoDismiss: true });
                    onBack();
                } else {
                    addToast("Error while updating exercise", {appearance: "error", autoDismiss: true });
                }
            });
        } else {
            e.index = exercises.length;
            dispatch(addExercise(e)).then(data => {
                if (!data.error) {
                    addToast("Exercise added successfully", {appearance: "success", autoDismiss: true });
                    onBack();
                } else {
                    addToast("Error while adding exercise", {appearance: "error", autoDismiss: true });
                }
            });
        }
    };

    const onTypeChanged = (v) => {
        setType(v);
    }

    const onBack = () => {
        if (history.length > 2) {
            history.goBack();
        }
        else {
            history.replace(Routes.Plans.plans);
        }
    }

    return (
        <PageSection>
            <NewModalStyle.Form>
                <NewModalStyle.Title>
                    <BackButtonTBlue onClick={onBack}>Back</BackButtonTBlue>
                    <h3 style={{ paddingLeft: '2rem' }}>
                        {exercise ? 'Edit Exercise' : 'New Exercise'}
                        {loading ? <Spinner size="1x" style={{ padding: '0px 10px' }}></Spinner> : null}
                    </h3>
                </NewModalStyle.Title>
                <FlexColumn style={{padding: '0 10%'}}>
                    <NewModalStyle.SubTitle>Name</NewModalStyle.SubTitle>
                    <NewModalStyle.StyledInputNew
                        value={name}
                        onChange={(e)=> setName(e.target.value)}>
                    </NewModalStyle.StyledInputNew>
                    <NewModalStyle.SubTitle>Description</NewModalStyle.SubTitle>
                    <NewModalStyle.StyledTextAreaNew
                        value={description}
                        onChange={(e)=> setDescription(e.target.value)}>
                    </NewModalStyle.StyledTextAreaNew>
                    <NewModalStyle.SubTitle>Video URL</NewModalStyle.SubTitle>
                    <NewModalStyle.StyledInputNew
                        value={videoUrl}
                        onChange={(e)=> setVideoUrl(e.target.value)}>
                    </NewModalStyle.StyledInputNew>
                    <NewModalStyle.RepeatesSets>
                        <div style={{ marginRight: "34px" }}>
                            <NewModalStyle.SubTitle>Repeates/Time</NewModalStyle.SubTitle>
                            <NewModalStyle.StyledInputNew
                                type={"number"}
                                min={0}
                                value={repeates}
                                onChange={(e) => setRepeates(e.target.value)}>
                            </NewModalStyle.StyledInputNew>
                        </div>
                        <div>
                            <NewModalStyle.SubTitle>Type</NewModalStyle.SubTitle>
                            <div style={{marginTop: '12px', marginBottom: '22px', marginRight: '34px', minWidth: '130px'}}>
                                <StyledSelect options={exTypeOptions}
                                onChange={onTypeChanged}
                                value={type}
                                defaultValue={exTypeOptions[0]}/>
                            </div>
                        </div>
                        <div>
                            <NewModalStyle.SubTitle>Sets</NewModalStyle.SubTitle>
                            <NewModalStyle.StyledInputNew
                                type={"number"}
                                min={0}
                                value={sets}
                                onChange={(e) => setSets(e.target.value)}>
                            </NewModalStyle.StyledInputNew>
                        </div>
                    </NewModalStyle.RepeatesSets>
                    <NewModalStyle.LButtoBlack
                        disabled={!name || !description || !videoUrl || !sets || !repeates}
                        onClick={() => { onNewExercise(exercise ? exercise._id : null) }}>
                        Save
                    </NewModalStyle.LButtoBlack>
                </FlexColumn>
            </NewModalStyle.Form>
        </PageSection>
    );
};

export default withRouter(NewExercise);
