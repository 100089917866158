import * as actionTypes from "../actionTypes";
import { authApi } from '../../api/auth-api';
import jwtDecode from 'jwt-decode';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (authData) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        ...authData
    };
};

export const authFailed = error => {
    return {
        type: actionTypes.AUTH_FAILED,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("userId");
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const logoutTimer = expirationMillis => {
    return dispatch => {
        setTimeout(() => dispatch(logout()), expirationMillis);
    };
};

export const authenticate = (username, password) => {
    return async dispatch => {
        dispatch(authStart());
        const {data, error, status} = await authApi.login(username, password);
        if (status === 200 && data) {
            localStorage.setItem('token', data.access_token);
            dispatch(authSuccess(data));
        }
        else {
            dispatch(authFailed(error));
        }
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem("token");
        if (!token) {
            dispatch(logout());
        } else {
            const decoded = jwtDecode(token);
            const expirationMillis = decoded.exp * 1000;
            if (expirationMillis <= new Date().getTime()) {
                dispatch(logout());
            } else {
                const userId = decoded.user_id;
                const authData = {
                    access_token: token,
                    user_id: userId
                };
                dispatch(authSuccess(authData));
                dispatch(logoutTimer(expirationMillis - new Date().getTime()));
            }
        }
    };
};
