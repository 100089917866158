import exercisesApi from "../../api/exercises-api";
import * as actionTypes from '../actionTypes';
import { ExerciseIdPath } from "./exercise-actions";
import * as _ from 'lodash';

export const getExercisesLoading = () => {
    return {
        type: actionTypes.GET_EXERCISES_LOADING
    };
};

export const getExercisesSuccess = exercises => {
    return {
        type: actionTypes.GET_EXERCISES_SUCCESS,
        payload: exercises
    };
};

export const getExercisesFail = err => {
    return {
        type: actionTypes.GET_EXERCISES_FAIL,
        error: err
    };
};

export const cleanExercises = () => {
    return {
        type: actionTypes.CLEAN_EXERCIES
    }
}

export const updateIndex = (exercises) => {
    return async (dispatch, getState) => {
        dispatch(getExercisesLoading());
        for (let i = 0; i < exercises.length; i++) {
            _.set(exercises[i], ['fields', 'index', 'integerValue'], i);
            await exercisesApi.updateExercise(getState().auth.token, exercises[i].name.replace(ExerciseIdPath, ''), exercises[i]);
        }
        return true;
    };
}

export const getExercises = (workoutId) => {
    return async (dispatch, getState) => {
        dispatch(getExercisesLoading());
        const { status, data, error } = await exercisesApi.fetchExercises(getState().auth.token, workoutId);
        if (status === 200) {
            const exercises = data;
            dispatch(getExercisesSuccess(exercises));
        } else {
            dispatch(getExercisesFail(error));
        }
    };
};

