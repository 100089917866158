import usersApi from "../../api/users-api";
import * as actionTypes from '../actionTypes';
import * as _ from 'lodash';
import { projectID } from "../../api/routes";

export const UserIdPath = 'projects/' + projectID +'/databases/(default)/documents/users/';

export const getUserLoading = () => {
    return {
        type: actionTypes.GET_USER_LOADING
    };
};

export const getUserSuccess = plan => {
    return {
        type: actionTypes.GET_USER_SUCCESS,
        payload: plan
    };
};

export const getUserFail = err => {
    return {
        type: actionTypes.GET_USER_FAIL,
        error: err
    };
};

export const updateUserLoading = () => {
    return {
        type: actionTypes.UPDATE_USER_LOADING
    };
};

export const updateUserSuccess = user => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        payload: user
    };
};

export const updateUserFail = err => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        error: err
    };
};

export const cleanUser = () => { 
    return {
        type: actionTypes.CLEAN_USER
    }
}

export const getUser = (id) => {
    return async (dispatch, getState) => {
        const u = getState().users.users.find(u => u.name === UserIdPath + id);
        if (u) {
            return dispatch(getUserSuccess(u));
        }
        dispatch(getUserLoading());
        const { status, data, error } = await usersApi.getUser(id);
        if (status === 200) {
            return dispatch(getUserSuccess(data));
        } else {
            return dispatch(getUserFail(error));
        }
    };
};

export const updateUserFromServer = (id, user, updateUser) => {
    return async (dispatch, getState) => {
        const newUser = {...user};
        _.set(newUser, ['fields', 'firstName', 'stringValue'], updateUser.firstName);
        _.set(newUser, ['fields', 'lastName', 'stringValue'], updateUser.lastName);
        _.set(newUser, ['fields', 'birthdate', 'timestampValue'], updateUser.dateOfBirth);
        _.set(newUser, ['fields', 'height', 'mapValue', 'fields', 'value', 'integerValue'], updateUser.height);
        _.set(newUser, ['fields', 'weight', 'mapValue', 'fields', 'value', 'integerValue'], updateUser.weight);
        _.set(newUser, ['fields', 'goal', 'stringValue'], updateUser.target);
        dispatch(updateUserLoading());
        const { status, data, error } = await usersApi.updateUser(getState().auth.token, id, newUser);
        if (status === 200) {
            return dispatch(updateUserSuccess(data));
        } else {
            return dispatch(updateUserFail(error));
        }
    };
};
