import React from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";

import {fullName} from "../../common/utils";
import RoundProfileImage from "../ui/RoundComponents";

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  height: 64px;
  align-items: center;
  border-bottom: 1px solid var(--neutral-40p);
  background: white;
  z-index: 1000;
  @media (max-width: 1290px) {
    padding: 0 0.5rem;
    align-items: flex-start;
  }
`;

const NavEnterpriseTitleContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 2.5;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const NavEnterpriseTitle = styled.h5`
  font-size: 20px;
  padding: 0;
  margin: 0 0 0 27px;
  align-self: center;
  max-width: 258px;
  width: 215px;

  @media (max-width: 1366px) {
    width: 190px;
    margin: 0 0 0 0.5rem;
    font-size: 18px;
  }

  @media (max-width: 1024px) {
    width: 155px;
    margin: 0 0 0 0.5rem;
    font-size: 16px;
  }
`;

const NavVerticalSeparator = styled.div`
  height: calc(100% - 20px);
  width: 1px;
  /* margin: 0 0.5rem; */
  background: var(--neutral-20p);
`;

const NavLogo = styled.img`
  height: 64px;
  white-space: nowrap;
  padding: 0rem 2.5rem;
`;

const NavName = styled.a`
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 37px;
  margin-bottom: 34px;
  margin-left: 46.5px;
  margin-right: 37.43px;
  text-decoration: none;
  color: var(--neutral-80p);
  font-size: 16px;
  line-height: 16px;
  font-family: InterUI-Medium;
`;

const NavBarContainer = styled.div`
  overflow: hidden;
  transition: all 0.3s ease-in;
  display: flex !important;
  flex-basis: auto;
  justify-content: space-around;
  height: 100%;
  flex-grow: 3;
  flex-shrink: 1;
`;

const NavBar = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  flex-direction: row;
  justify-content: start;
  display: flex;
  align-items: center;
  height: 100%;
  list-style: none;
  margin-left: 38.02px;
`;

const NavItem = styled.li`
  height: calc(100% - 3px);
  padding: 0;
  align-items: center;
  display: flex;
  margin: 0 10px;
  a {
    margin-right: 35px;
    height: 100%;
    display: flex;
    padding: 0;
    align-items: center;
    text-decoration: none;
    color: var(--neutral-80p);
    font-size: 16px;
    line-height: 16px;
    font-family: InterUI-Medium;
  }
  &:hover > a {
    color: var(--neutral-60p);
  }
  @media (max-width: 1100px) {
    a {
      font-size: 14px;
      line-height: 14px;
    }
  }
  @media (max-width: 1360px) {
    margin-right: 22px;
    a {
      font-size: 15px;
      line-height: 15px;
    }
  }
`;

const activeClassName = "active-nav";

const NavStyledNavLink = styled(NavLink).attrs({
  activeClassName: activeClassName
})`
  &.${activeClassName} {
    border-bottom: 5px solid var(--primary-blue);
  }
`;

const NavControlsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1290px) {
    padding: 0 1.5rem 0 0.5rem;
  }
`;

const NavControlsList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  align-items: baseline;
`;

const NavControlsListItem = styled.li`
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  list-style: none;
  height: 100%;
  align-items: end;
  padding-left: 0;
  padding-right: 1.375rem;
  background: transparent;

  @media (max-width: 1050px) {
    padding-right: 1rem;
  }

  & > img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    transform: translateY(-2px) scale(1.1, 1.1);
    transition: transform 0.1s;
    transition-timing-function: ease-out;
  }
`;

const NavControlItem = props => {
  return (
    <NavControlsListItem>
      <img src={props.icon} alt={props.alt} />
    </NavControlsListItem>
  );
};

const NavProfileButton = props => {
  const Container = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    background: transparent;
    border: none;
    color: var(--neutral-60p);
    padding: 0;
    & > * {
      margin: 0.25rem;
    }
    &:focus {
      outline: none;
    }
  `;

  return (
    <Container onClick={props.onClick}>
      <RoundProfileImage
        width={35}
        height={35}
        name={fullName(props.user)}
        bg={props.bg}
        color={props.color}
      />
    </Container>
  );
};

export default {
  Nav,
  NavEnterpriseTitleContainer,
  NavEnterpriseTitle,
  NavVerticalSeparator,
  NavLogo,
  NavName,
  NavBar,
  NavBarContainer,
  NavControlsContainer,
  NavItem,
  NavStyledNavLink,
  NavControlsList,
  NavControlsListItem,
  NavControlItem,
  NavProfileButton
};
