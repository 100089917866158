import React from "react";
import {withRouter} from "react-router-dom";

import Logo from "../../assets/logo/Logo.svg";

import Nav from "../../components/nav/NavigationStyledComponents";
import Routes from "../routes/routes";

const Navigation = () => {
  
  return (
    <Nav.Nav>
      <Nav.NavLogo src={Logo} alt="logo" />
      <Nav.NavVerticalSeparator />
      <Nav.NavBarContainer>
        <Nav.NavBar>
          <Nav.NavItem>
            <Nav.NavStyledNavLink to={Routes.Users.users} exact={false}>
              Users
            </Nav.NavStyledNavLink>
          </Nav.NavItem>
          <Nav.NavItem>
            <Nav.NavStyledNavLink to={Routes.Feeds.feeds} exact={false}>
              Feeds
            </Nav.NavStyledNavLink>
          </Nav.NavItem>
          <Nav.NavItem>
            <Nav.NavStyledNavLink to={Routes.Plans.plans} exact={false}>
              Plans
            </Nav.NavStyledNavLink>
          </Nav.NavItem>
          <Nav.NavItem>
            <Nav.NavStyledNavLink to={Routes.Messages.messages} exact={false}>
              Messages
            </Nav.NavStyledNavLink>
          </Nav.NavItem>
          <Nav.NavItem>
            <Nav.NavStyledNavLink to={Routes.Nutritions.nutritions} exact={false}>
              Nutrition
            </Nav.NavStyledNavLink>
          </Nav.NavItem>
        </Nav.NavBar>
      </Nav.NavBarContainer>
      <Nav.NavControlsContainer>
        <Nav.NavItem>
          <Nav.NavProfileButton
            user={{firstName: "Yuv", lastName: "T"}}
            bg="var(--primary-blue)"
            color="white"
          ></Nav.NavProfileButton>
          <Nav.NavStyledNavLink style={{margin: '0'}} to={Routes.Common.logout}>
            Logout
          </Nav.NavStyledNavLink>
        </Nav.NavItem>
      </Nav.NavControlsContainer>
    </Nav.Nav>
  );
};

export default withRouter(Navigation);
