import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import * as _ from 'lodash';

import { StyledInput } from "../../../components/common/CommonStyledComponent";
import MessagesStyle from "../../../components/common/FeedsMessagesStyledComponents";
import MessagesTable from "../../../components/tables/Messages/MessagesTable";
import { getMessages, deleteMessage, addMessage, MessageIdPath } from "../../../store/actions/messages/messages-actions";
import MessageDeletePopup from "./MessageDeletePopup/MessageDeletePopup";

const Messages = () => {
    const dispatch = useDispatch();

    const messages = useSelector(state => state.messages);

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [messageId, setMessageId] = useState(null);
    const { addToast } = useToasts();

    useEffect(() => {
        dispatch(getMessages());
    }, []);

    const onSend = () => {
        const newMessage = {
            title: title,
            message: message
        }
        dispatch(addMessage(newMessage)).then(data => {
            if (!data.error) {
                setTitle('');
                setMessage('');
                dispatch(getMessages());
                addToast("Message saved Successfully", { appearance: "success", autoDismiss: true });
            } else {
                addToast("Error while saving a message", { appearance: "error", autoDismiss: true });
            }
        });
    };

    const onResend = (row) => {
        setTitle(_.get(row.props, ['fields', 'title', 'mapValue', "fields", "he", "stringValue"]));
        setMessage(_.get(row.props, ['fields', 'body', 'mapValue', "fields", "he", "stringValue"]));
    }

    const onDelete = (row) => {
        const name = row.props.name.replace(MessageIdPath, '');
        setMessageId(name);
        setShowDeletePopup(true);
    };

    const onDeletePopupButton = () => {
        dispatch(deleteMessage(messageId)).then(data => {
            if (!data.error) {
                dispatch(getMessages());
                addToast("Deleted successfully", { appearance: "success", autoDismiss: true });
            } else {
                addToast("Error while deleting", { appearance: "error", autoDismiss: true });
            }
            setShowDeletePopup(false);
            setMessageId(null);
        });
    }

    return (
        <MessagesStyle.All>
            <MessagesStyle.Form>
                <MessagesStyle.SubTitle style={{ marginTop: "34.75px" }}>
                    Title
                </MessagesStyle.SubTitle>
                <StyledInput
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}>
                </StyledInput>
                <MessagesStyle.SubTitle>Message</MessagesStyle.SubTitle>
                <MessagesStyle.StyledTextAreaNew
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}>
                </MessagesStyle.StyledTextAreaNew>
                <MessagesStyle.LButtoBlack
                    disabled={title && message ? false : true}
                    onClick={onSend}>
                    Send
                </MessagesStyle.LButtoBlack>
            </MessagesStyle.Form>
            <MessagesStyle.tableTitle>Recent Messages</MessagesStyle.tableTitle>
            <MessagesTable
                loading={messages.loading}
                data={messages.messages}
                defaultPageSize={10}
                minRows={10}
                onRemove={onDelete}
                onResend={onResend}></MessagesTable>
            {showDeletePopup ?
                <MessageDeletePopup onDelete={onDeletePopupButton} closeModal={() => setShowDeletePopup(false)} /> : null}
        </MessagesStyle.All>
    );
};

export default Messages;
