import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import * as _ from 'lodash';

import { PageSection } from "../../../components/templates/PageSection";
import PlansStyle from "./PlansStyledComponents";
import { Spinner, FlexRow, Separator } from "../../../components/common/CommonStyledComponent";
import WorkoutsTable from "../../../components/tables/Workouts/WorkoutsTable";
import { DeleteModal } from "./DeleteModal";
import Routes from "../routes";
import caretRight from "../../../assets/images/icons/caret-right.svg";
import { getPlans, deletePlan } from "../../../store/actions/plans/plans-actions";
import { selectPlan, deselectPlan, PlanIdPath } from "../../../store/actions/plans/plan-actions";
import { getWorkouts } from "../../../store/actions/workouts/workouts-actions";
import { deleteWorkout, WorkoutIdPath } from "../../../store/actions/workouts/workout-actions";
import { DeleteWorkoutPopup } from "./Workouts/DeleteWorkoutPopup";


const PlanLevels = {
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    advanced: 'Advanced',
    "all_levels": 'All levels'
}

const PlanPlaces = {
    home: "Home",
    gym: "Gym"
}

const Plans = ({ history }) => {
    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const plans = useSelector(state => state.plans);
    const workouts = useSelector(state => state.workouts);

    const plan = useSelector(state => state.plan.plan);

    const [showModal, setShowModal] = useState(false);
    const [showDeleteWorkout, setShowDeleteWorkout] = useState(false);
    const [toDeleteWorkout, setToDeleteWorkout] = useState(null);
    const [search, setSearch] = useState('');
    
    useEffect(() => {
        dispatch(getPlans());
    }, []);

    useEffect(() => {
        if (search) {
            dispatch(deselectPlan());
        }
    }, [search]);

    useEffect(() => {
        if (plan) {
            dispatch(getWorkouts(plan.name.replace(PlanIdPath, '')));
        }
    }, [plan]);

    const closeModal = () => {
        setShowModal(false);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const openDeleteWorkoutPoup = (props) => {
        setShowDeleteWorkout(true);
        setToDeleteWorkout(props.props.name);
    }

    const onNewPlan = () => {
        dispatch(deselectPlan());
        history.push(Routes.Plans.newPlan);
    }

    const onEditWorkout = (props) => {
        const wid = props.props.name.replace(WorkoutIdPath, '');
        history.push(Routes.Plans.plans + "/" + plan.name.replace(PlanIdPath, '') + "/editWorkout/" + wid);
    };

    const onDeletePlan = (id) => {
        dispatch(deletePlan(plan.name.replace(PlanIdPath, ''))).then(data => {
            if (!data.error) {
                dispatch(deselectPlan());
                dispatch(getPlans());
                addToast("Plan deleted", { appearance: "success", autoDismiss: true });
            } else {
                addToast("Error while deleting plan", { appearance: "error", autoDismiss: true });
            }
            setShowModal(false);
        });
    };

    const onDeleteWorkout = () => {
        dispatch(deleteWorkout(toDeleteWorkout.replace(WorkoutIdPath, ''))).then((data) => {
            if (!data.error) {
                addToast("Deleted Successfully", { appearance: "success", autoDismiss: true });
                dispatch(getWorkouts(plan.name.replace(PlanIdPath, '')));
            } else {
                addToast("not deleted!", { appearance: "error", autoDismiss: true });
            }
            setShowDeleteWorkout(false);
            setToDeleteWorkout(null);
        });
    };

    const openWorkouts = (id) => {
        const p = plans.plans.find(p => p.name === id);
        dispatch(selectPlan(p));
    };

    const onNewWorkout = () => {
        history.push(Routes.Plans.plans + '/' + plan.name.replace(PlanIdPath, '') + '/newWorkout');
    }

    const editPlan = (p) => {
        history.push({ pathname: Routes.Plans.editPlan });
    };

    return (
        <PageSection style={{ height: "calc(100% - 65px)" }}>
            {showModal && plan ?
                <DeleteModal
                    onDelete={() => onDeletePlan(plan._id)}
                    closeModal={closeModal}>
                </DeleteModal> : null}
            {showDeleteWorkout ?
                <DeleteWorkoutPopup
                    onDelete={() => onDeleteWorkout(toDeleteWorkout)}
                    closeModal={() => setShowDeleteWorkout(false)} /> : null}
            <PlansStyle.Plans>
                <PlansStyle.InputAndPlans>
                    <PlansStyle.Search>
                        <PlansStyle.StyledInputNew
                            placeholder="Search by name"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}>
                        </PlansStyle.StyledInputNew>
                        <PlansStyle.LButtonNew style={{ maxWidth: "77px" }} onClick={onNewPlan}>
                            New
                        </PlansStyle.LButtonNew>
                    </PlansStyle.Search>
                    <PlansStyle.PlansList>
                        {plans && plans.plans && !plans.loading ? (
                            plans.plans.filter((p) => _.get(p, ["fields", "name", "mapValue", "fields", "he", "stringValue"], '').includes(search)).map(onePlan => (
                                <PlansStyle.PlansListItem className={plan && onePlan.name === plan.name ? 'selected' : ''}
                                    key={onePlan.name}
                                    onClick={() => openWorkouts(onePlan.name)}>
                                    <PlansStyle.PlaceLevelPlanCaret>
                                        <PlansStyle.Details>
                                            <span style={{ margin: "5px 0px 15px 0px ", fontFamily: "InterUI-SemiBold", fontSize: "18px" }}>
                                                {_.get(onePlan, ["fields", "name", "mapValue", "fields", "he", "stringValue"])}
                                            </span>
                                            <PlansStyle.DetailsOfList>
                                                <FlexRow style={{ color: "#868686", fontSize: "14px" }}>
                                                    Level:
                                                    <div style={{ fontFamily: "InterUI-Medium", color: "#565656", fontSize: "14px", padding: '0 3px' }}>
                                                        {PlanLevels[_.get(onePlan, ["fields", "level", "stringValue"], 'all_levels')]}
                                                    </div>
                                                </FlexRow>
                                                <FlexRow style={{ marginLeft: "16.17px", color: "#868686" }}>
                                                    Place:
                                                    <div style={{ fontFamily: "InterUI-Medium", color: "#565656", padding: '0 3px' }}>
                                                        {PlanPlaces[_.get(onePlan, ["fields", "location", "stringValue"], 'home')]}
                                                    </div>
                                                </FlexRow>
                                            </PlansStyle.DetailsOfList>
                                        </PlansStyle.Details>
                                        <img src={caretRight} alt="caret-right" />
                                    </PlansStyle.PlaceLevelPlanCaret>
                                </PlansStyle.PlansListItem>
                            ))) :
                            <FlexRow style={{ flex: 1, alignItems: 'center', justifyContent: 'center', padding: '30px 0' }}>
                                <Spinner></Spinner>
                            </FlexRow>}
                    </PlansStyle.PlansList>
                </PlansStyle.InputAndPlans>
                {plan ?
                    <PlansStyle.DetailsOnePlan>
                        <PlansStyle.PlanTitle>
                            {_.get(plan, ["fields", "name", "mapValue", "fields", "he", "stringValue"])}
                            <div>
                                <PlansStyle.LButtonNew style={{ marginRight: "24px" }} onClick={() => editPlan(plan)}>
                                    Edit
                                </PlansStyle.LButtonNew>
                                <PlansStyle.LButtonNew onClick={() => openModal()} style={{ background: "red" }} >
                                    Delete
                                </PlansStyle.LButtonNew>
                            </div>
                        </PlansStyle.PlanTitle>
                        <Separator/>
                        <PlansStyle.WorkoutBox>
                            <PlansStyle.WorkOutTitle>
                                <FlexRow style={{ alignItems: 'center' }}>
                                    <h5 style={{ margin: 0, padding: '5px 10px 5px 0' }}>Workouts:</h5>
                                    {workouts.loading ? <Spinner size="1x"></Spinner> : null}
                                </FlexRow>
                                <PlansStyle.LButtonNew onClick={onNewWorkout}>New workout</PlansStyle.LButtonNew>
                            </PlansStyle.WorkOutTitle>
                            <WorkoutsTable
                                loading={workouts.loading}
                                data={workouts.workouts}
                                defaultPageSize={6}
                                minRows={6}
                                onEdit={onEditWorkout}
                                onRemove={openDeleteWorkoutPoup}>
                            </WorkoutsTable>
                        </PlansStyle.WorkoutBox>
                    </PlansStyle.DetailsOnePlan>
                    :
                    <FlexRow style={{ flex: 1, alignItems: 'center', justifyContent: 'center', color: 'var(--neutral-50p)' }}>
                        Please select the plan
                    </FlexRow>}
            </PlansStyle.Plans>
        </PageSection>
    );
};

export default withRouter(Plans);
