import React from "react";
import { Redirect } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { logout } from "../../../store/actions/auth/auth-actions";

const Logout = () => {

    const dispatch = useDispatch();

    dispatch(logout());

    return <Redirect to={'/login'} />;
};

export default React.memo(Logout);
