import styled, {keyframes} from "styled-components";

const fade = keyframes`
	from {
		opacity: 0.01;
	}
	to {
		opacity: 1;
	}
`;

export const PageSection = styled.div`
  animation: ${fade} 0.3s linear;
  padding-top: 65px;

  @media (min-width: 990px) {
    padding-top: 65px;
  }
`;
