import {number, object} from 'yup';

export const errorSchema = object().shape({
	response: object().required().default({status: 300}).shape({
		status: number().required().integer().default(300)
	})
});

export const updateResponseSchema = object().shape({
	ok: number().default(0)
});

export const metadataSchema = object().shape({
	page: number().required().default(1),
	total: number().required().default(0)
}).required().default({
	page: 1,
	totla: 0
});
