import React, {useMemo} from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../../../styles/table-css-override.css";
import PropTypes from "prop-types";

import {ColumnDefinitions, ActionsContextMenu} from "./UsersTableComponents";
import {
  TablePagination,
  TableStyles,
  ActionCell
} from "../CommonTableComponents";

const UsersTable = (props) => {
  // Table setup
  
  const tableDefaults = {
    ...props,
    minRows: props.data.length,
    PaginationComponent: TablePagination
  };
  const idColumn = useMemo(() => ColumnDefinitions.IdColumn(), [ColumnDefinitions.IdColumn]);

  const emailColumn = useMemo(() => ColumnDefinitions.EmailColumn(), [ColumnDefinitions.EmailColumn]);

  const nameColumn = useMemo(() => ColumnDefinitions.NameColumn(), [ColumnDefinitions.NameColumn]);

  const dateOfBirthColumn = useMemo(() => ColumnDefinitions.DateOfBirthColumn(),[ColumnDefinitions.DateOfBirth]);
  
  const heightColumn = useMemo(() => ColumnDefinitions.HeightColumn(), [ColumnDefinitions.HeightColumn]);

  const weightColumn = useMemo(() => ColumnDefinitions.WeightColumn(), [ColumnDefinitions.WeightColumn]);

  const targetColumn = useMemo(() => ColumnDefinitions.TargetColumn(), [ColumnDefinitions.TargetColumn]);

  const subscriptionstatusColumn = useMemo(() => ColumnDefinitions.SubscriptionstatusColumn(),[ColumnDefinitions.SubscriptionstatusColumn]);

  const actionColumn = useMemo(() =>props.showActions? ColumnDefinitions.ActionColumn(props.ActionCellComponent ? props.ActionCellComponent : ActionCell): [],
  [ColumnDefinitions.ActionColumn,props.showActions,props.ActionCellComponent]);

  const columns = [
    ...idColumn,
    ...emailColumn,
    ...nameColumn,
    ...dateOfBirthColumn,
    ...heightColumn,
    ...weightColumn,
    ...targetColumn,
    ...subscriptionstatusColumn,
    ...actionColumn
  ];

  return (
    <>
      <ReactTable
        {...tableDefaults}
        loading={props.loading}
        style={TableStyles.tableStyle}
        data={props.data}
        columns={columns}
        manual
      />
      <ActionsContextMenu onEdit={props.onEdit} />
    </>
  );
};

UsersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  defaultPageSize: PropTypes.number,
  minRows: PropTypes.number,
  loading: PropTypes.bool,
  ActionCellComponent: PropTypes.element,
  showActions: PropTypes.bool,
  onEdit: PropTypes.func
};

UsersTable.defaultProps = {
  showActions: true
};

export default UsersTable;
