import axiosInstance from "../../axios";
import ServerRoutes from "./routes/index";
import { errorSchema } from "./schemas/common-schemas";


const fetchMessages = async (token) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const { status, data, error } = await axiosInstance.get(ServerRoutes.getMessages + '?pageSize=100', { headers: headers });
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const deleteMessage = async (token, id) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const {status, data, error} = await axiosInstance.delete(ServerRoutes.getMessages + '/' + id, {headers: headers});
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const addMessage = async (token, newMessage) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const { status, data, error } = await axiosInstance.post(ServerRoutes.getMessages, newMessage, {headers: headers});
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "message not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};


const messagesApi = {
    fetchMessages: fetchMessages,
    deleteMessage: deleteMessage,
    addMessage: addMessage,
};

export default messagesApi;
