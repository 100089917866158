import React from 'react'
import {Main,ButtonsTypesDiv,NewMealTitle} from './NutritionsStyle'
import Routes from '../routes'
import NewMealForm from './NewMealForm'
import { BackButtonTBlue } from "../../../components/ui/Buttons";
const NewMeal = (props) => {
    return (
        <Main>
            <ButtonsTypesDiv style={{justifyContent: 'flex-start'}}>
                    <BackButtonTBlue onClick={() => props.history.replace(Routes.Nutritions.nutritions)}>
                        Back
                    </BackButtonTBlue>
                    <NewMealTitle>New Meal</NewMealTitle>
            </ButtonsTypesDiv>
            <NewMealForm />
        </Main>)
}
export default NewMeal