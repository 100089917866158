import React from "react";
import PropTypes from "prop-types";
import { Menu, Item } from "react-contexify";
import * as _ from 'lodash';
import moment from 'moment';

import "react-contexify/dist/ReactContexify.min.css";
import "../../../styles/context-menu-css-override.css";

import { TableStyles } from "../CommonTableComponents";

const getDateColumn = () => {
  return [
    {
      ...TableStyles.generalColumnStyle,
      id: "date",
      Header: "Date",
      accessor: (m)=> new Date(_.get(m, ['fields', 'sent', 'timestampValue'])),
      style: { 
        whiteSpace: "unset", 
        wordBreak: "break-word", 
        color: "#565656",
        display: 'flex',
        alignItems: 'center'
      },
      Cell: props => moment(props.value).format("HH:mm DD-MM-YYYY"),
      maxWidth: 200,
      resizable: false
    }
  ];
};

const getMessageColumn = () => {
  return [
    {
      ...TableStyles.generalColumnStyle,
      id: "message",
      Header: "Message",
      accessor: (m)=> _.get(m, ['fields', 'body', 'mapValue', "fields", "he", "stringValue"]),
      style: { 
        whiteSpace: "unset", 
        wordBreak: "break-word", 
        color: "#565656",
        display: 'flex',
        alignItems: 'center'
      },
      Cell: props => props.value,
      resizable: false
    }
  ];
};

const getTitleColumn = () => {
  return [
    {
      ...TableStyles.generalColumnStyle,
      id: "title",
      Header: "Title",
      accessor: (m)=> _.get(m, ['fields', 'title', 'mapValue', "fields", "he", "stringValue"]),
      style: { 
        whiteSpace: "unset", 
        wordBreak: "break-word", 
        color: "#565656",
        display: 'flex',
        alignItems: 'center'
      },
      Cell: props => props.value,
      maxWidth: 100,
      resizable: false
    }
  ];
};

const getActionColumn = ActionsComponent => {
  return [
    {
      ...TableStyles.generalColumnStyle,
      id: "Actions",
      Header: "Actions",
      accessor: "actions",
      Cell: props => <>{<ActionsComponent {...props} />}</>,
      maxWidth: 80,
      sortable: false,
      resizable: false
    }
  ];
};
export const ColumnDefinitions = {
  DateColumn: getDateColumn,
  MessageColumn: getMessageColumn,
  TitleColumn: getTitleColumn,
  ActionColumn: getActionColumn
};

export const ActionsContextMenu = props => {
  return (
    <Menu id="action_menu" className={"context-menu"}>
      <Item
        style={{ fontFamily: "InterUI-Regular" }}
        className={"context-menu-item"}
        onClick={props.onResend}>
        Send again
      </Item>
      <Item
        style={{ fontFamily: "InterUI-Regular" }}
        className={"context-menu-item"}
        onClick={props.onRemove}>
        Delete
      </Item>
    </Menu>
  );
};

ActionsContextMenu.propTypes = {
  onRemove: PropTypes.func,
  onResend: PropTypes.func
};
