// export const projectID = 'yuvalgym'; // DEV
export const projectID = 'yuvalgym-ad810'; // PROD

const ServerRoutes = {
	// Account urls
	userInfo: '/login',
	getUsers: 'https://firestore.googleapis.com/v1/projects/' + projectID +'/databases/(default)/documents/users',
	getFeeds: 'https://' + projectID +'.firebaseio.com/feed.json',
	updateFeed: 'https://' + projectID +'.firebaseio.com/feed/',

	getMessages: 'https://firestore.googleapis.com/v1/projects/' + projectID +'/databases/(default)/documents/message_history',
	getNutritions: 'https://firestore.googleapis.com/v1/projects/' + projectID +'/databases/(default)/documents/meals',
	sendPush: 'https://fcm.googleapis.com/v1/projects/' + projectID +'/messages:send',

	getPlans: 'https://firestore.googleapis.com/v1/projects/' + projectID +'/databases/(default)/documents/plans',
	getWorkouts: 'https://firestore.googleapis.com/v1/projects/' + projectID +'/databases/(default)/documents/workouts',
	getExercises: 'https://firestore.googleapis.com/v1/projects/' + projectID +'/databases/(default)/documents/exercises',

	globalQuery: 'https://firestore.googleapis.com/v1/projects/' + projectID +'/databases/(default)/documents:runQuery'
}

export default ServerRoutes;