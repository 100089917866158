import nutritionsApi from "../../api/nutritions-api";
import * as actionTypes from '../actionTypes';
import { projectID } from "../../api/routes";
import * as _ from 'lodash';
export const NutritionIdPath = 'projects/' + projectID +'/databases/(default)/documents/meals/';

export const updateMealLoading = () => {
    return {
        type: actionTypes.UPDATE_MEAL_LOADING
    };
};

export const updateMealSuccess = meal => {
    return {
        type: actionTypes.UPDATE_MEAL_SUCCESS,
        payload: meal
    };
};

export const updateMealFail = err => {
    return {
        type: actionTypes.UPDATE_MEAL_FAIL,
        error: err
    };
};

export const deleteMealLoading = () => {
    return {
        type: actionTypes.DELETE_MEAL_LOADING
    };
};

export const deleteMealSuccess = () => {
    return {
        type: actionTypes.DELETE_MEAL_SUCCESS,
    };
};

export const deleteMealFail = err => {
    return {
        type: actionTypes.DELETE_MEAL_FAIL,
        error: err
    };
};

export const getMealLoading = () => {
    return {
        type: actionTypes.GET_MEAL_LOADING
    };
};

export const getMealSuccess = meal => {
    return {
        type: actionTypes.GET_MEAL_SUCCESS,
        payload: meal
    };
};

export const getMealFail = err => {
    return {
        type: actionTypes.GET_MEAL_FAIL,
        error: err
    };
};

export const cleanMeal = () => { 
    return {
        type: actionTypes.CLEAN_MEAL
    }
}

export const getMeal = (id) => {
    return async (dispatch, getState) => {
        const n = getState().nutritions.nutritions.find(n => n.name === NutritionIdPath + id);
        if (n) {
            return dispatch(getMealSuccess(n));
        }
        dispatch(getMealLoading());
        const { status, data, error } = await nutritionsApi.getMeal(getState().auth.token,id);
        if (status === 200) {
            return dispatch(getMealSuccess(data));
        } else {
            return dispatch(getMealFail(error));
        }
    };
};



export const deleteMeal = (id) => {
    return async (dispatch, getState) => {
        dispatch(deleteMealLoading());
        const { status, error } = await nutritionsApi.deleteMeal(getState().auth.token, id);
        if (status === 200) {
            return dispatch(deleteMealSuccess());
        } else {
            return dispatch(deleteMealFail(error));
        }
    };
};

export const updateMealFromServer = (id, meal, updateMeal) => {
    return async (dispatch, getState) => {
        _.set(meal, [ 'fields', 'name', 'mapValue', 'fields', 'en', 'stringValue'], updateMeal.name);
        _.set(meal, [ 'fields', 'name', 'mapValue', 'fields', 'he', 'stringValue'], updateMeal.name);
        _.set(meal, [ 'fields', 'nutritionValues', 'mapValue', 'fields', 'calories', 'integerValue'], parseInt(updateMeal.nutritionValues.calories));
        _.set(meal, [ 'fields', 'nutritionValues', 'mapValue', 'fields', 'carbs', 'integerValue'], parseInt(updateMeal.nutritionValues.carbs));
        _.set(meal, [ 'fields', 'nutritionValues', 'mapValue', 'fields', 'fats', 'integerValue'], parseInt(updateMeal.nutritionValues.fats));
        _.set(meal, [ 'fields', 'nutritionValues', 'mapValue', 'fields', 'proteins', 'integerValue'], parseInt(updateMeal.nutritionValues.proteins));
        _.set(meal, [ 'fields', 'contents', 'stringValue'], updateMeal.contents);
        _.set(meal, [ 'fields', 'type', 'stringValue'], updateMeal.type);
        _.set(meal, [ 'fields', 'image', 'stringValue'], updateMeal.image);
        _.set(meal, [ 'fields', 'prepareTime', 'integerValue'], parseInt(updateMeal.prepareTime));
        _.set(meal, [ 'fields', 'description', 'mapValue', 'fields', 'he', 'stringValue'], updateMeal.description);
        dispatch(updateMealLoading());
        const { status, data, error } = await nutritionsApi.updateMeal(getState().auth.token, id, meal);
        if (status === 200) {
            return dispatch(updateMealSuccess(data));
        } else {
            return dispatch(updateMealFail(error));
        }
    };
};
