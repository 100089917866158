import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';


const PrivateRoute = ({ component: Component, ...rest }) => {
    const auth = useSelector(state => state.auth);
    let route = null;
    if (!localStorage.getItem('token')) {
        route = <Redirect to={'/login'}/>
    }
    else if (localStorage.getItem('token') && auth.token ) {
        route = <Route {...rest} render={props => (
            localStorage.getItem('token') && auth.token ? <Component {...props} /> : null
        )} />
    }
    return route;
}

export default PrivateRoute;