import workoutsApi from "../../api/workouts-api";
import * as actionTypes from '../actionTypes';
import * as _ from 'lodash';
import { projectID } from "../../api/routes";

export const WorkoutIdPath = 'projects/' + projectID +'/databases/(default)/documents/workouts/';

export const getWorkoutLoading = () => {
    return {
        type: actionTypes.GET_WORKOUT_LOADING
    };
};

export const getWorkoutSuccess = workout => {
    return {
        type: actionTypes.GET_WORKOUT_SUCCESS,
        payload: workout
    };
};

export const getWorkoutFail = err => {
    return {
        type: actionTypes.GET_WORKOUT_FAIL,
        error: err
    };
};

export const updateWorkoutLoading = () => {
    return {
        type: actionTypes.UPDATE_WORKOUT_LOADING
    };
};

export const updateWorkoutSuccess = (workout) => {
    return {
        type: actionTypes.UPDATE_WORKOUT_SUCCESS,
        workout: workout
    };
};

export const updateWorkoutFail = err => {
    return {
        type: actionTypes.UPDATE_WORKOUT_FAIL,
        error: err
    };
};

export const deleteWorkoutLoading = () => {
    return {
        type: actionTypes.DELETE_WORKOUT_LOADING
    };
};

export const deleteWorkoutSuccess = () => {
    return {
        type: actionTypes.DELETE_WORKOUT_SUCCESS,
    };
};

export const deleteWorkoutFail = err => {
    return {
        type: actionTypes.DELETE_WORKOUT_FAIL,
        error: err
    };
};

export const addWorkoutLoading = () => {
    return {
        type: actionTypes.ADD_WORKOUT_LOADING
    };
};

export const addWorkoutSuccess = (workout) => {
    return {
        type: actionTypes.ADD_WORKOUT_SUCCESS,
        workout: workout
    };
};

export const addWorkoutFail = err => {
    return {
        type: actionTypes.ADD_WORKOUT_FAIL,
        error: err
    };
};

export const cleanWorkout = () => {
    return {
        type: actionTypes.CLEAN_WORKOUT,
    };
}

export const addWorkout = (newWorkout) => {
    return async (dispatch, getState) => {
        dispatch(addWorkoutLoading());
        const w = {
            "fields": { 
                "day": { "integerValue": newWorkout.day }, 
                "description": { 
                    "mapValue": { 
                        "fields": { 
                            "he": { "stringValue": newWorkout.description }
                        } 
                    } 
                }, 
                "week": { "integerValue": newWorkout.week }, 
                "pid": { "stringValue": newWorkout.pid }, 
                "name": { 
                    "mapValue": { 
                        "fields": { 
                            "he": { "stringValue": newWorkout.name } 
                        } 
                    } 
                } 
            }
        }
        const { status, data, error } = await workoutsApi.addWorkout(getState().auth.token, w);
        if (status === 200) {
            return dispatch(addWorkoutSuccess(data));
        } else {
            return dispatch(addWorkoutFail(error));
        }
    };
};

export const deleteWorkout = (id) => {
    return async (dispatch, getState) => {
        dispatch(deleteWorkoutLoading());
        const { status, error } = await workoutsApi.deleteWorkout(getState().auth.token, id);
        if (status === 200) {
            return dispatch(deleteWorkoutSuccess());
        } else {
            return dispatch(deleteWorkoutFail(error));
        }
    };
};

export const getWorkout = id => {
    return async (dispatch, getState) => {
        const w = getState().workouts.workouts.find((ww) => ww.name.replace(WorkoutIdPath, '') === id);
        if (w) {
            return dispatch(getWorkoutSuccess(w));
        }
        dispatch(getWorkoutLoading());
        const { status, data, error } = await workoutsApi.fetchWorkout(getState().auth.token, id);
        if (status === 200) {
            return dispatch(getWorkoutSuccess(data));
        } else {
            return dispatch(getWorkoutFail(error));
        }
    };
};

export const updateWorkout = (workout, w) => {
    return async (dispatch, getState) => {
        _.set(workout, ['fields', 'name', 'mapValue', 'fields', 'he', 'stringValue'], w.name);
        _.set(workout, ['fields', 'description', 'mapValue', 'fields', 'he', 'stringValue'], w.description);
        _.set(workout, ['fields', 'week', 'integerValue'], w.week);
        _.set(workout, ['fields', 'day', 'integerValue'], w.day);
        dispatch(updateWorkoutLoading());
        const { status, data, error } = await workoutsApi.updateWorkout(getState().auth.token, workout.name.replace(WorkoutIdPath, ''), workout);
        if (status === 200) {
            return dispatch(updateWorkoutSuccess(data));
        } else {
            return dispatch(updateWorkoutFail(error));
        }
    };
};
