import usersApi from "../../api/users-api";
import * as actionTypes from '../actionTypes';

export const getUsersLoading = () => {
    return {
        type: actionTypes.GET_USERS_LOADING
    };
};

export const getUsersSuccess = (users, nextPageToken) => {
    return {
        type: actionTypes.GET_USERS_SUCCESS,
        payload: users,
        nextPageToken: nextPageToken
    };
};

export const getUsersFail = err => {
    return {
        type: actionTypes.GET_USERS_FAIL,
        error: err
    };
};

export const cleanUsers = () => {
    return {
        type: actionTypes.CLEAN_USERS
    }
}

export const getUsersFromServer = (nextPageToken) => {
    return async (dispatch, getState)  => {
        dispatch(getUsersLoading());
        const { status, data, error } = await usersApi.fetchUsers(getState().auth.token, nextPageToken);
        if (status === 200) {
            dispatch(getUsersSuccess(data.documents, data.nextPageToken));
        } else {
            dispatch(getUsersFail(error));
        }
    };
};