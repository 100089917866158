import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { PageSection } from "../../../components/templates/PageSection";
import LoginStyle from "./LoginStyledComponents";
import Logo from "../../../assets/logo/Logo.svg";
import { authenticate } from "../../../store/actions/auth/auth-actions";

const LoginPage = () => {
	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');

	const dispatch = useDispatch();

	const onSubmit = () => {
		if(!userName || userName.trim().length === 0 || !password || password.trim().length === 0) {
			return;
		}
		dispatch(authenticate(userName, password));
	};

	return (
		<PageSection>
			<LoginStyle.LoginForm>
				<img src={Logo} style={{ height: "157px" }} alt="" />
				<h1 style={{ textAlign: "center", fontFamily: "InterUI-Medium" }}>
					Login
        		</h1>
				<LoginStyle.SubTitle>Email</LoginStyle.SubTitle>
				<LoginStyle.StyledInputNew
					value={userName}
					onChange={(e) => setUserName(e.target.value)}>
				</LoginStyle.StyledInputNew>
				<LoginStyle.SubTitle>Password</LoginStyle.SubTitle>
				<LoginStyle.StyledInputNew
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onKeyDown={(e)=> e.keyCode === 13 ? onSubmit() : null}>
				</LoginStyle.StyledInputNew>
				<LoginStyle.LButtoBlack 
					onClick={onSubmit} 
					disabled={!userName || userName.trim().length === 0 || !password || password.trim().length === 0}
					style={{ width: "100%" }}>
					Log in
       			</LoginStyle.LButtoBlack>
			</LoginStyle.LoginForm>
		</PageSection>
	);
};

LoginPage.propTypes = {
	firebase: PropTypes.shape({
		doSignInWithEmailAndPassword: PropTypes.func.isRequired
	}),
	auth: PropTypes.object
};

export default LoginPage;
