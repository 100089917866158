import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import FeedsStyle from "../../../components/common/FeedsMessagesStyledComponents";
import FeedsTable from "../../../components/tables/Feeds/FeedsTable";
import { StyledInput, FlexRow } from "../../../components/common/CommonStyledComponent";
import { getFeeds, updateFeed, addFeed, deleteFeed } from "../../../store/actions/feeds/feeds-actions";
import FeedDeletePopup from "./DeletePopup/DeletePopup";

const Feeds = () => {
    const { addToast } = useToasts();

    const dispatch = useDispatch();
    const feeds = useSelector(state => state.feeds);

    const [body, setBody] = useState('');
    const [video, setVideo] = useState('');
    const [editFeed, setEditFeed] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        dispatch(getFeeds());
    }, []);

    const onPost = () => {
        dispatch(addFeed({ body: {he: body}, video: video, posted: new Date(), rev: 0 - new Date().getTime() })).then((data) => {
            if (!data.error) {
                addToast("Feed posted", { appearance: "success", autoDismiss: true });
                setBody('');
                setVideo('');
                dispatch(getFeeds());
            }
            else {
                addToast("Error while posting feed", { appearance: "error", autoDismiss: true });
            }
        });
    }

    const onRePost = () => {
        dispatch(updateFeed(editFeed.id, { body: {he: body}, video: video, posted: new Date(), rev: 0 - new Date().getTime() })).then((data) => {
            if (!data.error) {
                addToast("Feed reposted", { appearance: "success", autoDismiss: true });
                setBody('');
                setVideo('');
                setEditFeed(null);
                dispatch(getFeeds());
            }
            else {
                addToast("Error while reposting feed", { appearance: "error", autoDismiss: true });
            }
        });
    }

    const onCancel = () => {
        setBody('');
        setVideo('');
        setEditFeed(null);
    }

    const onEdit = (row) => {
        setEditFeed(row.props);
        setBody(row.props.body.he);
        setVideo(row.props.video);
    };

    const onDeletePopupButton = () => {
        dispatch(deleteFeed(deleteId)).then((data)=> {
            if (!data.error) {
                addToast("Feed deleted", { appearance: "success", autoDismiss: true });
                setDeleteId(null);
                dispatch(getFeeds());
            }
            else {
                addToast("Error while deleting feed", { appearance: "error", autoDismiss: true });
            }
            setShowDeletePopup(false);
        });
    };

    const onDelete = (row) => {
        setDeleteId(row.props.id);
        setShowDeletePopup(true);
    }

    return (
        <FeedsStyle.All>
            <FeedsStyle.Form>
                <FeedsStyle.SubTitle>Feed Body</FeedsStyle.SubTitle>
                <FeedsStyle.StyledTextArea
                    value={body}
                    onChange={(e) => setBody(e.target.value)}>
                </FeedsStyle.StyledTextArea>
                <FeedsStyle.SubTitle>Video URL</FeedsStyle.SubTitle>
                <StyledInput
                    value={video}
                    onChange={(e) => setVideo(e.target.value)}>
                </StyledInput>
                <FlexRow>
                    {!editFeed ? <FeedsStyle.LButtoBlack
                        disabled={!body && !video}
                        onClick={onPost}>
                        Post
                    </FeedsStyle.LButtoBlack> : null}
                    {editFeed ?
                        <>
                            <FeedsStyle.LButtoBlack
                                disabled={!body && !video}
                                onClick={onRePost}>
                                Repost
                            </FeedsStyle.LButtoBlack>
                            <FeedsStyle.LTButtoBlack
                                onClick={onCancel}>
                                Cancel
                            </FeedsStyle.LTButtoBlack>
                        </> : null}
                </FlexRow>
            </FeedsStyle.Form>
            <FeedsStyle.tableTitle>Recent Feeds</FeedsStyle.tableTitle>
            <FeedsTable
                loading={feeds.loading}
                data={feeds.feeds}
                defaultPageSize={10}
                minRows={10}
                onEdit={onEdit}
                onRemove={onDelete}>
            </FeedsTable>
            {showDeletePopup ? <FeedDeletePopup onDelete={onDeletePopupButton} closeModal={()=>setShowDeletePopup(false)}/> : null}
        </FeedsStyle.All>
    );
};

export default Feeds;
