import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../utilities";

const initialState = {
    workouts: [],
    error: null,
    loading: false
};

const workoutsLoading = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null
    });
};

const getWorkoutsSuccess = (state, action) => {
    const { payload } = action;
    return updateObject(state, {
        workouts: payload,
        error: null,
        loading: false
    });
};

const getWorkoutsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const clenWorkouts = (state, action) => {
    return updateObject(state, {
        workouts: [],
        error: null,
        loading: false
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_WORKOUTS_LOADING: return workoutsLoading(state, action);
        case actionTypes.GET_WORKOUTS_SUCCESS: return getWorkoutsSuccess(state, action);
        case actionTypes.GET_WORKOUTS_FAIL: return getWorkoutsFail(state, action);
        case actionTypes.CLEAN_WORKOUTS: return clenWorkouts(state, action);
        default:
            return state;
    }
};

export default reducer;
