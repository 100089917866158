import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../utilities";

const initialState = {
  plans: [],
  error: null,
  loading: false
};

const plansLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const getPlansSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    plans: payload,
    error: null,
    loading: false
  });
};

const getPlansFail = (state, action) => {
  return updateObject(state, {
    plans: [],
    error: action.error,
    loading: false
  });
};

const addPlanLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const addPlanSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
};

const addPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const deletePlanLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const deletePlanSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    plans: payload,
    error: null,
    loading: false
  });
};

const deletePlanFail = (state, action) => {
  return updateObject(state, {
    plans: [],
    error: action.error,
    loading: false
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PLANS_LOADING:return plansLoading(state, action);
    case actionTypes.GET_PLANS_SUCCESS:return getPlansSuccess(state, action);
    case actionTypes.GET_PLANS_FAIL:return getPlansFail(state, action);
    case actionTypes.ADD_PLAN_LOADING:return addPlanLoading(state, action);
    case actionTypes.ADD_PLAN_SUCCESS:return addPlanSuccess(state, action);
    case actionTypes.ADD_PLAN_FAIL:return addPlanFail(state, action);
    case actionTypes.DELETE_PLAN_LOADING:return deletePlanLoading(state, action);
    case actionTypes.DELETE_PLAN_SUCCESS:return deletePlanSuccess(state, action);
    case actionTypes.DELETE_PLAN_FAIL:return deletePlanFail(state, action);
    
    default:
      return state;
  }
};

export default reducer;
