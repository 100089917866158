import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {initialsByName} from "../../common/utils";

export const RoundNameLogo = styled.div`
  width: ${props => props.width + "px"};
  height: ${props => props.height + "px"};
  background: ${props =>
    props.bg ? props.bg : "var(--primary-blue);"};
  border-radius: ${props =>
    (props.radius ? props.radius : props.width / 2) + "px"};
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${props => (props.color ? props.color : "white")};
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : "InterUI-SemiBold"};
  font-size: ${props => (props.fontSize ? props.fontSize : 16 + "px")};
  line-height: 16px;
  user-select: none;
  box-sizing: border-box;
`;

export const RoundImage = styled.img`
  width: ${props => props.width + "px"};
  height: ${props => props.height + "px"};
  border-radius: ${props =>
    (props.radius ? props.radius : props.width / 2) + "px"};
`;

const OverButton = styled.span`
  position: absolute;
  text-align: center;
  width: 100%;
  background: #00000082;
  color: white;
  bottom: 0;
  left: 0;
  padding: 10px 0;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: var(--neutral-30p);
  }
  &:active {
    color: white;
  }
`;

const ImageContainer = styled.div`
  height: ${props => props.height + "px"};
  width: ${props => props.width + "px"};
  display: inline-block;
  overflow: hidden;
  border-radius: ${props =>
    props.withButton
      ? (props.radius ? props.radius : props.width / 2) + "px"
      : null};
  border: none;
  position: relative;
  padding: 0;
`;

const RoundProfileImage = React.memo(props => {
  let image = props.imgUrl ? (
    <ImageContainer {...props}>
      <RoundImage src={props.imgUrl} {...props} />
      {props.withButton ? (
        <OverButton onClick={props.changeClickedHandler}>Change</OverButton>
      ) : null}
    </ImageContainer>
  ) : (
    <ImageContainer {...props}>
      <RoundNameLogo {...props}>{initialsByName(props.name)}</RoundNameLogo>
      {props.withButton ? (
        <OverButton onClick={props.changeClickedHandler}>Change</OverButton>
      ) : null}
    </ImageContainer>
  );
  return image;
});

RoundProfileImage.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  radius: PropTypes.number,
  name: PropTypes.string,
  bg: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  imgUrl: PropTypes.string,
  withButton: PropTypes.bool
};

RoundProfileImage.defaultProps = {
  withButton: false,
  changeClickedHandler: () => {}
};

export default RoundProfileImage;
