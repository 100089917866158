import exerciseApi from "../../api/exercises-api";
import * as actionTypes from '../actionTypes';
import exercisesApi from "../../api/exercises-api";
import {projectID} from '../../api/routes';
import * as _ from 'lodash';

export const ExerciseIdPath = 'projects/' + projectID +'/databases/(default)/documents/exercises/';

export const getExerciseLoading = () => {
    return {
        type: actionTypes.GET_EXERCISE_LOADING
    };
};

export const getExerciseSuccess = exercise => {
    return {
        type: actionTypes.GET_EXERCISE_SUCCESS,
        payload: exercise
    };
};

export const getExerciseFail = err => {
    return {
        type: actionTypes.GET_EXERCISE_FAIL,
        error: err
    };
};

export const deleteExerciseLoading = () => {
    return {
        type: actionTypes.DELETE_EXERCISE_LOADING
    };
};

export const deleteExerciseSuccess = () => {
    return {
        type: actionTypes.DELETE_EXERCISE_SUCCESS,
    };
};

export const deleteExerciseFail = err => {
    return {
        type: actionTypes.DELETE_EXERCISE_FAIL,
        error: err
    };
};

export const updateExerciseLoading = () => {
    return {
        type: actionTypes.UPDATE_EXERCISE_LOADING
    };
};

export const updateExerciseSuccess = () => {
    return {
        type: actionTypes.UPDATE_EXERCISE_SUCCESS,
    };
};

export const updateExerciseFail = err => {
    return {
        type: actionTypes.UPDATE_EXERCISE_FAIL,
        error: err
    };
};

export const addExerciseLoading = () => {
    return {
        type: actionTypes.ADD_EXERCISE_LOADING
    };
};

export const addExerciseSuccess = () => {
    return {
        type: actionTypes.ADD_EXERCISE_SUCCESS,
    };
};

export const addExerciseFail = err => {
    return {
        type: actionTypes.ADD_EXERCISE_FAIL,
        error: err
    };
};

export const getExercise = id => {
    return async (dispatch, getState) => {
        const e = getState().exercises.exercises.find(ee => ee.name.replace(ExerciseIdPath, '') === id);
        if (e) {
            return dispatch(getExerciseSuccess(e));
        }
        dispatch(getExerciseLoading());
        const { status, data, error } = await exerciseApi.fetchExercise(getState().auth.token, id);
        if (status === 200) {
            dispatch(getExerciseSuccess(data));
        } else {
            dispatch(getExerciseFail(error));
        }
    };
};

export const deleteExercise = id => {
    return async (dispatch, getState) => {
        dispatch(deleteExerciseLoading());

        const { status, error } = await exercisesApi.deleteExercise(getState().auth.token, id);
        if (status === 200) {
            return dispatch(deleteExerciseSuccess());
        } else {
            return dispatch(deleteExerciseFail(error));
        }
    };
};

export const updateExercise = (exercise, e) => {
    return async (dispatch, getState) => {
        _.set(exercise, ['fields', 'name', 'mapValue', 'fields', 'he', 'stringValue'], e.name);
        _.set(exercise, ['fields', 'description', 'mapValue', 'fields', 'he', 'stringValue'], e.description);
        _.set(exercise, ['fields', 'reps', 'integerValue'], e.reps);
        _.set(exercise, ['fields', 'sets', 'integerValue'], e.sets);
        _.set(exercise, ['fields', 'type', 'stringValue'], e.type);
        _.set(exercise, ['fields', 'video', 'stringValue'], e.video);
        dispatch(updateExerciseLoading());
        const { status, data, error } = await exercisesApi.updateExercise(getState().auth.token, exercise.name.replace(ExerciseIdPath, ''), exercise);
        if (status === 200) {
            return dispatch(updateExerciseSuccess(data));
        } else {
            return dispatch(updateExerciseFail(error));
        }
    };
};

export const addExercise = (newExercise) => {
    return async (dispatch, getState) => {
        const e = {
            "fields": {
                "description": {
                    "mapValue": {
                        "fields": {
                            "he": {"stringValue": newExercise.description},
                        }
                    }
                },
                "video": {"stringValue": newExercise.video},
                "sets": {"integerValue": newExercise.sets},
                "wid": {"stringValue": newExercise.wid},
                "name": {
                    "mapValue": {
                        "fields": {
                            "he": {"stringValue": newExercise.name},
                        }
                    }
                },
                "index": {"integerValue": newExercise.index},
                "reps": {"integerValue": newExercise.reps},
                "type": {"stringValue": newExercise.type}
            },
        }
        dispatch(addExerciseLoading());
        const { status, data, error } = await exercisesApi.addExercise(getState().auth.token, e);
        if (status === 200) {
            return dispatch(addExerciseSuccess(data));
        } else {
            return dispatch(addExerciseFail(error));
        }
    };
};
