import styled from "styled-components";
import { StyledInput, StyledTextArea } from "./CommonStyledComponent";
import { NavLink } from "react-router-dom";
import { LButton } from "../ui/Buttons";

const Form = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  max-width: 1222px;
  /* padding: 10px; */
  margin: 0 auto;
`;

const Title = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 100%;
  margin: 10px 10%;
  border-bottom: 1px solid silver;
`;

const WorkOutTitle = styled.div`
  font-size: 16px;
  font-family: InterUI-SemiBold;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 12px 0;

`;

const Body = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 29px;
`;

const Details = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  flex: 1;
  height: 100%;
  font-family: InterUI-SemiBold;
  padding: 0 10%;
`;

const RepeatesSets = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  height: 100%;
`;

const NewTitle = styled.div`
  font-size: 36px;
  padding-bottom: 12px;
  padding-left: 29px;
  font-family: InterUI-SemiBold;
`;

const SubTitle = styled.div`
  font-family: InterUI-SemiBold;
  font-size: 16px;
`;

const LButtonUpload = styled(LButton)`
  background: white;
  height: 100%;
  border: 1px solid silver;
  padding: 0;
  font-size: 14px;
  font-family: InterUI-SemiBold;
  color: #1264a3;
  padding: 10px 21px 10px 22px;
  margin-top: 21px;
  border-radius: 2px;
  &:hover {
    background: white;
    border: 1px solid silver;
  }
`;

const UploadImg = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  font-family: InterUI-SemiBold;
  margin-top: 32px;
  margin-right: 30px;
  max-width: 150px;
`;

const LButtonNew = styled(LButton)`
  background: white;
  border: 1px solid silver;
  padding: 0;
  border-radius: 45px;
  font-size: 14px;
  font-family: InterUI-SemiBold;
  height: 32px;
  &:hover {
    background: white;
    border: 1px solid silver;
  }
  padding: 8px 16px 8px 0px;
`;

const Inputs = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  font-family: InterUI-SemiBold;
`;

const WeekDay = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;
  font-family: InterUI-SemiBold;
`;

const StyledInputNew = styled(StyledInput)`
  margin-bottom: 22px;
  margin-top: 12px;
`;

export const StyledTextAreaNew = styled(StyledTextArea)`
  margin-bottom: 22px;
  margin-top: 12px;
`;

const LButtoBlack = styled(LButton)`
  font-family: InterUI-SemiBold;

  background: var(--primary-blue);
  border: 2px solid var(--orange-shade-dark);
  color: #ffffff;
  padding: 10px 24px;
  width: 187px;
  height: 44px;
  &:active {
    background: var(--primary-blue);
  }
  &:hover {
    background: var(--primary-blue);
  }
`;

const activeClassName = "active-nav";

const NavStyledNavLink = styled(NavLink).attrs({
  activeClassName: activeClassName
})`
  &.${activeClassName} {
    border-bottom: 5px solid var(--primary-blue);
  }
`;

export default {
  Form,
  WorkOutTitle,
  Body,
  Title,
  Details,
  WeekDay,
  LButtonNew,
  UploadImg,
  NewTitle,
  StyledInputNew,
  StyledTextAreaNew,
  SubTitle,
  LButtoBlack,
  LButtonUpload,
  NavStyledNavLink,
  RepeatesSets,
  Inputs
};
