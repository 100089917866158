import React, { useState } from 'react'
import { Span, NewMealMain } from './NutritionsStyle'
import Routes from '../routes'
import { withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form'
import { addMeal } from "../../../store/actions/nutritions/nutritions-actions";
import { useToasts } from "react-toast-notifications";
import { FlexColumn, FlexRow } from "../../../components/common/CommonStyledComponent";
import NewModalStyle from "../../../components/common/NewModalStyledComponents";

import StyledSelect from "../../../components/ui/Select";
import { useDispatch } from "react-redux";

const contentsOptions = [
    { value: 'vegan', label: 'Vegan' },
    { value: 'dairy', label: 'Dairy' },
    { value: 'gluten_free', label: 'Gluten free' },
    { value: 'vegetarian', label: 'Vegetarian' },
    { value: 'meat', label: 'Meat' },
    { value: 'other', label: 'Other' }
]
const typesOptions = [
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'snack', label: 'Snack' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'dinner', label: 'Dinner' }
]
const NewMealForm = (props) => {
    const { addToast } = useToasts();

    const [contents, setContents] = useState(contentsOptions[0]);
    const [type, setType] = useState(typesOptions[0]);
    const { register, handleSubmit, errors } = useForm()
    const dispatch = useDispatch();
    const onSubmit = async data => {
        const newMeal = {
            name: data.name,
            nutritionValues : {calories: data.calories, fats:data.fats, carbs:data.carbs, proteins:data.proteins},
            prepareTime: data.prepareTime,
            description: data.description,
            image: data.image,
            contents: contents.value,
            type: type.value
        }
        dispatch(addMeal(newMeal)).then(data => {
            if (!data.error) {
                addToast("Meal added successfully", { appearance: "success", autoDismiss: true });
                props.history.replace(Routes.Nutritions.nutritions)
            } else {
                addToast("Error while adding Meal", { appearance: "error", autoDismiss: true });
            }
        });
    }
    return (<NewMealMain onSubmit={handleSubmit(onSubmit)}>
        <FlexRow style={{ justifyContent: 'space-between'}}>
            <FlexColumn style={{ width: '47.5%'}}>
                <NewModalStyle.SubTitle>Name</NewModalStyle.SubTitle>
                <FlexRow>
                    <NewModalStyle.StyledInputNew name="name"
                        ref={register({ required: true, pattern: /^[ -A-Za-z1-9א-ת]+$/i, minLength: 2, maxLength: 50 })}
                        style={{ width: '100%'}}>
                    </NewModalStyle.StyledInputNew>
                </FlexRow>
                {errors.name && errors.name.type === 'required' && <Span>required</Span>}
                {errors.name && (errors.name.type === 'maxLength' || errors.name.type === 'minLength' ||
                    errors.name.type === 'pattern') && <Span>2-50 letters and digits only</Span>}
            </FlexColumn>
            <FlexColumn style={{ width: '47.5%' }}>
                <NewModalStyle.SubTitle>Calories</NewModalStyle.SubTitle>
                <FlexRow>
                    <NewModalStyle.StyledInputNew name="calories"
                        style={{ width: '100%'}}
                        ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                    </NewModalStyle.StyledInputNew>
                </FlexRow>
                {errors.calories && errors.calories.type === 'required' && <Span>required</Span>}
                {errors.calories && (errors.calories.type === 'maxLength' || errors.calories.type === 'minLength' ||
                    errors.calories.type === 'pattern') && <Span>1-4 digits only</Span>}
            </FlexColumn>
        </FlexRow>



        <FlexRow style={{justifyContent: "space-between"}}>
            <FlexColumn style={{ width: '30%' }}>
                <NewModalStyle.SubTitle>Fats</NewModalStyle.SubTitle>
                <FlexRow>
                    <NewModalStyle.StyledInputNew name="fats"
                        style={{ width: '100%' }}
                        ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                    </NewModalStyle.StyledInputNew>
                </FlexRow>
                {errors.fats && errors.fats.type === 'required' && <Span>required</Span>}
                {errors.fats && (errors.fats.type === 'maxLength' || errors.fats.type === 'minLength' ||
                    errors.fats.type === 'pattern') && <Span>1-4 digits only</Span>}
            </FlexColumn>
            <FlexColumn style={{ width: '30%', paddingLeft: '10px' }}>
                <NewModalStyle.SubTitle>Carbs</NewModalStyle.SubTitle>
                <FlexRow>
                    <NewModalStyle.StyledInputNew name="carbs"
                        style={{ width: '100%'}}
                        ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                    </NewModalStyle.StyledInputNew>
                </FlexRow>
                {errors.carbs && errors.carbs.type === 'required' && <Span>required</Span>}
                {errors.carbs && (errors.carbs.type === 'maxLength' || errors.carbs.type === 'minLength' ||
                    errors.carbs.type === 'pattern') && <Span>1-4 digits only</Span>}
            </FlexColumn>
            <FlexColumn style={{ width: '30%', paddingLeft: '10px' }}>
                <NewModalStyle.SubTitle>Proteins</NewModalStyle.SubTitle>
                <FlexRow>
                    <NewModalStyle.StyledInputNew name="proteins"
                        style={{ width: '100%'}}
                        ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                    </NewModalStyle.StyledInputNew>
                </FlexRow>
                {errors.proteins && errors.proteins.type === 'required' && <Span>required</Span>}
                {errors.proteins && (errors.proteins.type === 'maxLength' || errors.proteins.type === 'minLength' ||
                    errors.proteins.type === 'pattern') && <Span>1-4 digits only</Span>}
            </FlexColumn>
        </FlexRow>


        <FlexRow style={{ justifyContent: "space-between" }}>
            <FlexColumn style={{ width: '30%' }}>
                <NewModalStyle.SubTitle style={{ width: '100%' }}>Type</NewModalStyle.SubTitle>
                <FlexRow style={{ width: '100%' ,marginTop:"12px" }}>
                    <StyledSelect
                        options={typesOptions}
                        defaultValue={type}
                        value={type}
                        isSearchable={false}
                        onChange={(e) => setType(e)}>
                    </StyledSelect>
                </FlexRow>
            </FlexColumn>
            <FlexColumn style={{ width: '30%' }}>
                <NewModalStyle.SubTitle style={{ width: '100%' }}>Contents</NewModalStyle.SubTitle>
                <FlexRow style={{  width: '100%' ,marginTop:"12px" }}>
                    <StyledSelect
                        options={contentsOptions}
                        defaultValue={contents}
                        value={contents}
                        isSearchable={false}
                        onChange={(e) => setContents(e)}>
                    </StyledSelect>
                </FlexRow>
            </FlexColumn>
            <FlexColumn style={{ width: '30%' }}>
                <NewModalStyle.SubTitle>Prepare Time (mins)</NewModalStyle.SubTitle>
                <FlexRow>
                    <NewModalStyle.StyledInputNew name="prepareTime"
                        style={{ width: '100%' }}
                        ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                    </NewModalStyle.StyledInputNew>
                </FlexRow>
                {errors.prepareTime && errors.prepareTime.type === 'required' && <Span>required</Span>}
                {errors.prepareTime && (errors.prepareTime.type === 'maxLength' || errors.prepareTime.type === 'minLength' ||
                    errors.prepareTime.type === 'pattern') && <Span>1-4 digits only</Span>}
            </FlexColumn>
        </FlexRow>

        <FlexColumn >
            <NewModalStyle.SubTitle>Description</NewModalStyle.SubTitle>
            <NewModalStyle.StyledTextAreaNew name="description" style={{ width:"98.5%"}}
                ref={register({ required: true })}>
            </NewModalStyle.StyledTextAreaNew>
            {errors.description && errors.description.type === 'required' && <Span>required</Span>}
            <NewModalStyle.SubTitle >Image URL</NewModalStyle.SubTitle>
            <FlexRow style={{ flex: 1, alignItems: 'center' }}>
                <NewModalStyle.StyledInputNew name="image"
                    style={{ width: '100%'}}
                    ref={register({ required: true })}>

                </NewModalStyle.StyledInputNew>
            </FlexRow>
            {errors.image && errors.image.type === 'required' && <Span>required</Span>}

            <NewModalStyle.LButtoBlack type="submit" style={{ marginTop: "20px" }}>
                Save
                    </NewModalStyle.LButtoBlack>
        </FlexColumn>
    </NewMealMain>)
}

export default withRouter(NewMealForm)
