import axiosInstance from "../../axios";
import ServerRoutes from "./routes/index";
import { errorSchema } from "./schemas/common-schemas";


const fetchNutritions = async (token,type) => {
    
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const query = {
            "structuredQuery": {
                "from": [
                    {
                        "collectionId": "meals",
                        "allDescendants": false
                    }
                ],
                "where": {
                    "fieldFilter": {
                        "field": {
                            "fieldPath": "type"
                        },
                        "op": "EQUAL",
                        "value": {
                            "stringValue": type
                        }
                    }
                }
            }
        }
        const { status, data, error } = await axiosInstance.post(ServerRoutes.globalQuery, query, { headers });
        const result = data.map(d=> d.document).filter(d => d !== undefined);
        return { data: result, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const addMeal = async (token, newMeal) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        
        const { status, data, error } = await axiosInstance.post(ServerRoutes.getNutritions, newMeal, { headers: headers });
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const deleteMeal = async (token, id) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const {status, data, error} = await axiosInstance.delete(ServerRoutes.getNutritions + '/' + id, {headers: headers});
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const getMeal = async (token, id) => {
	try {
		const headers = {
			Authorization: 'Bearer ' + token
		}
		const { status, data, error } = await axiosInstance.get(ServerRoutes.getNutritions + '/' + id, { headers: headers });
		return { data: data, status: status, error: error };
	} catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message =
			"Some error happen, please try again. If the error returns please contact support";
		switch (e.response.status) {
			case 404:
				message = "user not found";
				break;
			case 500:
				message = "Server error. Please contact support";
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
};
const updateMeal = async (token, id, newMeal) => {
	try {
		const headers = {
			Authorization: 'Bearer ' + token
		}
		const { status, data, error } = await axiosInstance.patch(ServerRoutes.getNutritions + '/' + id, newMeal, { headers: headers });
		return { data: data, status: status, error: error };
	} catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message =
			"Some error happen, please try again. If the error returns please contact support";
		switch (e.response.status) {
			case 404:
				message = "user not found";
				break;
			case 500:
				message = "Server error. Please contact support";
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
};

const nutritionsApi = {
    fetchNutritions,
    addMeal,
    getMeal,
    updateMeal,
    deleteMeal
};

export default nutritionsApi;
