import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../utilities";

const initialState = {
    plan: null,
    error: null,
    loading: false
};

const planLoading = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null
    });
};

const getPlanSuccess = (state, action) => {
    const { payload } = action;
    return updateObject(state, {
        plan: payload,
        error: null,
        loading: false
    });
};

const getPlanFail = (state, action) => {
    return updateObject(state, {
        plan: null,
        error: action.error,
        loading: false
    });
};

const updatePlanLoading = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null
    });
};

const updatePlanSuccess = (state, action) => {
    return updateObject(state, {
        plan: action.plan,
        error: null,
        loading: false
    });
};

const updatePlanFail = (state, action) => {
    return updateObject(state, {
        plan: null,
        error: action.error,
        loading: false
    });
};

const selectPlan = (state, action) => {
    return updateObject(state, {
        plan: action.plan
    });
}

const deselectPlan = (state, action) => {
    return updateObject(state, {
        plan: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PLAN_LOADING: return planLoading(state, action);
        case actionTypes.GET_PLAN_SUCCESS: return getPlanSuccess(state, action);
        case actionTypes.GET_PLAN_FAIL: return getPlanFail(state, action);
        case actionTypes.UPDATE_PLAN_LOADING: return updatePlanLoading(state, action);
        case actionTypes.UPDATE_PLAN_SUCCESS: return updatePlanSuccess(state, action);
        case actionTypes.UPDATE_PLAN_FAIL: return updatePlanFail(state, action);
        case actionTypes.SELECT_PLAN: return selectPlan(state, action);
        case actionTypes.DESELECT_PLAN: return deselectPlan(state, action);

        default:
            return state;
    }
};

export default reducer;
