import React, { useState, useEffect } from 'react'
import { Span, NewMealMain, Main, ButtonsTypesDiv, NewMealTitle } from './NutritionsStyle'
import { BackButtonTBlue } from "../../../components/ui/Buttons";
import Routes from '../routes'
import { withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form'
import { updateMealFromServer, getMeal, cleanMeal } from "../../../store/actions/nutritions/meal-actions";
import * as _ from 'lodash';
import { useToasts } from "react-toast-notifications";
import { FlexColumn, FlexRow } from "../../../components/common/CommonStyledComponent";
import NewModalStyle from "../../../components/common/NewModalStyledComponents";
import { Spinner } from "../../../components/common/CommonStyledComponent";
import StyledSelect from "../../../components/ui/Select";
import { useDispatch, useSelector } from "react-redux";

const typesOptionsArr = [
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'snack', label: 'Snack' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'dinner', label: 'Dinner' }
];

const contentsOptionsArr = [
    { value: 'vegan', label: 'Vegan' },
    { value: 'dairy', label: 'Dairy' },
    { value: 'gluten_free', label: 'Gluten free' },
    { value: 'vegetarian', label: 'Vegetarian' },
    { value: 'meat', label: 'Meat' },
    { value: 'other', label: 'Other' }
];

const UpdateMeal = (props) => {
    const mealId = props.match.params.id;
    const dispatch = useDispatch();

    const meal = useSelector(state => state.meal.meal);
    const { addToast } = useToasts();
    const [load, setLoad] = useState(false)
    const [name, setName] = useState(null);
    const [contents, setContents] = useState(null);
    const [type, setType] = useState(null);
    const [image, setImage] = useState(null);
    const [calories, setCalories] = useState(null);
    const [carbs, setCarbs] = useState(null);
    const [fats, setFats] = useState(null);
    const [proteins, setProteins] = useState(null);
    const [prepareTime, setPrepareTime] = useState(null);
    const [description, setDescription] = useState('');
    const { register, handleSubmit, errors } = useForm()
    useEffect(() => {
        if (props.match.params.id) {
            dispatch(getMeal(mealId));
        }
        return () => {
            dispatch(cleanMeal());
        }
    }, []);

    useEffect(() => {
        if (meal && props.match.params.id) {
            setName(_.get(meal, ['fields', 'name', 'mapValue', 'fields', 'en', 'stringValue']));
            setCalories(_.get(meal, ['fields', 'nutritionValues', 'mapValue', 'fields', 'calories', 'integerValue']));
            setCarbs(_.get(meal, ['fields', 'nutritionValues', 'mapValue', 'fields', 'carbs', 'integerValue']));
            setProteins(_.get(meal, ['fields', 'nutritionValues', 'mapValue', 'fields', 'proteins', 'integerValue']));
            setFats(_.get(meal, ['fields', 'nutritionValues', 'mapValue', 'fields', 'fats', 'integerValue']));
            setImage(_.get(meal, ['fields', 'image', 'stringValue']));
            const c = _.get(meal, ['fields', 'contents', 'stringValue']);
            const selectedContent = contentsOptionsArr.find(o => c === o.value);
            if (selectedContent) {
                setContents(selectedContent)
            }
            else {
                setContents(contentsOptionsArr[0]);
            }
            const t = _.get(meal, ['fields', 'type', 'stringValue']);
            const selectedType = typesOptionsArr.find(o => o.value === t);
            if (selectedType) {
                setType(selectedType);
            }
            else {
                setType(typesOptionsArr[0]);
            }
            setPrepareTime(_.get(meal, ['fields', 'prepareTime', 'integerValue']));
            setDescription(_.get(meal, ['fields', 'description', 'mapValue', 'fields', 'he', 'stringValue']));
        }
    }, [meal]);

    const onSubmit = async data => {
        setLoad(true)
        const newMeal = {
            name: data.name,
            nutritionValues : {calories: data.calories, fats:data.fats, carbs:data.carbs, proteins:data.proteins},
            prepareTime: data.prepareTime,
            description: data.description,
            image: data.image,
            contents: contents.value,
            type: type.value
        }

        dispatch(updateMealFromServer(mealId, meal, newMeal)).then(data => {
            if (!data.error) {
                addToast("Update Successfully", { appearance: "success", autoDismiss: true });
                setLoad(false)
                props.history.goBack()
            } else {
                addToast("Not updated!", { appearance: "error", autoDismiss: true });
            }
        });
    }
    return (<Main>

        <ButtonsTypesDiv style={{ justifyContent: "flex-start" }}>
            <BackButtonTBlue onClick={() => props.history.replace(Routes.Nutritions.nutritions)}>
                Back
                    </BackButtonTBlue>
            <NewMealTitle>Update Meal</NewMealTitle>
        </ButtonsTypesDiv>
        <NewMealMain onSubmit={handleSubmit(onSubmit)}>
            <FlexRow style={{ justifyContent: 'space-between'}}>
                <FlexColumn style={{ width: '47.5%'}}>
                    <NewModalStyle.SubTitle>Name</NewModalStyle.SubTitle>
                    <FlexRow>
                        <NewModalStyle.StyledInputNew name="name" defaultValue={name || ""}
                            ref={register({ required: true, pattern: /^[ -A-Za-z1-9א-ת]+$/i, minLength: 2, maxLength: 50 })}
                            style={{ width: '100%' }}>
                        </NewModalStyle.StyledInputNew>
                    </FlexRow>
                    {errors.name && errors.name.type === 'required' && <Span>required</Span>}
                    {errors.name && (errors.name.type === 'maxLength' || errors.name.type === 'minLength' ||
                        errors.name.type === 'pattern') && <Span>2-20 letters and digits only</Span>}
                </FlexColumn>
                <FlexColumn style={{ width: '47.5%' }}>
                    <NewModalStyle.SubTitle>Calories</NewModalStyle.SubTitle>
                    <FlexRow>
                        <NewModalStyle.StyledInputNew name="calories" defaultValue={calories || ""}
                            style={{ width: '100%' }}
                            ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                        </NewModalStyle.StyledInputNew>
                    </FlexRow>
                    {errors.calories && errors.calories.type === 'required' && <Span>required</Span>}
                    {errors.calories && (errors.calories.type === 'maxLength' || errors.calories.type === 'minLength' ||
                        errors.calories.type === 'pattern') && <Span>1-4 digits only</Span>}
                </FlexColumn>
            </FlexRow>


            <FlexRow style={{justifyContent: "space-between" }}>
            <FlexColumn style={{ width: '30%'}}>
                    <NewModalStyle.SubTitle>Fats</NewModalStyle.SubTitle>
                    <FlexRow>
                        <NewModalStyle.StyledInputNew name="fats" defaultValue={fats || ""}
                            style={{ width: '100%' }}
                            ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                        </NewModalStyle.StyledInputNew>
                    </FlexRow>
                    {errors.fats && errors.fats.type === 'required' && <Span>required</Span>}
                    {errors.fats && (errors.fats.type === 'maxLength' || errors.fats.type === 'minLength' ||
                        errors.fats.type === 'pattern') && <Span>1-4 digits only</Span>}
                </FlexColumn>
            <FlexColumn style={{ width: '30%'}}>
                    <NewModalStyle.SubTitle>Carbs</NewModalStyle.SubTitle>
                    <FlexRow>
                        <NewModalStyle.StyledInputNew name="carbs" defaultValue={carbs || ""}
                            style={{ width: '100%' }}
                            ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                        </NewModalStyle.StyledInputNew>
                    </FlexRow>
                    {errors.carbs && errors.carbs.type === 'required' && <Span>required</Span>}
                    {errors.carbs && (errors.carbs.type === 'maxLength' || errors.carbs.type === 'minLength' ||
                        errors.carbs.type === 'pattern') && <Span>1-4 digits only</Span>}
                </FlexColumn>
                <FlexColumn style={{ width: '30%' }}>
                    <NewModalStyle.SubTitle>Proteins</NewModalStyle.SubTitle>
                    <FlexRow>
                        <NewModalStyle.StyledInputNew name="proteins" defaultValue={proteins || ""}
                            style={{ width: '100%' }}
                            ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                        </NewModalStyle.StyledInputNew>
                    </FlexRow>
                    {errors.proteins && errors.proteins.type === 'required' && <Span>required</Span>}
                    {errors.proteins && (errors.proteins.type === 'maxLength' || errors.proteins.type === 'minLength' ||
                        errors.proteins.type === 'pattern') && <Span>1-4 digits only</Span>}
                </FlexColumn>
            </FlexRow>

            <FlexRow style={{justifyContent: "space-between",width: '100%'}}>
                <FlexColumn style={{ width: '30%' }}>
                    <NewModalStyle.SubTitle style={{ width: '100%' }}>Type</NewModalStyle.SubTitle>
                    <FlexRow style={{  width: '100%' ,marginTop:"12px" }}>
                        <StyledSelect
                            options={typesOptionsArr}
                            value={type}
                            isSearchable={false}
                            onChange={(e) => setType(e)}>
                        </StyledSelect>
                    </FlexRow>
                </FlexColumn>
                <FlexColumn style={{ width: '30%' }}>
                    <NewModalStyle.SubTitle style={{ width: '100%' }}>Contents</NewModalStyle.SubTitle>
                    <FlexRow style={{ width: '100%' ,marginTop:"12px" }}>
                        <StyledSelect
                            options={contentsOptionsArr}
                            value={contents}
                            isSearchable={false}
                            onChange={(e) => setContents(e)}>
                        </StyledSelect>
                    </FlexRow>
                </FlexColumn>
                <FlexColumn style={{ width: '30%' }}>
                    <NewModalStyle.SubTitle>Prepare Time (mins)</NewModalStyle.SubTitle>
                    <FlexRow>
                        <NewModalStyle.StyledInputNew name="prepareTime" defaultValue={prepareTime || ""}
                            style={{ width: '100%' }}
                            ref={register({ required: true, pattern: /^[0-9]+$/i, minLength: 1, maxLength: 4 })}>
                        </NewModalStyle.StyledInputNew>
                    </FlexRow>
                    {errors.prepareTime && errors.prepareTime.type === 'required' && <Span>required</Span>}
                    {errors.prepareTime && (errors.prepareTime.type === 'maxLength' || errors.prepareTime.type === 'minLength' ||
                        errors.prepareTime.type === 'pattern') && <Span>1-4 digits only</Span>}
                </FlexColumn>
            </FlexRow>

            <FlexColumn>
                <NewModalStyle.SubTitle>Description</NewModalStyle.SubTitle>
                <NewModalStyle.StyledTextAreaNew style={{ width:"98.5%"}} name="description" value={description}
                    ref={register({ required: true })} onChange={(e) => setDescription(e.target.value)}>
                </NewModalStyle.StyledTextAreaNew>
                {errors.description && errors.description.type === 'required' && <Span>required</Span>}
                <NewModalStyle.SubTitle>Image URL</NewModalStyle.SubTitle>
                <FlexRow style={{ flex: 1, alignItems: 'center' }}>
                    <NewModalStyle.StyledInputNew name="image" defaultValue={image || ""}
                        style={{ width: '100%' }}
                        ref={register({ required: true })}>

                    </NewModalStyle.StyledInputNew>
                </FlexRow>
                {errors.image && errors.image.type === 'required' && <Span>required</Span>}

                <NewModalStyle.LButtoBlack style={{marginTop:"20px"}} type="submit">
                    Save
                    </NewModalStyle.LButtoBlack><br />
                {load ? <Spinner /> : ''}
            </FlexColumn>
        </NewMealMain>
    </Main>)
}

export default withRouter(UpdateMeal)
