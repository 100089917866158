export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const CLEAN_USERS = "CLEAN_USERS";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const CLEAN_USER = "CLEAN_USER";

export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const GET_PLAN_LOADING = "GET_PLAN_LOADING";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const GET_PLAN_FAIL = "GET_PLAN_FAIL";
export const SELECT_PLAN = "SELECT_PLAN";
export const DESELECT_PLAN = "DESELECT_PLAN";

export const ADD_PLAN_LOADING = "ADD_PLAN_LOADING";
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS";
export const ADD_PLAN_FAIL = "ADD_PLAN_FAIL";

export const UPDATE_PLAN_LOADING = "UPDATE_PLAN_LOADING";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL";

export const DELETE_PLAN_LOADING = "DELETE_PLAN_LOADING";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAIL = "DELETE_PLAN_FAIL";

export const GET_PLANS_LOADING = "GET_PLANS_LOADING";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAIL = "GET_PLANS_FAIL";

export const GET_WORKOUTS_LOADING = "GET_WORKOUTS_LOADING";
export const GET_WORKOUTS_SUCCESS = "GET_WORKOUTS_SUCCESS";
export const GET_WORKOUTS_FAIL = "GET_WORKOUTS_FAIL";

export const CLEAN_WORKOUTS = "CLEAN_WORKOUTS";

export const GET_WORKOUT_LOADING = "GET_WORKOUT_LOADING";
export const GET_WORKOUT_SUCCESS = "GET_WORKOUT_SUCCESS";
export const GET_WORKOUT_FAIL = "GET_WORKOUT_FAIL";

export const DELETE_WORKOUT_LOADING = "DELETE_WORKOUT_LOADING";
export const DELETE_WORKOUT_SUCCESS = "DELETE_WORKOUT_SUCCESS";
export const DELETE_WORKOUT_FAIL = "DELETE_WORKOUT_FAIL";

export const EDIT_WORKOUT = "EDIT_WORKOUT";

export const UPDATE_WORKOUT_LOADING = "UPDATE_WORKOUT_LOADING";
export const UPDATE_WORKOUT_SUCCESS = "UPDATE_WORKOUT_SUCCESS";
export const UPDATE_WORKOUT_FAIL = "UPDATE_WORKOUT_FAIL";

export const ADD_WORKOUT_LOADING = "ADD_WORKOUT_LOADING";
export const ADD_WORKOUT_SUCCESS = "ADD_WORKOUT_SUCCESS";
export const ADD_WORKOUT_FAIL = "ADD_WORKOUT_FAIL";

export const CLEAN_WORKOUT = "CLEAN_WORKOUT";

export const GET_EXERCISES_LOADING = "GET_EXERCISES_LOADING";
export const GET_EXERCISES_SUCCESS = "GET_EXERCISES_SUCCESS";
export const GET_EXERCISES_FAIL = "GET_EXERCISES_FAIL";

export const CLEAN_EXERCIES = "CLEAN_EXERCIES";

export const GET_EXERCISE_LOADING = "GET_EXERCISE_LOADING";
export const GET_EXERCISE_SUCCESS = "GET_EXERCISE_SUCCESS";
export const GET_EXERCISE_FAIL = "GET_EXERCISE_FAIL";

export const DELETE_EXERCISE_LOADING = "DELETE_EXERCISE_LOADING";
export const DELETE_EXERCISE_SUCCESS = "DELETE_EXERCISE_SUCCESS";
export const DELETE_EXERCISE_FAIL = "DELETE_EXERCISE_FAIL";

export const UPDATE_EXERCISE_LOADING = "UPDATE_EXERCISE_LOADING";
export const UPDATE_EXERCISE_SUCCESS = "UPDATE_EXERCISE_SUCCESS";
export const UPDATE_EXERCISE_FAIL = "UPDATE_EXERCISE_FAIL";

export const ADD_EXERCISE_LOADING = "ADD_EXERCISE_LOADING";
export const ADD_EXERCISE_SUCCESS = "ADD_EXERCISE_SUCCESS";
export const ADD_EXERCISE_FAIL = "ADD_EXERCISE_FAIL";

export const GET_FEEDS_LOADING = "GET_FEEDS_LOADING";
export const GET_FEEDS_SUCCESS = "GET_FEEDS_SUCCESS";
export const GET_FEEDS_FAIL = "GET_FEEDS_FAIL";

export const ADD_FEED_LOADING = "ADD_FEED_LOADING";
export const ADD_FEED_SUCCESS = "ADD_FEED_SUCCESS";
export const ADD_FEED_FAIL = "ADD_FEED_FAIL";

export const DELETE_FEED_LOADING = "DELETE_FEED_LOADING";
export const DELETE_FEED_SUCCESS = "DELETE_FEED_SUCCESS";
export const DELETE_FEED_FAIL = "DELETE_FEED_FAIL";

export const UPDATE_FEED_LOADING = "UPDATE_FEED_LOADING";
export const UPDATE_FEED_SUCCESS = "UPDATE_FEED_SUCCESS";
export const UPDATE_FEED_FAIL = "UPDATE_FEED_FAIL";

export const CLEAN_FEEDS = "CLEAN_FEEDS";

export const GET_MESSAGES_LOADING = "GET_MESSAGES_LOADING";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";

export const ADD_MESSAGE_LOADING = "ADD_MESSAGE_LOADING";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_FAIL = "ADD_MESSAGE_FAIL";

export const DELETE_MESSAGE_LOADING = "DELETE_MESSAGE_LOADING";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAIL = "DELETE_MESSAGE_FAIL";

export const GET_NUTRITIONS_LOADING = "GET_NUTRITIONS_LOADING";
export const GET_NUTRITIONS_SUCCESS = "GET_NUTRITIONS_SUCCESS";
export const GET_NUTRITIONS_FAIL = "GET_NUTRITIONS_FAIL";
export const CLEAN_NUTRITIONS = "CLEAN_NUTRITIONS";

export const ADD_MEAL_LOADING = "ADD_MEAL_LOADING";
export const ADD_MEAL_SUCCESS = "ADD_MEAL_SUCCESS";
export const ADD_MEAL_FAIL = "ADD_MEAL_FAIL";

export const GET_MEAL_LOADING = "GET_MEAL_LOADING";
export const GET_MEAL_SUCCESS = "GET_MEAL_SUCCESS";
export const GET_MEAL_FAIL = "GET_MEAL_FAIL";

export const DELETE_MEAL_LOADING = "DELETE_MEAL_LOADING";
export const DELETE_MEAL_SUCCESS = "DELETE_MEAL_SUCCESS";
export const DELETE_MEAL_FAIL = "DELETE_MEAL_FAIL";

export const UPDATE_MEAL_LOADING = "UPDATE_MEAL_LOADING";
export const UPDATE_MEAL_SUCCESS = "UPDATE_MEAL_SUCCESS";
export const UPDATE_MEAL_FAIL = "UPDATE_MEAL_FAIL";
export const CLEAN_MEAL = "CLEAN_MEAL";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
