import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../utilities";

const initialState = {
  nutritions: [],
  error: null,
  loading: false
};

const addMealLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const addMealSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
};

const addMealFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const nutritionsLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const getNutritionsSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    nutritions: payload,
    error: null,
    loading: false
  });
};

const getNutritionsFail = (state, action) => {
  return updateObject(state, {
    nutritions: [],
    error: action.error,
    loading: false
  });
};

const cleanNutritions = (state, action) => {
  return updateObject(state, initialState);
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NUTRITIONS_LOADING: return nutritionsLoading(state, action);
    case actionTypes.GET_NUTRITIONS_SUCCESS: return getNutritionsSuccess(state, action);
    case actionTypes.GET_NUTRITIONS_FAIL: return getNutritionsFail(state, action);
    case actionTypes.CLEAN_NUTRITIONS: return cleanNutritions(state, action);
    case actionTypes.ADD_MEAL_LOADING:return addMealLoading(state, action);
    case actionTypes.ADD_MEAL_SUCCESS:return addMealSuccess(state, action);
    case actionTypes.ADD_MEAL_FAIL:return addMealFail(state, action);
    
    default:
      return state;
  }
};

export default reducer;
