import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export const Separator = styled.div`
    padding: 0;
    margin: ${props => props.margin ? props.margin : '20px 0'};
    width: 100%;
    height: 1px;
    background: var(--neutral-30p);
`;

export const StyledInput = styled.input`
    color: ${props => props.color || 'var(--neutral-90p)' };
    font-size: 16px;
    padding: 0 0.875rem;
    height: ${props => props.height || '44px' };
    border-style: groove;
    margin-right:5px;
    border:1px solid var(--neutral-30p);
    
    &.invalid{
        border-color: var(--secondary-red);
    }

    &:disabled {
        border:1px solid var(--neutral-20p);
        color: var(--neutral-50p);
    }

    &:focus {
        outline: none;
        color: var(--neutral-90p);
    }

    &::-webkit-input-placeholder { /* Edge */
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }

    &::placeholder {
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }
`;

export const StyledTextArea = styled.textarea`
    font-family: InterUI-Regular;
    height: ${props => props.height || '76px' };
    border: 1px solid var(--neutral-30p);
    padding: 0.625rem;
    overflow: auto;
    font-size: 16px;
    border:1px solid var(--neutral-30p);
    resize: none;
    &:focus {
        outline: none;
        color: var(--neutral-90p);
    }
    &:disabled {
        border:1px solid var(--neutral-20p);
        color: var(--neutral-50p);
    }
`;

export const ExternalLink = styled.a`
    color: ${props => props.color ? props.color : 'var(--blue-shade-light)'};
    text-decoration: ${props => props.underline ? 'underline' : 'none'};

    &:hover {
        cursor: pointer;
    }
`;

export const  StyledText = styled.div`
    color: ${props => props.color ? props.color : 'var(--blue-shade-light)'};
    text-decoration: ${props => props.underline ? 'underline' : 'none'};
    font-size: 16px;
    font-family: InterUI-Regular;
    padding-left: 16px;

    &:hover {
        cursor: pointer;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 1rem;
    padding-top: 0.5rem;
    & > .input-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: max-content;
    }
    & > .title {
        padding: 0.5rem 0;
        font-family: InterUI-SemiBold;
        color: var(--neutral-90p);
    }
    & > .value {
        font-family: InterUI-Regular;
        color: var(--neutral-60p);
    }
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
`;

export const InputContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 44px;
`;


export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FlexRowSpaced = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const FlexRowCentered = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const FlexRowWrapped = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;

`;

export const FlexColumnCentered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ErrorMessage = styled.div`
    width: 100%;
    background: #F2DEDE;
    border: 1px solid #EBCCCC;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    & > .message {
        font-family: InterUI-Regular;
        font-size: 16px;
        color: #AB352D;
        letter-spacing: 0;
        text-align: left;
        line-height: 25px;
    }
`;

export const StyledLink = styled(Link)`
    font-family: InterUI-Medium;
    font-size: 16px;
    color: #0061A8;
    text-decoration: none;
`

export const Spinner = (props) => {
    return (
        <FontAwesomeIcon style={{ color: 'var(--orange-shade-dark)'}}  icon={faCircleNotch} spin size="2x" {...props} />
    );
}