import styled from "styled-components";
import {StyledTextArea} from "./CommonStyledComponent";
import {LButton} from "../../components/ui/Buttons";

const Form = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 770px;
  margin: 0 auto;
  font-family: InterUI-Regular;
`;

const SubTitle = styled.div`
  font-family: InterUI-SemiBold;
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 22px;
`;

const StyledTextAreaNew = styled(StyledTextArea)`
  font-size: 16px;
  border: 1px solid var(--neutral-30p);
  margin-bottom: 22px;
  margin-top: 12px;
`;

const LButtoBlack = styled(LButton)`
  background: var(--primary-blue);
  margin: 0 auto;
  padding: 10px 24px;
  width: 187px;
  height: 44px;
  margin-top: 30px;
  margin-bottom: 26px;
  &:active {
    background: var(--orange-shade-dark);
  }
  &:hover {
    background: var(--primary-blue);
  }
`;

const LTButtoBlack = styled(LButtoBlack)`
  background: transparent;
  color: var(--neutral-80p);
  border: 2px solid var(--neutral-50p);
  margin: 0 auto;
  padding: 10px 24px;
  width: 187px;
  height: 44px;
  margin-top: 30px;
  margin-bottom: 26px;
  &:hover {
    background: var(--neutral-80p);
    color: #ffffff;
  }
  &:active {
    color: var(--neutral-80p);
    background: var(--neutral-50p);
  }
`;


const All = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  max-width: 1046px;
  padding: 10px;
  margin: 0 auto;
  padding-top: 65px;
  align-items: center;
`;

const tableTitle = styled.div`
  font-family: InterUI-SemiBold;
  border-top: 1px solid silver;
  padding-top: 33px;
  margin-bottom: 16px;
  width: 1046px;
  font-size: 20px;
`;

export default {
  Form,
  StyledTextArea,
  StyledTextAreaNew,
  SubTitle,
  LButtoBlack,
  LTButtoBlack,
  All,
  tableTitle
};
