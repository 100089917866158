import React from "react";
import { Link } from "react-router-dom";
import { Menu, Item } from "react-contexify";
import PropTypes from "prop-types";
import * as _ from 'lodash';

import "react-contexify/dist/ReactContexify.min.css";
import "../../../styles/context-menu-css-override.css";

import { TableStyles } from "../CommonTableComponents";
import Routes from "../../../containers/routes/routes";
import { WorkoutIdPath } from "../../../store/actions/workouts/workout-actions";

const getNameColumn = props => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "name",
            Header: "Name",
            accessor: (u) => _.get(u, ['fields', 'name', 'mapValue', 'fields', 'he', 'stringValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#1264A3",
                fontFamily: "InterUI-Medium"
            },
            Cell: props =>
                <Link 
                    style={{ color: "#1264A3" }} 
                    to={{ pathname: Routes.Plans.plans + '/' + 
                    _.get(props.original, ['fields', 'pid', 'stringValue'])
                     + "/editWorkout/" + props.original.name.replace(WorkoutIdPath, '') }}>
                    {props.value}
                </Link>,
            resizable: false
        }
    ];
};

const getWeekColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Week",
            Header: "Week",
            accessor: (u) => _.get(u, ['fields', 'week', 'integerValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656",
                fontFamily: "InterUI-Medium"
            },
            Cell: props => parseInt(props.value) + 1,
            maxWidth: 70,
            resizable: false
        }
    ];
};

const getDayColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Day",
            Header: "Day",
            accessor: (u) => _.get(u, ['fields', 'day', 'integerValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656",
                fontFamily: "InterUI-Medium"
            },
            Cell: props => parseInt(props.value) + 1,
            maxWidth: 70,
            resizable: false
        }
    ];
};

const getImageColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Image",
            Header: "Image",
            accessor: (u) => _.get(u, ['fields', 'image', 'stringValue']),
            style: { whiteSpace: "unset", wordBreak: "break-word" },
            Cell: props => (
                <>
                    {props.value ? <a href={props.value} style={{ color: "#1264A3" }} target={"_blank"}>
                        Image
                    </a> : null}
                </>),
            maxWidth: 80,
            resizable: false
        }
    ];
};

const getActionColumn = ActionsComponent => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Actions",
            Header: "Actions",
            accessor: "actions",
            Cell: props => <>{<ActionsComponent {...props} />}</>,
            maxWidth: 80,
            sortable: false,
            resizable: false
        }
    ];
};

export const ColumnDefinitions = {
    NameColumn: getNameColumn,
    WeekColumn: getWeekColumn,
    DayColumn: getDayColumn,
    ImageColumn: getImageColumn,
    ActionColumn: getActionColumn
};

export const ActionsContextMenu = props => {
    return (
        <Menu id="action_menu" className={"context-menu"}>
            <Item
                className={"context-menu-item"}
                style={{ fontFamily: "InterUI-Regular" }}
                onClick={() => {
                    console.log("excercise");
                }}>
                Exercises
      </Item>
            <Item
                className={"context-menu-item"}
                style={{ fontFamily: "InterUI-Regular" }}
                onClick={props.onEdit}>
                Edit
      </Item>
            <Item
                className={"context-menu-item"}
                style={{ fontFamily: "InterUI-Regular" }}
                onClick={props.onRemove}>
                Delete
      </Item>
        </Menu>
    );
};

ActionsContextMenu.propTypes = {
    onEdit: PropTypes.func,
    onRemove: PropTypes.func
};
