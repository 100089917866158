import uuidv4 from 'uuid/v4';


export const initialsByName = (name) => {
    const names = name ? name.split(' ') : '';
    let initials = names.length === 1 ? names[0].substring(0, 2).toUpperCase() : 
                                        names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const fullName = ({firstName, lastName, email}) => {
    if (!firstName && !lastName) {
        return email;
    }
    if (!firstName) {
        return lastName;
    }
    if (!lastName) {
        return firstName;
    }
    return firstName + ' ' + lastName;
}

export const getId = () => {
    return uuidv4();
}

export const getLimitByPageWidth = (defaultLimit) => {
    const limit = Math.floor(window.innerWidth/450);
    if (limit === 0) {
        return defaultLimit;
    }
    return limit < 4 ? limit * 2 : defaultLimit; 
}