import React, {useMemo} from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../../../styles/table-css-override.css";
import PropTypes from "prop-types";

import {ColumnDefinitions, ActionsContextMenu} from "./NutritionsTableComponents";
import {
  TablePagination,
  TableStyles,
  ActionCell
} from "../CommonTableComponents";

const NutritionsTable = (props) => {
  
  const tableDefaults = {
    ...props,
    defaultPageSize: props.defaultPageSize,
    minRows: props.minRows
      ? Math.min(props.data && props.data.length, props.minRows)
      : Math.min(props.data && props.data.length, 20),
    PaginationComponent: TablePagination
  };
  const idColumn = useMemo(() => ColumnDefinitions.getIdColumn(), [ColumnDefinitions.getIdColumn]);
  const nameColumn = useMemo(() => ColumnDefinitions.getNameColumn(), [ColumnDefinitions.getNameColumn]);
  const caloriesColumn = useMemo(() => ColumnDefinitions.getCaloriesColumn(), [ColumnDefinitions.getCaloriesColumn]);
  const contentsColumn = useMemo(() => ColumnDefinitions.getContentsColumn(), [ColumnDefinitions.getContentsColumn]);
  const imageColumn = useMemo(() => ColumnDefinitions.getImageColumn(), [ColumnDefinitions.getImageColumn]);
  const prepareTimeColumn = useMemo(() => ColumnDefinitions.getPrepareTimeColumn(), [ColumnDefinitions.getPrepareTimeColumn]);
  const actionColumn = useMemo(() => ColumnDefinitions.getActionColumn(ActionCell), [ColumnDefinitions.getActionColumn]);



  const columns = [
    ...idColumn,
    ...nameColumn,
    ...caloriesColumn,
    ...contentsColumn,
    ...imageColumn,
    ...prepareTimeColumn,
    ...actionColumn
  ];

  return (
    <>
      <ReactTable
        {...tableDefaults}
        loading={props.loading}
        style={TableStyles.tableStyle}
        data={props.data}
        columns={columns}
      />
      <ActionsContextMenu onEdit={props.onEdit} onDelete={props.onDelete} />
    </>
  );
};

NutritionsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  defaultPageSize: PropTypes.number,
  minRows: PropTypes.number,
  loading: PropTypes.bool,
  ActionCellComponent: PropTypes.element,
  showActions: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func

};

NutritionsTable.defaultProps = {
  showActions: true
};

export default NutritionsTable;
