import styled from "styled-components";
import { StyledInput } from "../../../components/common/CommonStyledComponent";

const LoginForm = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  max-width: 460px;
  padding: 10px;
  margin: 0 auto;
`;

const StyledInputNew = styled(StyledInput)`
  margin-bottom: 22px;
  margin-top: 12px;
`;

const SubTitle = styled.div`
  font-family: InterUI-SemiBold;
  font-size: 16px;
`;

const GeneralButton = styled.button`
  cursor: pointer;
  font-family: InterUI-SemiBold;
  text-align: center;
  width: max-content;
`;

const LButtoBlack = styled(GeneralButton)`
  font-family: InterUI-SemiBold;

  background: var(--primary-blue);
  border: 2px solid var(--orange-shade-dark);
  color: #ffffff;
  padding: 10px 24px;
  width: 187px;
  height: 44px;
  &:active {
    background: var(--orange-shade-dark);
  }

  &:disabled {
    background: var(--neutral-30p);
  }
`;

export default {
  LoginForm,
  StyledInputNew,
  SubTitle,
  LButtoBlack
};
