import React, {useMemo} from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../../../styles/table-css-override.css";
import PropTypes from "prop-types";

import {ColumnDefinitions, ActionsContextMenu} from "./MessagesTableComponents";
import {TablePagination,TableStyles,ActionCell} from "../CommonTableComponents";
// import LoadingComponent from "../../ui/LoadingComponent";

const MessagesTable = props => {
  // Table setup
  const tableDefaults = {
    ...props,
    defaultPageSize: props.defaultPageSize,
    minRows: props.minRows
      ? Math.min(props.data && props.data.length, props.minRows)
      : Math.min(props.data && props.data.length, 9),
    PaginationComponent: TablePagination
  };

  const dateColumn = useMemo(() => ColumnDefinitions.DateColumn(),[ColumnDefinitions.DateColumn]);

  const messageColumn = useMemo(() => ColumnDefinitions.MessageColumn(), [ColumnDefinitions.MessageColumn]);

  const titleColumn = useMemo(() => ColumnDefinitions.TitleColumn(), [ColumnDefinitions.TitleColumn]);

  const actionColumn = useMemo(() =>props.showActions? ColumnDefinitions.ActionColumn(props.ActionCellComponent ? props.ActionCellComponent : ActionCell): [],
    [ColumnDefinitions.ActionColumn,props.showActions,props.ActionCellComponent]);

  const columns = [
    ...dateColumn,
    ...messageColumn,
    ...titleColumn,
    ...actionColumn
  ];

  return (
    <>
      <ReactTable
        loading={props.loading}
        {...tableDefaults}
        style={TableStyles.tableStyle}
        data={props.data}
        columns={columns}/>
      <ActionsContextMenu onResend={props.onResend} onRemove={props.onRemove} />
    </>
  );
};

MessagesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  minRows: PropTypes.number,
  ActionCellComponent: PropTypes.element,
  showActions: PropTypes.bool,
  // Used only with default action component
  onResend: PropTypes.func,
  onRemove: PropTypes.func
};

MessagesTable.defaultProps = {
  showActions: true
};

export default MessagesTable;
