import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Routes from "../routes/routes";

import Users from "../routes/Users/Users";
import Feeds from "../routes/Feeds/Feeds";
import Plans from "../routes/Plans/Plans";
import Messages from "../routes/Messages/Messages";
import Nutritions from "../routes/Nutritions/Nutritions";
import NewMeal from "../routes/Nutritions/NewMeal";
import UpdateMeal from '../routes/Nutritions/UpdateMeal'
import NewPlan from "../routes/Plans/NewPlan";
import NewWorkout from "../routes/Plans/Workouts/NewWorkout";
import NewExercise from "../routes/Plans/Exercise/NewExercise";
import UpdateUser from "../routes/Users/UpdateUser";

const NavigationSwitch = () => {
    return (
        <Switch>
            <Route path={Routes.Users.updateUser + "/:id"} component={UpdateUser} exact={false}></Route>
            <Route path={Routes.Users.users} component={Users} exact={false}></Route>
            <Route path={Routes.Feeds.feeds} component={Feeds} exact={false} />
            <Route path={Routes.Nutritions.updateMeal + "/:id"} component={UpdateMeal} exact={false} />
            <Route path={Routes.Nutritions.nutritions} component={Nutritions} exact={true} />
            <Route path={Routes.Nutritions.newMeal} component={NewMeal} exact={true} />
            <Route path={Routes.Messages.messages} component={Messages} exact={true} />
            <Route path={Routes.Plans.plans} component={Plans} exact={true} />
            <Route path={Routes.Plans.newPlan} component={NewPlan} exact={true} />
            <Route path={Routes.Plans.editPlan} component={NewPlan} exact={true} />
            <Route path={Routes.Plans.plans + '/:pid/newWorkout'} component={NewWorkout} exact={true} />
            <Route path={Routes.Plans.plans + '/:pid/editWorkout/:id'} component={NewWorkout} exact={true} />
            <Route path={Routes.Plans.plans + '/:pid/editWorkout/:id/newExercise'} component={NewExercise} exact={true} />
            <Route path={Routes.Plans.plans + '/:pid/editWorkout/:id/editExercise/:eid'} component={NewExercise} exact={true} />
            <Redirect to={Routes.Users.users} />
        </Switch>
    );
};

export default NavigationSwitch;
