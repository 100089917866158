import React from "react";
import { Menu, Item } from "react-contexify";
import PropTypes from "prop-types";
import * as _ from 'lodash';

import "react-contexify/dist/ReactContexify.min.css";
import "../../../styles/context-menu-css-override.css";

import { TableStyles } from "../CommonTableComponents";
import { NutritionIdPath } from "../../../store/actions/nutritions/nutritions-actions";
const style={
    whiteSpace: "unset",
    wordBreak: "break-word",
    alignSelf: "center",
    fontFamily: "InterUI-Regular",
    fontSize: '13px'
}
const getIdColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "id",
            Header: "ID",
            accessor: (nuts)=> _.get(nuts, [ 'name']),
            style,
            Cell: props => props.value.replace(NutritionIdPath, ''),
            resizable: false,
            sortable: false
        }
    ];
};


const getNameColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "name",
            Header: "Name",
            accessor: (nuts)=> _.get(nuts, ['fields', 'name', 'mapValue', 'fields', 'en', 'stringValue']),
            style,
            Cell: props => props.value,
            
            resizable: false,
            sortable: false
        }
    ];
};

const getCaloriesColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "calories",
            Header: "Calories",
            accessor: (nuts)=> _.get(nuts, ['fields', 'nutritionValues', 'mapValue', 'fields', 'calories', 'integerValue']),
            style,
            Cell: props => props.value,
            
            resizable: false,
            sortable: false
        }
    ];
};

const getContentsColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "contents",
            Header: "Contents",
            accessor: (nuts)=> _.get(nuts, ['fields', 'contents', 'stringValue']),
            style,
            Cell: props => props.value,
            
            resizable: false,
            sortable: false
        }
    ];
};


const getImageColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "image",
            Header: "Image",
            accessor: (nuts)=> _.get(nuts, ['fields', 'image', 'stringValue']),
            style,
            Cell: props => <img alt={props.value} src={props.value} width="60" height="60" />,
            
            resizable: false,
            sortable: false
        }
    ];
};


const getPrepareTimeColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "prepareTime",
            Header: "PrepareTime",
            accessor: (nuts)=> _.get(nuts, ['fields', 'prepareTime', 'integerValue']),
            style,
            Cell: props => props.value,
            
            resizable: false,
            sortable: false
        }
    ];
};

const getActionColumn = ActionsComponent => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Actions",
            Header: "Actions",
            accessor: "actions",
            Cell: props => <>{<ActionsComponent {...props} />}</>,
            maxWidth: 80,
            sortable: false,
            resizable: false
        }
    ];
};
export const ColumnDefinitions = {
    getIdColumn,
    getNameColumn,
    getCaloriesColumn,
    getContentsColumn,
    getImageColumn,
    getPrepareTimeColumn,
    getActionColumn
};

export const ActionsContextMenu = props => {
    return (
        <Menu id="action_menu" className={"context-menu"}>
            <Item
                style={{ fontFamily: "InterUI-Regular" }}
                className={"context-menu-item"}
                onClick={props.onEdit}>
                Edit
            </Item>
            <Item
                style={{ fontFamily: "InterUI-Regular" }}
                className={"context-menu-item"}
                onClick={props.onDelete}>
                Delete
            </Item>
        </Menu>
    );
};

ActionsContextMenu.propTypes = {
    onRemove: PropTypes.func,
    onEdit: PropTypes.func
};
