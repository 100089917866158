import React from "react";
import ReactDOM from "react-dom";
import {HashRouter} from "react-router-dom";

import {createStore, applyMiddleware, compose, combineReducers} from "redux";
import thunk from "redux-thunk";
import {Provider} from "react-redux";

import "./styles/index.css";
import App from "./app/App";
import {reducers} from "./store/reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  users: reducers.usersReducer,
  user: reducers.userReducer,
  plans: reducers.plansReducer,
  plan: reducers.planReducer,
  exercises: reducers.exercisesReducer,
  exercise: reducers.exerciseReducer,
  feeds: reducers.feedsReducer,
  auth: reducers.authReducer,
  messages: reducers.messagesReducer,
  workouts: reducers.workoutsReducer,
  nutritions: reducers.nutritionsReducer,
  meal: reducers.mealReducer,
  workout: reducers.workoutReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
