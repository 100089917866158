import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../utilities";

const initialState = {
  workout: null,
  error: null,
  loading: false
};

const workoutLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const getWorkoutSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    workout: payload,
    error: null,
    loading: false
  });
};

const getWorkoutFail = (state, action) => {
  return updateObject(state, {
    workout: null,
    error: action.error,
    loading: false
  });
};

const updateWorkoutLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const updateWorkoutSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    workout: action.workout
  });
};

const updateWorkoutFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const deleteWorkoutLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const deleteWorkoutSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
};

const deleteWorkoutFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const addWorkoutLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const addWorkoutSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    workout: action.workout
  });
};

const addWorkoutFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const cleanWorkout = (state, action) => {
  return updateObject(state, {
    workout: null,
    error: null,
    loading: false
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WORKOUT_LOADING:return workoutLoading(state, action);
    case actionTypes.GET_WORKOUT_SUCCESS:return getWorkoutSuccess(state, action);
    case actionTypes.GET_WORKOUT_FAIL:return getWorkoutFail(state, action);
    case actionTypes.UPDATE_WORKOUT_LOADING:return updateWorkoutLoading(state, action);
    case actionTypes.UPDATE_WORKOUT_SUCCESS:return updateWorkoutSuccess(state, action);
    case actionTypes.UPDATE_WORKOUT_FAIL:return updateWorkoutFail(state, action);
    case actionTypes.DELETE_WORKOUT_LOADING:return deleteWorkoutLoading(state, action);
    case actionTypes.DELETE_WORKOUT_SUCCESS:return deleteWorkoutSuccess(state, action);
    case actionTypes.DELETE_WORKOUT_FAIL:return deleteWorkoutFail(state, action);
    case actionTypes.ADD_WORKOUT_LOADING:return addWorkoutLoading(state, action);
    case actionTypes.ADD_WORKOUT_SUCCESS:return addWorkoutSuccess(state, action);
    case actionTypes.ADD_WORKOUT_FAIL:return addWorkoutFail(state, action);
    case actionTypes.CLEAN_WORKOUT: return cleanWorkout(state, action);
    default:
      return state;
  }
};

export default reducer;
