import axiosInstance from "../../axios";

// const LOGIN_URL = 'https://us-central1-yuvalgym.cloudfunctions.net/login'; // DEV
const LOGIN_URL = 'https://europe-west2-yuvalgym-ad810.cloudfunctions.net/login'; // PROD

const login = async (email, password) => {
	try {
		const body = {
			email: email,
			password: password
		}
		const {data, status, error} = await axiosInstance.post(LOGIN_URL, body);
		return {data, status, error};
	}
	catch(e) {
		return {error: 'Not authenticated'};
	}
}

export const authApi = {
	login: login
}