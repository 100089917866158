import nutritionsApi from "../../api/nutritions-api";
import * as actionTypes from '../actionTypes';
import { projectID } from "../../api/routes";

export const NutritionIdPath = 'projects/' + projectID +'/databases/(default)/documents/meals/';




export const getNutritionsLoading = () => {
    return {
        type: actionTypes.GET_NUTRITIONS_LOADING
    };
};
export const getNutritionsSuccess = nutritions => {
    return {
        type: actionTypes.GET_NUTRITIONS_SUCCESS,
        payload: nutritions
    };
};
export const getNutritionsFail = (err) => {
    return {
        type: actionTypes.GET_NUTRITIONS_FAIL,
        error: err
    };
};

export const addMealLoading = () => {
    return {
        type: actionTypes.ADD_MEAL_LOADING
    };
};

export const addMealSuccess = () => {
    return {
        type: actionTypes.ADD_MEAL_SUCCESS,
    };
};

export const addMealFail = err => {
    return {
        type: actionTypes.ADD_MEAL_FAIL,
        error: err
    };
};

export const addMeal = (newMeal) => {
    return async (dispatch, getState) => {
        const meal = {
            "fields": {
                "image": {
                    "stringValue": newMeal.image
                },
                "nutritionValues": {
                    "mapValue": {
                        "fields": {
                            "calories": {
                                "integerValue": newMeal.nutritionValues.calories
                            },
                            "carbs": {
                                "integerValue": newMeal.nutritionValues.carbs
                            },
                            "fats": {
                                "integerValue": newMeal.nutritionValues.fats
                            },
                            "proteins": {
                                "integerValue": newMeal.nutritionValues.proteins
                            }
                        }
                    }
                },
                "type": {
                    "stringValue": newMeal.type
                },
                "name": {
                    "mapValue": {
                        "fields": {
                            "en": {
                                "stringValue": newMeal.name
                            },
                            "he": {
                                "stringValue": newMeal.name
                            }
                        }
                    }
                },
                
                "contents": {
                    "stringValue": newMeal.contents
                },
                "prepareTime": {
                    "integerValue": newMeal.prepareTime
                },
                "description": {
                    "mapValue": {
                        "fields": {
                            "he": {
                                "stringValue": newMeal.description
                            }
                        }
                    }
                }
            }
        }
        dispatch(addMealLoading());
        const { status, data, error } = await nutritionsApi.addMeal(getState().auth.token, meal);
        if (status === 200 && data.name) {
            return dispatch(addMealSuccess());
        } else {
            return dispatch(addMealFail(error));
        }
    };
};

export const cleanNutritions = () => { 
    return {
        type: actionTypes.CLEAN_NUTRITIONS
    }
}

export const getNutritions = (type) => {
    return async (dispatch,getState) => {
        dispatch(getNutritionsLoading())
        const{status,data,error} = await nutritionsApi.fetchNutritions(getState().auth.token,type)
        if (status === 200) {
            dispatch(getNutritionsSuccess(data));
        } else {
            dispatch(getNutritionsFail(error));
        }
    }
}


