import React, { useEffect } from "react";
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { ToastProvider } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import { authCheckState } from "../store/actions/auth/auth-actions";
import Logout from "../containers/routes/Logout/Logout";
import LoginPage from "../containers/routes/Login/Login";
import Routes from "../containers/routes/routes";
import PrivateRoute from "../security/PrivateRoute/PrivateRoute";
import BaseLayout from "../containers/Navigation/BaseLayout";

const App = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authCheckState());
    }, []);

    const spinnerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    };

    let view = auth.loading ?
        <div style={spinnerStyle}>
            <FontAwesomeIcon icon={faSync} spin size="5x" />
        </div>
        :
        <Switch>
            <PrivateRoute path={Routes.Common.app} exact={false} component={BaseLayout}></PrivateRoute>
            {!auth.token ? <Route path={Routes.Common.login} component={LoginPage} exact={true}></Route> : null}
            <Route path={Routes.Common.logout} component={Logout} exact={true} />
            <Redirect to={Routes.Common.app} />
        </Switch>;

    return <ToastProvider placement="bottom-center">{view}</ToastProvider>;
};

export default withRouter(App);
