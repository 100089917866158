import React, {useMemo} from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import "react-table/react-table.css";
import "../../../styles/table-css-override.css";

import {ColumnDefinitions, ActionsContextMenu} from "./FeedsTableComponents";
import {TablePagination,TableStyles,ActionCell} from "../CommonTableComponents";

const FeedsTable = props => {
  // Table setup
  const tableDefaults = {
    ...props,
    defaultPageSize: props.defaultPageSize,
    minRows: props.minRows
      ? Math.min(props.data && props.data.length, props.minRows)
      : Math.min(props.data && props.data.length, 9),
    PaginationComponent: TablePagination
  };
  const dateColumn = useMemo(() => ColumnDefinitions.DateColumn(),[ColumnDefinitions.DateColumn]);

  const messageColumn = useMemo(() => ColumnDefinitions.MessageColumn(),[ColumnDefinitions.MessageColumn]);

  const videoColumn = useMemo(() => ColumnDefinitions.VideoColumn(),[ColumnDefinitions.VideoColumn]);

  const actionColumn = useMemo(() =>props.showActions? ColumnDefinitions.ActionColumn(props.ActionCellComponent ? props.ActionCellComponent : ActionCell): [],
    [ColumnDefinitions.ActionColumn,props.showActions,props.ActionCellComponent]);

  const columns = [
    ...dateColumn,
    ...messageColumn,
    ...videoColumn,
    ...actionColumn
  ];

  return (
    <>
      <ReactTable
        {...tableDefaults}
        style={TableStyles.tableStyle}
        data={props.data}
        columns={columns}
      />
      <ActionsContextMenu onEdit={props.onEdit} onRemove={props.onRemove} />
    </>
  );
};

FeedsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      message: PropTypes.string,
      video: PropTypes.string
    })
  ),
  defaultPageSize: PropTypes.number,
  minRows: PropTypes.number,
  ActionCellComponent: PropTypes.element,
  showActions: PropTypes.bool,
  // Used only with default action component
  contextMenuEventHandlers: PropTypes.shape({
    onEdit: PropTypes.func,
    onRemove: PropTypes.func
  })
};

FeedsTable.defaultProps = {
  showActions: true
};

export default FeedsTable;
