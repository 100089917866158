import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import * as _ from 'lodash';


import NewModalStyle from "../../../../components/common/NewModalStyledComponents";
import Routes from "../../routes";
import ExercisesTable from "../../../../components/tables/Exercises/ExercisesTable";
import { getExercises, updateIndex, cleanExercises } from "../../../../store/actions/exercises/exercises-actions";
import { getWorkout, updateWorkout, addWorkout, WorkoutIdPath, cleanWorkout } from "../../../../store/actions/workouts/workout-actions";
import { PageSection } from "../../../../components/templates/PageSection";
import { Spinner, FlexColumn, Separator } from "../../../../components/common/CommonStyledComponent";
import { BackButtonTBlue } from "../../../../components/ui/Buttons";
import { deleteExercise, ExerciseIdPath } from "../../../../store/actions/exercises/exercise-actions";
import { DeleteExercisePopup } from "../Exercise/DeleteExercisePopup";

const NewWorkout = ({ history, match }) => {

    const dispatch = useDispatch();

    const { addToast } = useToasts();

    const exercises = useSelector(state => state.exercises.exercises);
    const loading = useSelector(state => state.exercises.loading);
    const workout = useSelector(state => state.workout.workout);
    const workoutLoading = useSelector(state => state.workout.loading);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [week, setWeek] = useState(1);
    const [day, setDay] = useState(1);

    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [exToDelete, setExToDelete] = useState(null);

    useEffect(() => {
        if (match.params.id) {
            dispatch(getWorkout(match.params.id)).then((data) => {
                if (!data.error) {
                    dispatch(getExercises(match.params.id));
                }
                else {
                    addToast("Error while fetching workout", { appearance: "error", autoDismiss: true });
                    onBack();
                }
            });
        }
        return () => {
            if (history.location.pathname.indexOf('editWorkout') < 0) {
                dispatch(cleanWorkout());
                dispatch(cleanExercises());
            }
        }
    }, []);

    useEffect(() => {
        if (workout && match.params.id) {
            setName(_.get(workout, ['fields', 'name', 'mapValue', 'fields', 'he', 'stringValue']));
            setDescription(_.get(workout, ['fields', 'description', 'mapValue', 'fields', 'he', 'stringValue']));
            setWeek(parseInt(_.get(workout, ['fields', 'week', 'integerValue'])) + 1);
            setDay(parseInt(_.get(workout, ['fields', 'day', 'integerValue'])) + 1);
        }
    }, [workout]);

    const onBack = () => {
        if (history.length > 2) {
            history.goBack();
        }
        else {
            history.replace(Routes.Plans.plans);
        }
    }

    const onEditWorkout = () => {
        const w = {
            pid: match.params.pid,
            name: name,
            description: description,
            week: parseInt(week) - 1,
            day: parseInt(day) - 1,
        }
        if (workout && workout.name) {
            dispatch(updateWorkout(workout, w)).then(data => {
                if (!data.error) {
                    addToast("Update successfull", { appearance: "success", autoDismiss: true });
                } else {
                    addToast("Error while updating", { appearance: "error", autoDismiss: true });
                }
            });
        } else {
            dispatch(addWorkout(w)).then(data => {
                if (!data.error) {
                    addToast("Workout added successfully", { appearance: "success", autoDismiss: true });
                    const wid = data.workout.name.replace(WorkoutIdPath, '');
                    history.replace(Routes.Plans.plans + '/' + match.params.pid + '/editWorkout/' + wid);
                } else {
                    addToast("Error while creating workout", { appearance: "error", autoDismiss: true });
                }
            });
        }
    };

    const onEdit = props => {
        const eid = props.props.name.replace(ExerciseIdPath, '');
        history.push(Routes.Plans.plans + '/' + match.params.pid + '/editWorkout/' + match.params._id + '/editExercise/' + eid);
    };

    const onDeleteExercise = () => {
        dispatch(deleteExercise(exToDelete)).then((data) => {
            if (!data.error) {
                dispatch(getExercises(match.params.id));
                addToast("Exercise deleted successfully", {appearance: "success", autoDismiss: true });
                setExToDelete(null);
                setShowDeletePopup(false);
            } else {
                addToast("Error while deleting exercise", {appearance: "error", autoDismiss: true });
            }
        });
    };

    const onDelete = (props) => {
        setExToDelete(props.props.name.replace(ExerciseIdPath, ''));
        setShowDeletePopup(true);
    }

    const onNewExercies = () => {
        history.push(Routes.Plans.plans + '/' + match.params.pid + '/editWorkout/' + match.params.id + '/newExercise')
    }

    const onMoveUp = (props) => {
        console.log(props);
        const ind = exercises.findIndex((e) => e.name === props.props.name);
        if (ind > 0) {
            const exs = [...exercises];
            const t = exs[ind-1];
            exs[ind-1] = props.props;
            exs[ind] = t;
            dispatch(updateIndex(exs)).then(()=> {
                dispatch(getExercises(match.params.id));
            });
        }
    }

    const onMoveDown = (props) => {
        console.log(props);
        console.log(props);
        const ind = exercises.findIndex((e) => e.name === props.props.name);
        if (ind < exercises.length - 1) {
            const exs = [...exercises];
            const t = exs[ind+1];
            exs[ind+1] = props.props;
            exs[ind] = t;
            dispatch(updateIndex(exs)).then(()=> {
                dispatch(getExercises(match.params.id));
            });
        }
    }

    return (
        <PageSection>
            <NewModalStyle.Form>
                <NewModalStyle.Title>
                    <BackButtonTBlue onClick={onBack}>Back</BackButtonTBlue>
                    <h3 style={{ paddingLeft: '2rem' }}>
                        {workout ? 'Edit Workout' : 'New Workout'}
                        {workoutLoading ? <Spinner size="1x" style={{ padding: '0px 10px' }}></Spinner> : null}
                    </h3>
                </NewModalStyle.Title>
                <NewModalStyle.Details>
                    <NewModalStyle.Inputs>
                        <NewModalStyle.SubTitle>Name</NewModalStyle.SubTitle>
                        <NewModalStyle.StyledInputNew
                            value={name}
                            onChange={(e) => setName(e.target.value)}>
                        </NewModalStyle.StyledInputNew>
                        <NewModalStyle.SubTitle>Description</NewModalStyle.SubTitle>
                        <NewModalStyle.StyledTextAreaNew
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ marginBottom: "12px" }}>
                        </NewModalStyle.StyledTextAreaNew>
                        <NewModalStyle.WeekDay>
                            <FlexColumn>
                                <NewModalStyle.SubTitle>Week</NewModalStyle.SubTitle>
                                <NewModalStyle.StyledInputNew
                                    type={"number"}
                                    min={1}
                                    value={week}
                                    onChange={(e) => setWeek(e.target.value)}
                                    style={{ maxWidth: "187px", marginRight: "30px" }}>
                                </NewModalStyle.StyledInputNew>
                            </FlexColumn>
                            <FlexColumn>
                                <NewModalStyle.SubTitle>Day</NewModalStyle.SubTitle>
                                <NewModalStyle.StyledInputNew
                                    value={day}
                                    type={"number"}
                                    min={1}
                                    onChange={(e) => setDay(e.target.value)}>
                                </NewModalStyle.StyledInputNew>
                            </FlexColumn>
                        </NewModalStyle.WeekDay>
                        <NewModalStyle.LButtoBlack
                            disabled={!name || !description || !week || !day}
                            onClick={() => { onEditWorkout(workout ? workout._id : null) }}
                            style={{ width: "187px", height: "44px" }}>
                            Save
                        </NewModalStyle.LButtoBlack>
                    </NewModalStyle.Inputs>
                </NewModalStyle.Details>
                {workout && workout.name ?
                    <>
                        <Separator />
                        <FlexColumn style={{ padding: '0 5%' }}>
                            <NewModalStyle.WorkOutTitle>
                                <div style={{ fontSize: "20px" }}>Exercises</div>
                                <NewModalStyle.LButtoBlack style={{ fontSize: "14px", height: "100%" }} onClick={onNewExercies}>
                                    New Exercise
                                </NewModalStyle.LButtoBlack>
                            </NewModalStyle.WorkOutTitle>
                            <ExercisesTable
                                loading={loading}
                                data={exercises}
                                defaultPageSize={10}
                                minRows={10}
                                onEdit={onEdit}
                                onRemove={onDelete}
                                onMoveUp={onMoveUp}
                                onMoveDown={onMoveDown}>
                            </ExercisesTable>
                        </FlexColumn>
                    </>
                    : null}
                    {showDeletePopup ? <DeleteExercisePopup onDelete={onDeleteExercise} closeModal={()=>{
                            setExToDelete(null);
                            setShowDeletePopup(false);
                        }}/> : null}
            </NewModalStyle.Form>
        </PageSection>
    );
};

export default withRouter(NewWorkout);
