import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const StyledSelect = props => {
  return (
    <Select
      {...props}
      styles={{
        menu: style => ({
          ...style,
          backgroundColor: "hsl(0,0%,100%)",
          borderRadius: "0",
          boxShadow:
            "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)",
          marginBottom: "8px",
          marginTop: "8px",
          zIndex: "20",
          boxSizing: "border-box",
          minWidth: "max-content"
        }),
        option: style => ({
          ...style,
          padding: "0.5rem 1.5rem",
          color: "black",
          ":hover": {
            backgroundColor: "var(--neutral-20p)"
          },
          fontFamily: "InterUI-Regular"
        }),
        container: style => ({
          ...style,
          width: "100%",
          fontFamily: "InterUI-Regular"
        }),
        control: style => ({
          ...style,
          height: props.height ? props.height : "44px",
          borderColor: "var(--neutral-30p)",
          ":hover": {
            borderColor: "var(--neutral-30p)",
            boxShadow: "0 0 0 0 var(--neutral-30p)"
          }
        }),
        indicatorSeparator: style => ({
          ...style,
          display: "none"
        }),
        indicatorsContainer: style => ({
          ...style,
          display: "flex",
          fill: "var(--orange-shade-dark)",
          svg: {
            fill: "var(--orange-shade-dark)"
          }
        })
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        height: "44px",
        colors: {
          ...theme.colors,
          primary: "var(--neutral-30p)"
        }
      })}
    />
  );
};

StyledSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string
  }),
  height: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default StyledSelect;
