import React from 'react';
import Layout from '../../hoc/Layout';
import NavigationSwitch from './NavigationSwitch';

const BaseLayout = () => {
	return <Layout>
		<NavigationSwitch />
	</Layout>
}

export default BaseLayout;