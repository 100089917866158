import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {contextMenu} from "react-contexify";

import moreActions from "../../assets/images/icons/more-actions-gray.svg";

import Pagination from "./Pagination";

const tableStyle = {
  border: "none",
  fontFamily: "InterUI-Regular",
  color: "var(--neutral-90p)",
  margin: "0 auto",
  width: "100%"
};

const columnStyle = {
  height: "80px",
  border: "none",
  display: "flex",
  flex: "80 0 auto",
  width: "80px"
};
const headerStyle = {
  height: "54px",
  border: "none",
  display: "flex",
  borderBottom: "1px solid silver"
};

const generalColumnStyle = {
  style: columnStyle,
  headerStyle: headerStyle
};

export const TableStyles = {
  tableStyle,
  columnStyle,
  headerStyle,
  generalColumnStyle
};

export const StyledLink = styled(Link)`
  font-family: InterUI-Medium;
  font-size: 16px;
  color: var(--blue-shade-medium);
  line-height: 16px;
  text-decoration: none;
`;

const TablePaginationContainer = styled.div`
  width: 100%;
  height: 54px;
  border-top: 1px solid var(--neutral-50p);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 19px;
`;

export const TablePagination = props => {
  return (
    <>
      {props.pages > 1 ? (
        <TablePaginationContainer>
          <Pagination {...props}></Pagination>
        </TablePaginationContainer>
      ) : null}
    </>
  );
};

const ActionsCellContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > img {
    cursor: pointer;
  }
  & > img:active {
    filter: invert(1);
  }
`;

const DefaultContextMenuActionCell = props => {
  const handleEvent = e => {
    contextMenu.show({
      id: "action_menu",
      event: e,
      props: props.original
    });
  };
  return <img src={moreActions} onClick={handleEvent} alt="More actions" />;
};

export const ActionCell = props => {
  return (
    <ActionsCellContainer>
      <DefaultContextMenuActionCell {...props} />
    </ActionsCellContainer>
  );
};
