import React, { useMemo } from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import "react-table/react-table.css";
import "../../../styles/table-css-override.css";

import {ColumnDefinitions,ActionsContextMenu} from "./ExercisesTableComponents";
import {TablePagination,TableStyles,ActionCell} from "../CommonTableComponents";
// import LoadingComponent from "../../ui/LoadingComponent";

const ExercisesTable = props => {
  // Table setup
  const tableDefaults = {
    ...props,
    defaultPageSize: props.defaultPageSize,
    minRows: props.minRows
      ? Math.min(props.data && props.data.length, props.minRows)
      : Math.min(props.data && props.data.length, 9),
    PaginationComponent: TablePagination
  };

  const nameColumn = useMemo(() => ColumnDefinitions.NameColumn(),[ColumnDefinitions.NameColumn]);

  const repeatsColumn = useMemo(() => ColumnDefinitions.RepeatsColumn(),[ColumnDefinitions.RepeatsColumn]);

  const typeColumn = useMemo(() => ColumnDefinitions.TypeColumn(),[ColumnDefinitions.TypeColumn]);

  const setsColumn = useMemo(() => ColumnDefinitions.SetsColumn(), [ColumnDefinitions.SetsColumn]);

  const descriptionColumn = useMemo(() => ColumnDefinitions.DescriptionColumn(),[ColumnDefinitions.DescriptionColumn]);

  const videoURLColumn = useMemo(() => ColumnDefinitions.VideoURLColumn(), [ColumnDefinitions.VideoURLColumn]);

  const actionColumn = useMemo(() =>props.showActions? 
  ColumnDefinitions.ActionColumn(props.ActionCellComponent ? props.ActionCellComponent : ActionCell) : [],
    [ColumnDefinitions.ActionColumn,props.showActions,props.ActionCellComponent]
  );

  const columns = [
    ...nameColumn,
    ...repeatsColumn,
    ...typeColumn,
    ...setsColumn,
    ...descriptionColumn,
    ...videoURLColumn,
    ...actionColumn
  ];

  return (
    <>
      <ReactTable
        {...tableDefaults}
        style={TableStyles.tableStyle}
        data={props.data}
        columns={columns}
      />
      <ActionsContextMenu 
        onEdit={props.onEdit} 
        onRemove={props.onRemove} 
        onMoveUp={props.onMoveUp} 
        onMoveDown={props.onMoveDown}/>
    </>
  );
};

ExercisesTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      repeats: PropTypes.number,
      sets: PropTypes.number,
      description: PropTypes.string,
      videoURL: PropTypes.string
    })
  ),
  defaultPageSize: PropTypes.number,
  minRows: PropTypes.number,
  ActionCellComponent: PropTypes.element,
  showActions: PropTypes.bool,
};

ExercisesTable.defaultProps = {
  showActions: true
};

export default ExercisesTable;
