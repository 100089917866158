import React from "react";
import PropTypes from "prop-types";

import { Modal } from "../../../../components/common/PopupWindowLayout";

export const DeleteExercisePopup = ({ closeModal, onDelete }) => {

	return (
		<Modal
			positiveButtonAction={onDelete}
			negativeButtonAction={closeModal}
			closeOnBackdrop={true}
			positiveButtonTitle="Delete Exercise"
			negativeButtonTitle="Cancel"
			title="Are you sure?"
			popupType="delete"
			popupStyle={{
				color: "#1264A3",
				borderRadius: "2px",
				fontFamily: "InterUI-SemiBold"
			}}
			body={
				<div style={{ color: "#565656", fontFamily: "InterUI-Regular" }}>
					You are about to delete exercise
        		</div>
			}>
		</Modal>
	);
};

DeleteExercisePopup.propTypes = {
	closeModal: PropTypes.func,
	delete: PropTypes.func
};
