import messagesApi from "../../api/messages-api";
import * as actionTypes from '../actionTypes';
import { projectID } from "../../api/routes";

export const MessageIdPath = 'projects/' + projectID +'/databases/(default)/documents/message_history/';

export const getMessagesLoading = () => {
    return {
        type: actionTypes.GET_MESSAGES_LOADING
    };
};

export const getMessagesSuccess = messages => {
    return {
        type: actionTypes.GET_MESSAGES_SUCCESS,
        payload: messages
    };
};

export const getMessagesFail = (err) => {
    return {
        type: actionTypes.GET_MESSAGES_FAIL,
        error: err
    };
};

export const deleteMessageLoading = () => {
    return {
        type: actionTypes.DELETE_MESSAGE_LOADING
    };
};

export const deleteMessageSuccess = () => {
    return {
        type: actionTypes.DELETE_MESSAGE_SUCCESS,
    };
};

export const deleteMessageFail = err => {
    return {
        type: actionTypes.DELETE_MESSAGE_FAIL,
        error: err
    };
};

export const addMessageLoading = () => {
    return {
        type: actionTypes.ADD_MESSAGE_LOADING
    };
};

export const addMessageSuccess = () => {
    return {
        type: actionTypes.ADD_MESSAGE_SUCCESS,
    };
};

export const addMessageFail = (error) => {
    return {
        type: actionTypes.ADD_MESSAGE_FAIL,
        error: error
    };
};

export const getMessages = () => {
    return async (dispatch, getState) => {
        dispatch(getMessagesLoading());

        const { status, data, error } = await messagesApi.fetchMessages(getState().auth.token);
        if (status === 200) {
            dispatch(getMessagesSuccess(data.documents));
        } else {
            dispatch(getMessagesFail(error));
        }
    };
};

export const deleteMessage = (id) => {
    return async (dispatch, getState) => {
        dispatch(deleteMessageLoading());
        const { status, error } = await messagesApi.deleteMessage(getState().auth.token, id);
        if (status === 200) {
            return dispatch(deleteMessageSuccess());
        } else {
            return dispatch(deleteMessageFail(error));
        }
    };
};

export const addMessage = (newMessage) => {
    return async (dispatch, getState) => {
        const message = {
            "fields": {
                "title": {
                    "mapValue": {
                        "fields": {
                            "he": {
                                "stringValue": newMessage.title
                            }
                        }
                    }
                },
                "body": {
                    "mapValue": {
                        "fields": {
                            "he": {
                                "stringValue": newMessage.message
                            }
                        }
                    }
                },
                "sent": {
                    "timestampValue": new Date()
                }
            }
        }
        dispatch(addMessageLoading());
        const { status, data, error } = await messagesApi.addMessage(getState().auth.token, message);
        if (status === 200 && data.name) {
            return dispatch(addMessageSuccess());
        } else {
            return dispatch(addMessageFail(error));
        }
    };
};

