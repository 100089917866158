import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import * as _ from 'lodash';

// import caretLeft from "../../../assets/images/icons/caret-left.svg";
import Routes from "../routes";
import { PageSection } from "../../../components/templates/PageSection";
import NewModalStyle from "../../../components/common/NewModalStyledComponents";
import { addPlan } from "../../../store/actions/plans/plans-actions";
import { updatePlan, PlanIdPath } from "../../../store/actions/plans/plan-actions";
import StyledSelect from "../../../components/ui/Select";
import { FlexColumn, FlexRow } from "../../../components/common/CommonStyledComponent";
import { BackButtonTBlue } from "../../../components/ui/Buttons";

const placesOptions = [
    { value: 'gym', label: 'Gym' },
    { value: 'home', label: 'Home' }
]

const planLevelOptions = [
    { value: 'all_levels', label: 'All levels' },
    { value: 'beginner', label: 'Beginner levels' },
    { value: 'advanced', label: 'Advanced' }
]

const NewPlan = (props) => {
    const dispatch = useDispatch();
    const plan = useSelector(state => state.plan.plan);

    const { addToast } = useToasts();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [place, setPlace] = useState(placesOptions[0]);
    const [level, setLevel] = useState(planLevelOptions[0]);
    const [trainingsPerWeek, setTrainingsPerWeek] = useState(1);
    const [weeks, setWeeds] = useState(1);

    useEffect(() => {
        if (plan) {
            setName(_.get(plan, ["fields", "name", "mapValue", "fields", "he", "stringValue"]));
            setDescription(_.get(plan, ["fields", "description", "mapValue", "fields", "he", "stringValue"]));
            setImageUrl(_.get(plan, ["fields", "image", "stringValue"]));
            setVideoUrl(_.get(plan, ["fields", "video", "stringValue"]));
            setPlace(placesOptions.find(o => o.value === _.get(plan, ["fields", "location", "stringValue"])));
            setLevel(planLevelOptions.find(o => o.value === _.get(plan, ["fields", "level", "stringValue"])));
            setWeeds(_.get(plan, ["fields", "weeks", "integerValue"]));
            setTrainingsPerWeek(_.get(plan, ["fields", "trainingsPerWeek", "integerValue"]))
        }
    }, [plan]);

    const onChangeLevel = e => {
        setLevel(e);
    };

    const onChangePlace = e => {
        setPlace(e);
    };

    const onBack = () => {
        if (props.history.length > 2) {
            props.history.goBack();
        }
        else {
            props.history.replace(Routes.Users.users);
        }
    }

    const onEditPlan = id => {
        if (plan) {
            const newPlan = {
                title: name,
                description: description,
                place: place.value,
                level: level.value,
                image: imageUrl,
                video: videoUrl,
                weeks: weeks,
                trainingsPerWeek: trainingsPerWeek
            }
            dispatch(updatePlan(plan.name.replace(PlanIdPath, ''), plan, newPlan)).then(data => {
                if (!data.error) {
                    addToast("Plan updated successfully", { appearance: "success", autoDismiss: true });
                    onBack();
                } else {
                    addToast("Plan update error", { appearance: "error", autoDismiss: true });
                }
            });
        } else {
            const newPlan = {
                title: name,
                description: description,
                place: place.value,
                level: level.value,
                image: imageUrl,
                video: videoUrl,
                weeks: weeks,
                trainingsPerWeek: trainingsPerWeek
            }
            dispatch(addPlan(newPlan)).then(data => {
                if (!data.error) {
                    addToast("Plan added successfully", { appearance: "success", autoDismiss: true });
                    onBack();
                } else {
                    addToast("Error while adding plan", { appearance: "error", autoDismiss: true });
                }
            });
        }
    };

    return (
        <PageSection>
            <NewModalStyle.Form>
                <NewModalStyle.Title>
                    <BackButtonTBlue onClick={onBack}>
                        Back
                    </BackButtonTBlue>
                    <h3 style={{ paddingLeft: '2rem' }}>
                        {plan ? 'Edit Plan' : 'New Plan'}
                    </h3>
                </NewModalStyle.Title>
                <FlexColumn style={{ padding: '0 10%' }}>
                    <NewModalStyle.SubTitle>Name</NewModalStyle.SubTitle>
                    <NewModalStyle.StyledInputNew
                        value={name}
                        onChange={(e) => setName(e.target.value)}>
                    </NewModalStyle.StyledInputNew>
                    <NewModalStyle.SubTitle>Description</NewModalStyle.SubTitle>
                    <NewModalStyle.StyledTextAreaNew
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}>
                    </NewModalStyle.StyledTextAreaNew>
                    <NewModalStyle.SubTitle>Image URL</NewModalStyle.SubTitle>
                    <FlexRow style={{ flex: 1, alignItems: 'center' }}>
                        <NewModalStyle.StyledInputNew
                            style={{ width: '100%' }}
                            value={imageUrl}
                            onChange={(e) => setImageUrl(e.target.value)}>
                        </NewModalStyle.StyledInputNew>
                        {imageUrl && imageUrl.startsWith('http') ? <a href={imageUrl} target={'_blank'} style={{ padding: '0px 0px 8px 10px' }}>View</a> : null}
                    </FlexRow>
                    <NewModalStyle.SubTitle>Video URL</NewModalStyle.SubTitle>
                    <FlexRow style={{ flex: 1, alignItems: 'center' }}>
                        <NewModalStyle.StyledInputNew
                            style={{ width: '100%' }}
                            value={videoUrl}
                            onChange={(e) => setVideoUrl(e.target.value)}>
                        </NewModalStyle.StyledInputNew>
                        {videoUrl && videoUrl.startsWith('http') ? <a href={videoUrl} target={'_blank'} style={{ padding: '0px 0px 8px 10px' }}>View</a> : null}
                    </FlexRow>
                    <FlexRow >
                        <FlexColumn style={{ width: '50%', paddingRight: '10px' }}>
                            <NewModalStyle.SubTitle>Level</NewModalStyle.SubTitle>
                            <FlexRow style={{ marginBottom: '22px', marginTop: '12px' }}>
                                <StyledSelect
                                    options={planLevelOptions}
                                    defaultValue={level}
                                    value={level}
                                    isSearchable={false}
                                    onChange={onChangeLevel}>
                                </StyledSelect>
                            </FlexRow>
                        </FlexColumn>
                        <FlexColumn style={{ width: '50%', paddingLeft: '10px' }}>
                            <NewModalStyle.SubTitle>Place</NewModalStyle.SubTitle>
                            <FlexRow style={{ marginBottom: '22px', marginTop: '12px' }}>
                                <StyledSelect
                                    isSearchable={false}
                                    defaultValue={place}
                                    options={placesOptions}
                                    value={place}
                                    onChange={onChangePlace}>
                                </StyledSelect>
                            </FlexRow>
                        </FlexColumn>
                    </FlexRow>
                    <FlexRow>
                        <FlexColumn>
                            <NewModalStyle.SubTitle>Trainings per week</NewModalStyle.SubTitle>
                            <NewModalStyle.StyledInputNew
                                type={"number"}
                                min={1}
                                value={trainingsPerWeek}
                                onChange={(e) => setTrainingsPerWeek(e.target.value)}
                                style={{ maxWidth: "187px", marginRight: "30px" }}>
                            </NewModalStyle.StyledInputNew>
                        </FlexColumn>
                        <FlexColumn>
                            <NewModalStyle.SubTitle>Weeks</NewModalStyle.SubTitle>
                            <NewModalStyle.StyledInputNew
                                value={weeks}
                                type={"number"}
                                min={1}
                                onChange={(e) => setWeeds(e.target.value)}>
                            </NewModalStyle.StyledInputNew>
                        </FlexColumn>
                    </FlexRow>
                    <NewModalStyle.LButtoBlack
                        disabled={name && description && imageUrl && level && place ? false : true}
                        onClick={() => onEditPlan(plan ? plan._id : null)}>
                        Save
                    </NewModalStyle.LButtoBlack>
                </FlexColumn>
            </NewModalStyle.Form>
        </PageSection>
    );
};

export default withRouter(NewPlan);
