import styled from "styled-components";
import {NavLink} from "react-router-dom";

import {StyledInput} from "../../../components/common/CommonStyledComponent";
import {LButton} from "../../../components/ui/Buttons";

const active = "active";

const Plans = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  height: 100%;
`;

const StyledInputNew = styled(StyledInput)`
  margin: 20px 20px 15px 10px;
  max-width: 253px;
  padding: 0px 10px;
`;

const PlansList = styled.ul`
  overflow: auto;
  max-height: calc(100% - 85px);
  border: 1px solid silver;
  border-width: 0px 1px 0px 1px;
  padding: 0px;
  margin: 0px;
  flex-grow: 1;
  flex-shrink: 1;
`;

const Search = styled.div`
  border: 1px solid silver;
  border-width: 0px 1px 1px 0px;

`;

const PlansListItem = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-30p);
  box-sizing: border-box;

  &.selected {
    background: var(--neutral-20p);
  }

  img {
    overflow: auto;
    width: 9.11px;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: var(--neutral-20p);
  }
`;

styled(PlansListItem).attrs({
  activeClassName: active
})`
  &.${active} {
    background: silver;
  }
`;

const DetailsOfList = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  height: 100%;
`;

const InputAndPlans = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  max-width: 415px;
`;

const DetailsOnePlan = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 0 20px;
  flex: 1;
`;

const PlanTitle = styled.div`
  font-size: 36px;
  padding: 20px 0;
  font-family: InterUI-SemiBold;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const WorkoutBox = styled.div`
  margin-top: 30px;
`;

const Details = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

const PlaceLevelPlanCaret = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  height: 100%;
  font-family: InterUI-Regular;
  padding: 18px 24.8px 16px 25.8px;
`;

const LButtonNew = styled(LButton)`
  font-family: InterUI-Medium;
  padding: 10px 24px;
  height: 34px;
  background: var(--neutral-90p);
  border-radius: 2px;
  font-size: 14px;
  margin-right: 24px;
  &:hover {
    background: var(--neutral-90p);
  }
`;

const WorkOutTitle = styled.div`
  font-size: 16px;
  font-family: InterUI-SemiBold;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const activeClassName = "active-nav";

const NavStyledNavLink = styled(NavLink).attrs({
  activeClassName: activeClassName
})`
  &.${activeClassName} {
    border-bottom: 5px solid var(--primary-blue);
  }
`;

export default {
  Plans,
  PlansList,
  PlansListItem,
  LButtonNew,
  WorkoutBox,
  DetailsOfList,
  InputAndPlans,
  Search,
  StyledInputNew,
  DetailsOnePlan,
  PlaceLevelPlanCaret,
  Details,
  PlanTitle,
  WorkOutTitle,
  NavStyledNavLink
};
