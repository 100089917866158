export const UsersRoutes = {
  users: "/app/users",
  updateUser: "/app/users/editUser"
};

export const CommonRoutes = {
  app: '/app',
  welcome: "/welcome",
  logout: "/logout",
  login: "/login"
};
export const FeedsRoutes = {
  feeds: "/app/feeds"
};

export const MessagesRoutes = {
  messages: "/app/messages"
};

export const NutritionsRoutes = {
  nutritions: "/app/nutritions",
  newMeal: "/app/nutritions/newMeal",
  updateMeal: "/app/nutritions/editMeal"
};

export const PlansRoutes = {
  plans: "/app/plans",
  newPlan: "/app/plans/newPlan",
  editPlan: "/app/plans/edit",
  newWorkout: "/app/plans/newWorkout",
  newExercise: "/app/plans/newExercise",
  editWorkout: "/app/plans/editWorkout",
  editExercise: "/app/plans/editExercise"
};

export const ErrorRoutes = {
  error403: "/403",
  error404: "/404"
};

const Routes = {
  Users: UsersRoutes,
  Common: CommonRoutes,
  Errors: ErrorRoutes,
  Feeds: FeedsRoutes,
  Messages: MessagesRoutes,
  Nutritions: NutritionsRoutes,
  Plans: PlansRoutes
};

export default Routes;
