import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../utilities";

const initialState = {
    exercises: [],
    error: null,
    loading: false
};

const exercisesLoading = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null
    });
};

const getExercisesSuccess = (state, action) => {
    const { payload } = action;
    return updateObject(state, {
        exercises: payload,
        error: null,
        loading: false
    });
};

const getExercisesFail = (state, action) => {
    return updateObject(state, {
        exercises: [],
        error: action.error,
        loading: false
    });
};

const cleanExercises = (state, action) => {
    return updateObject(state, {
        exercises: [],
        error: null,
        loading: false
    });
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EXERCISES_LOADING: return exercisesLoading(state, action);
        case actionTypes.GET_EXERCISES_SUCCESS: return getExercisesSuccess(state, action);
        case actionTypes.GET_EXERCISES_FAIL: return getExercisesFail(state, action);
        case actionTypes.CLEAN_EXERCIES: return cleanExercises(state, action);
        default:
            return state;
    }
};

export default reducer;
