import plansApi from "../../api/plans-api";
import * as actionTypes from '../actionTypes';


export const getPlansLoading = () => {
    return {
        type: actionTypes.GET_PLANS_LOADING
    };
};

export const getPlansSuccess = (plans) => {
    return {
        type: actionTypes.GET_PLANS_SUCCESS,
        payload: plans
    };
};

export const getPlansFail = (err) => {
    return {
        type: actionTypes.GET_PLANS_FAIL,
        error: err
    };
};

export const getPlans = () => {
    return async (dispatch, getState) => {
        dispatch(getPlansLoading());
        const { status, data, error } = await plansApi.fetchPlans(getState().auth.token);
        if (status === 200) {
            dispatch(getPlansSuccess(data));
        } else {
            dispatch(getPlansFail(error));
        }
    };
};

export const addPlanLoading = () => {
    return {
        type: actionTypes.ADD_PLAN_LOADING
    };
};

export const addPlanSuccess = () => {
    return {
        type: actionTypes.ADD_PLAN_SUCCESS,
    };
};

export const addPlanFail = err => {
    return {
        type: actionTypes.ADD_PLAN_FAIL,
        error: err
    };
};

export const deletePlanLoading = () => {
    return {
        type: actionTypes.DELETE_PLAN_LOADING
    };
};

export const deletePlanSuccess = () => {
    return {
        type: actionTypes.DELETE_PLAN_SUCCESS,
    };
};

export const deletePlanFail = err => {
    return {
        type: actionTypes.DELETE_PLAN_FAIL,
        error: err
    };
};

export const addPlan = (newPlan) => {
    return async (dispatch, getState) => {
        const plan = {
            "fields": {
                "image": {
                    "stringValue": newPlan.image
                },
                "video": {
                    "stringValue": newPlan.video
                },
                "level": {
                    "stringValue": newPlan.level ? newPlan.level : 'all_levels'
                },
                "name": {
                    "mapValue": {
                        "fields": {
                            "he": {
                                "stringValue": newPlan.title
                            },
                            "en": {
                                "stringValue": newPlan.title
                            }
                        }
                    }
                },
                "location": {
                    "stringValue": newPlan.place
                },
                "weeks": {
                    "integerValue": newPlan.weeks
                },
                "trainingsPerWeek": {
                    "integerValue": newPlan.trainingsPerWeek
                },
                "description": {
                    "mapValue": {
                        "fields": {
                            "he": {
                                "stringValue": newPlan.description
                            }
                        }
                    }
                }
            }
        }
        dispatch(addPlanLoading());
        const { status, data, error } = await plansApi.addPlan(getState().auth.token, plan);
        if (status === 200 && data.name) {
            return dispatch(addPlanSuccess());
        } else {
            return dispatch(addPlanFail(error));
        }
    };
};

export const deletePlan = id => {
    return async (dispatch, getState) => {
        dispatch(deletePlanLoading());
        const { status, error } = await plansApi.deletePlan(getState().auth.token, id);
        if (status === 200) {
            return dispatch(deletePlanSuccess());
        } else {
            return dispatch(deletePlanFail(error));
        }
    };
};
