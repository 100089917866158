import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../utilities";

const initialState = {
    meal: null,
    error: null,
    loading: false
};


const deleteMealLoading = (state, action) => {
  return updateObject(state, {
    error: null
  });
};

const deleteMealSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
};

const deleteMealFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const mealLoading = (state, action) => {
    return updateObject(state, {
      loading: true,
      error: null
    });
  };


const getMealSuccess = (state, action) => {
    const { payload } = action;
    return updateObject(state, {
        meal: payload,
        error: null,
        loading: false
    });
};

const getMealFail = (state, action) => {
    return updateObject(state, {
      meal: null,
      error: action.error,
      loading: false
    });
  };

  const cleanMeal = (state, action) => {
    return updateObject(state, initialState);
  }


  const updateMealLoading = (state, action) => {
    return updateObject(state, {
      loading: true,
      error: null
    });
  };
  
  const updateMealSuccess = (state, action) => {
    const {payload} = action;
    return updateObject(state, {
      meal: payload,
      error: null,
      loading: false
    });
  };
  
  const updateMealFail = (state, action) => {
    return updateObject(state, {
      meal: null,
      error: action.error,
      loading: false
    });
  };


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MEAL_LOADING: return mealLoading(state, action);
        case actionTypes.GET_MEAL_SUCCESS: return getMealSuccess(state, action);
        case actionTypes.GET_MEAL_FAIL: return getMealFail(state, action);
        case actionTypes.CLEAN_MEAL: return cleanMeal(state, action);
        case actionTypes.UPDATE_MEAL_LOADING: return updateMealLoading(state, action);
        case actionTypes.UPDATE_MEAL_SUCCESS: return updateMealSuccess(state, action);
        case actionTypes.UPDATE_MEAL_FAIL: return updateMealFail(state, action);
        case actionTypes.DELETE_MEAL_LOADING: return deleteMealLoading(state, action);
        case actionTypes.DELETE_MEAL_SUCCESS: return deleteMealSuccess(state, action);
        case actionTypes.DELETE_MEAL_FAIL: return deleteMealFail(state, action);

        default:
            return state;
    }
};

export default reducer;