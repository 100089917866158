import axiosInstance from "../../axios";
import ServerRoutes from "./routes/index";
import { errorSchema } from "./schemas/common-schemas";


const fetchExercises = async (token, workoutId) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const query = {
            "structuredQuery": {
                "from": [
                    {
                        "collectionId": "exercises",
                        "allDescendants": false
                    }
                ],
                "where": {
                    "fieldFilter": {
                        "field": {
                            "fieldPath": "wid"
                        },
                        "op": "EQUAL",
                        "value": {
                            "stringValue": workoutId
                        }
                    }
                },
                "orderBy": [
                    {
                        "field": {
                            "fieldPath": "index"
                        },
                        "direction": "ASCENDING"
                    }	
                ]
            }
        }
        const { status, data, error } = await axiosInstance.post(ServerRoutes.globalQuery, query, {headers: headers});
        const result = data.map(d=> d.document).filter(d => d !== undefined);;
        return { data: result, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "exercises not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const fetchExercise = async (token, id) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const {status, data, error} = await axiosInstance.get(ServerRoutes.getExercises + '/' + id, {headers: headers});
        console.log(data)

        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "exercise not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const deleteExercise = async (token, id) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const {status, data, error} = await axiosInstance.delete(ServerRoutes.getExercises + '/' + id, {headers: headers});
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "exercise not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const updateExercise = async (token, id, updateExercise) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const {status, data, error} = await axiosInstance.patch(ServerRoutes.getExercises + '/' + id, updateExercise, {headers: headers});
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "exercise not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const addExercise = async (token, newExercise) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const {status, data, error} = await axiosInstance.post(ServerRoutes.getExercises, newExercise, {headers: headers});
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "exercises not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const exercisesApi = {
    fetchExercises: fetchExercises,
    fetchExercise: fetchExercise,
    deleteExercise: deleteExercise,
    updateExercise: updateExercise,
    addExercise: addExercise
};

export default exercisesApi;
