import React from "react";
import { Menu, Item } from "react-contexify";
import PropTypes from "prop-types";
import styled from 'styled-components';
import * as _ from 'lodash';

import "react-contexify/dist/ReactContexify.min.css";
import "../../../styles/context-menu-css-override.css";

import { TableStyles } from "../CommonTableComponents";
import { UserIdPath } from "../../../store/actions/users/user-actions";

const InActive = styled.div`
  color: red;
  align-self: center;
  justify-content: center;
  display: flex;
  width: 100%;
  `;

const Active = styled.div`
  color: green;
  align-self: center;
  justify-content: center;
  display: flex;
  width: 100%;
  `;

const UserGoals = {
    'lose_fat': 'Lose Fat',
    'get_fitter': 'Get Fitter',
    'gain_muscle': 'Gain Muscle'
}

const getIdColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "id",
            Header: "ID",
            accessor: 'name',
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                fontFamily: "InterUI-Regular",
                fontSize: '13px'
            },
            Cell: props => props.value.replace(UserIdPath, ''),
            maxWidth: 130,
            resizable: false,
            sortable: false
        }
    ];
};

const getEmailColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "email",
            Header: "Email",
            accessor: (u)=> _.get(u, ['fields', 'email', 'stringValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                fontFamily: "InterUI-Medium"
            },
            Cell: props => props.value,
            resizable: true,
            sortable: false
        }
    ];
};

const getNameColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "name",
            Header: "Full Name",
            accessor: (u)=> {
                return {
                    firstName: _.get(u, ['fields', 'firstName', 'stringValue']),
                    lastName: _.get(u, ['fields', 'lastName', 'stringValue']),
                }
            },
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656"
            },
            Cell: props => props.value.firstName + " " + props.value.lastName,
            resizable: false,
            sortable: false
        }
    ];
};

const getDateOfBirthColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "dateOfBirth",
            Header: "Date of birth",
            accessor: (u)=> new Date(_.get(u, ['fields', 'birthdate', 'timestampValue'])),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656"
            },
            Cell: props => {
                let date = props.value.getDate() + "/" + parseInt(props.value.getMonth() + 1) + "/" + props.value.getFullYear();
                return date;
            },
            sortable: false,
            maxWidth: 150,
            resizable: false
        }
    ];
};

const getHeightColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "height",
            Header: "Height",
            accessor: (u)=> _.get(u, ['fields', 'height', 'mapValue', 'fields', 'value', 'integerValue']) + ' ' + 
                            _.get(u, ['fields', 'height', 'mapValue', 'fields', 'unit', 'stringValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656"
            },
            Cell: props => props.value ? props.value : '',
            maxWidth: 70,
            sortable: false,
            resizable: false
        }
    ];
};

const getWeightColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "weight",
            Header: "Weight",
            accessor: (u)=> _.get(u, ['fields', 'weight', 'mapValue', 'fields', 'value', 'integerValue']) + ' ' + 
                            _.get(u, ['fields', 'weight', 'mapValue', 'fields', 'unit', 'stringValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656"
            },
            Cell: props => props.value,
            maxWidth: 70,
            sortable: false,
            resizable: false
        }
    ];
};

const getTargetColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "target",
            Header: "Target",
            accessor: (u)=> UserGoals[_.get(u, ['fields', 'goal', 'stringValue'])],
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656"
            },
            Cell: props => props.value,
            maxWidth: 130,
            resizable: false,
            sortable: false
        }
    ];
};


const getSubscriptionstatusColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "subscriptionstatus",
            Header: "Subscription",
            accessor: (u)=> _.get(u, ['fields', 'subscribed', 'booleanValue']),
            Cell: props => props.value === false ? <InActive > inActive</InActive> : <Active>Active</Active>,
            maxWidth: 200,
            sortable: false,
            resizable: false
        }
    ];
};

const getActionColumn = ActionsComponent => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Actions",
            Header: "Actions",
            accessor: "actions",
            Cell: props => <>{<ActionsComponent {...props} />}</>,
            maxWidth: 80,
            sortable: false,
            resizable: false
        }
    ];
};

export const ColumnDefinitions = {
    IdColumn: getIdColumn,
    EmailColumn: getEmailColumn,
    NameColumn: getNameColumn,
    DateOfBirthColumn: getDateOfBirthColumn,
    HeightColumn: getHeightColumn,
    WeightColumn: getWeightColumn,
    TargetColumn: getTargetColumn,
    SubscriptionstatusColumn: getSubscriptionstatusColumn,
    ActionColumn: getActionColumn
};

export const ActionsContextMenu = props => {
    return (
        <Menu id="action_menu" className={"context-menu"}>
            <Item
                style={{ fontFamily: "InterUI-Regular" }}
                className={"context-menu-item"}
                onClick={props.onEdit}>
                Edit
            </Item>
        </Menu>
    );
};

ActionsContextMenu.propTypes = {
    onRemove: PropTypes.func,
    onEdit: PropTypes.func
};
