import React from "react";
import PropTypes from "prop-types";

import {Modal} from "../../../components/common/PopupWindowLayout";

export const DeleteModal = ({closeModal, onDelete}) => {
  
  return (
    <Modal
      positiveButtonAction={onDelete}
      negativeButtonAction={closeModal}
      closeOnBackdrop={true}
      positiveButtonTitle="Delete Plan"
      negativeButtonTitle="Cancel"
      title="Are you sure?"
      popupType="delete"
      popupStyle={{
        color: "#1264A3",
        borderRadius: "2px",
        fontFamily: "InterUI-SemiBold"
      }}
      body={
        <div style={{color: "#565656", fontFamily: "InterUI-Regular"}}>
          You are about to delete plan
        </div>
      }>
    </Modal>
  );
};

DeleteModal.propTypes = {
  closeModal: PropTypes.func,
  delete: PropTypes.func
};
