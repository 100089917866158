import React from "react";
import PropTypes from "prop-types";
import {Menu, Item} from "react-contexify";
import moment from 'moment';

import "react-contexify/dist/ReactContexify.min.css";
import "../../../styles/context-menu-css-override.css";

import {TableStyles} from "../CommonTableComponents";

const getDateColumn = () => {
  return [
    {
      ...TableStyles.generalColumnStyle,
      id: "date",
      Header: "Posted",
      accessor: "posted",
      style: {whiteSpace: "unset", wordBreak: "break-word", color: "#565656", display: "flex", alignItems: "center"},
      Cell: props => moment(props.value).format('HH:mm DD-MM-YYYY'),
      maxWidth: 160,
      resizable: false
    }
  ];
};

const getMessageColumn = () => {
  return [
    {
      ...TableStyles.generalColumnStyle,
      id: "message",
      Header: "Body",
      accessor: (f)=> f.body.he,
      style: {whiteSpace: "unset", wordBreak: "break-word", color: "#565656", display: "flex", alignItems: "center"},
      Cell: props => props.value,
      resizable: false
    }
  ];
};

const getVideoColumn = () => {
  return [
    {
      ...TableStyles.generalColumnStyle,
      id: "video",
      Header: "Video",
      accessor: "video",
      style: {display: "flex", alignItems: "center"},
      Cell: props => 
        <a href={props.value} style={{color: "#1264A3"}} target={"_blank"}>
          Video
        </a>,
      maxWidth: 100,
      resizable: false
    }
  ];
};

const getActionColumn = ActionsComponent => {
  return [
    {
      ...TableStyles.generalColumnStyle,
      id: "Actions",
      Header: "Actions",
      accessor: "actions",
      Cell: props => <>{<ActionsComponent {...props} />}</>,
      maxWidth: 80,
      sortable: false,
      resizable: false
    }
  ];
};

export const ColumnDefinitions = {
  DateColumn: getDateColumn,
  MessageColumn: getMessageColumn,
  VideoColumn: getVideoColumn,
  ActionColumn: getActionColumn
};

export const ActionsContextMenu = props => {
  return (
    <Menu id="action_menu" className={"context-menu"}>
      <Item
        style={{fontFamily: "InterUI-Regular"}}
        className={"context-menu-item"}
        onClick={props.onEdit}>
        Edit
      </Item>
      <Item
        style={{fontFamily: "InterUI-Regular"}}
        className={"context-menu-item"}
        onClick={props.onRemove}>
        Delete
      </Item>
    </Menu>
  );
};

ActionsContextMenu.propTypes = {
  onRemove: PropTypes.func,
  onEdit: PropTypes.func
};
