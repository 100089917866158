import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../utilities";

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
};

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        token: null,
        userId: null,
        loading: true
    });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.access_token,
        userId: action.user_id,
        error: null,
        loading: false
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        token: null,
        userId: null
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userId: null });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAILED: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);

        default:
            return state;
    }
};

export default reducer;
