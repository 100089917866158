import React, {useEffect} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import OutsideClick from "../../hoc/OutsideClick";
import {XMarkButton, MButton, MButtonTBlue} from "../ui/Buttons";
import {Separator, Spinner} from "./CommonStyledComponent";

const Backdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1000;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : "#8686868f"};
  overflow: auto;
  html {
    overflow: none;
  }
`;

const PopupContainer = styled.div`
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--neutral-90p);
  padding: 0.5rem;
  width: 100%;
  max-width: 860px;
  -webkit-box-shadow: 0px 0px 11px 1px rgb(111, 111, 111);
  -moz-box-shadow: 0px 0px 11px 1px rgb(111, 111, 111);
  box-shadow: 0px 0px 11px 1px rgb(111, 111, 111);
`;
const PageTitle = styled.div`
  font-family: InterUI-SemiBold;
  font-size: 20px;
  color: var(--neutral-80p);
  line-height: 42px;
`;
const CloseButton = styled.div`
  align-self: flex-end;
`;

const PopupSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 20px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 630px;
`;
const Footer = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin: 0 0.5rem;
  }
`;

export const Modal = props => {
  // Disable scroll on modal present
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <Backdrop style={{...props.backdropStyle}}>
      <OutsideClick
        callback={props.closeOnBackdrop ? props.negativeButtonAction : () => {}}
      >
        <PopupContainer style={{...props.popupStyle}}>
          <CloseButton>
            <XMarkButton
              onClick={props.negativeButtonAction}
              fontSize={"25px"}
              disabled={props.loading}
            />
          </CloseButton>
          <PopupSection>
            <PageTitle>{props.title}</PageTitle>
            <PopupWindowLayout.RowContainer>
              {props.body}
            </PopupWindowLayout.RowContainer>
            <Separator />
            <Footer
              style={
                props.popupType === "submit"
                  ? {direction: "rtl"}
                  : {direction: "ltr"}
              }
            >
              <RowContainer>
                <ButtonsContainer>
                  {props.loading ? <Spinner size="2x" /> : null}
                  <MButtonTBlue
                    style={{background: "white"}}
                    disabled={props.loading}
                    onClick={props.negativeButtonAction}
                  >
                    {props.negativeButtonTitle}
                  </MButtonTBlue>
                  <MButton
                    style={{background: "black"}}
                    disabled={props.loading || props.positiveButtonDisabled}
                    onClick={props.positiveButtonAction}
                  >
                    {props.positiveButtonTitle}
                  </MButton>
                </ButtonsContainer>
              </RowContainer>
            </Footer>
          </PopupSection>
        </PopupContainer>
      </OutsideClick>
    </Backdrop>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.element.isRequired,
  closeOnBackdrop: PropTypes.bool,
  positiveButtonTitle: PropTypes.string,
  negativeButtonTitle: PropTypes.string,
  popupType: PropTypes.oneOf(["submit", "delete"]),
  loading: PropTypes.bool,
  positiveButtonDisabled: PropTypes.bool,

  // Styles
  backdropStyle: PropTypes.object,
  popupStyle: PropTypes.object,

  // Button action handlers
  negativeButtonAction: PropTypes.func,
  positiveButtonAction: PropTypes.func
};

Modal.defaultProps = {
  loading: false,
  positiveButtonDisabled: false,
  popupType: "submit",
  closeOnBackdrop: true,
  positiveButtonTitle: "Done"
};

export default Modal;

export const PopupWindowLayout = {
  Backdrop,
  PopupContainer,
  PageTitle,
  CloseButton,
  PopupSection,
  RowContainer,
  ColumnContainer,
  Footer
};
