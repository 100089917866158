import usersReducer from "./users/users-reducer";
import userReducer from "./users/user-reducer";
import plansReducer from "./plans/plans-reducer";
import workoutsReducer from "./workouts/workouts-reducer";
import workoutReducer from "./workouts/workout-reducer";
import planReducer from "./plans/plan-reducer";
import exercisesReducer from "./exercises/exercises-reducer";
import exerciseReducer from "./exercises/exercise-reducer";
import feedsReducer from "./feeds/feeds-reducer";
import messagesReducer from "./messages/messages-reducer";
import authReducer from "./auth/auth-user-reducer";
import nutritionsReducer from "./nutritions/nutritions-reducer";
import mealReducer from "./nutritions/meal-reducer"

export const reducers = {
  usersReducer: usersReducer,
  userReducer: userReducer,
  authReducer: authReducer,
  plansReducer: plansReducer,
  workoutsReducer: workoutsReducer,
  workoutReducer: workoutReducer,
  feedsReducer: feedsReducer,
  messagesReducer: messagesReducer,
  planReducer: planReducer,
  exercisesReducer: exercisesReducer,
  exerciseReducer: exerciseReducer,
  nutritionsReducer: nutritionsReducer,
  mealReducer
};
