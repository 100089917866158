import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Spinner, FlexRowCentered } from './CommonStyledComponent';

const FullPageInfinityScroll = ({ children, threshold, loading, hasMore, loadMore }) => {
	const elemRef = useRef();

	useEffect(() => {
		elemRef.current = document.getElementById('root');
		return () => {
			elemRef.current = null;
		}
	}, []);

	useEffect(() => {
		window.onscroll = _.debounce(handleScroll, 300);
		return () => {
			window.onscroll = null;
		}
	}, [loading]);

	const handleScroll = (e) => {
		if (!hasMore || loading) return;
		const scrollHeight = elemRef.current.scrollHeight;
		const offsetHeight = elemRef.current.offsetHeight;
		const scrollY = window.scrollY;
		if (offsetHeight + scrollY >= scrollHeight - threshold) {
			loadMore();
			document.body.scrollTo(0, offsetHeight + scrollY + 100);
		}
	}

	return (
		<>
			{children}
			{loading ?
				<FlexRowCentered style={{ padding: '25px 0' }}>
					<Spinner size="1x" />
					<div style={{ padding: '0 10px', color: 'var(--neutral-60p)' }}>Loading ...</div>
				</FlexRowCentered> : null}
		</>
	)
}

export default FullPageInfinityScroll;

FullPageInfinityScroll.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
	threshold: PropTypes.number,
	loading: PropTypes.bool,
	hasMore: PropTypes.bool,
	loadMore: PropTypes.func
}

FullPageInfinityScroll.defaultProps = {
	threshold: 100,
	hasMore: false,
	loadMore: () => { }
}
