import plansApi from "../../api/plans-api";
import * as actionTypes from '../actionTypes';
import * as _ from 'lodash';
import { projectID } from "../../api/routes";

export const PlanIdPath = 'projects/' + projectID +'/databases/(default)/documents/plans/'

export const getPlanLoading = () => {
    return {
        type: actionTypes.GET_PLAN_LOADING
    };
};

export const getPlanSuccess = plan => {
    return {
        type: actionTypes.GET_PLAN_SUCCESS,
        payload: plan
    };
};

export const getPlanFail = err => {
    return {
        type: actionTypes.GET_PLAN_FAIL,
        error: err
    };
};

export const updatePlanLoading = () => {
    return {
        type: actionTypes.UPDATE_PLAN_LOADING
    };
};

export const updatePlanSuccess = (plan) => {
    return {
        type: actionTypes.UPDATE_PLAN_SUCCESS,
        plan: plan
    };
};

export const updatePlanFail = err => {
    return {
        type: actionTypes.UPDATE_PLAN_FAIL,
        error: err
    };
};

export const getPlanFromServer = id => {
    return async dispatch => {
        dispatch(getPlanLoading());
        const { status, data, error } = await plansApi.fetchPlan(id);
        if (status === 200) {
            const plan = data;
            return dispatch(getPlanSuccess(plan));
        } else {
            return dispatch(getPlanFail(error));
        }
    };
};

export const updatePlan = (id, plan, updatePlan) => {
    return async (dispatch, getState) => {
        _.set(plan, ["fields", "name", "mapValue", "fields", "he", "stringValue"], updatePlan.title);
        _.set(plan, ["fields", "name", "mapValue", "fields", "en", "stringValue"], updatePlan.title);
        _.set(plan, ["fields", "description", "mapValue", "fields", "he", "stringValue"], updatePlan.description);
        _.set(plan, ["fields", "image", "stringValue"], updatePlan.image);
        _.set(plan, ["fields", "video", "stringValue"], updatePlan.video);
        _.set(plan, ["fields", "location", "stringValue"], updatePlan.place);
        _.set(plan, ["fields", "level", "stringValue"], updatePlan.level);
        _.set(plan, ["fields", "weeks", "integerValue"], updatePlan.weeks);
        _.set(plan, ["fields", "trainingsPerWeek", "integerValue"], updatePlan.trainingsPerWeek);
        dispatch(updatePlanLoading());
        const { status, data, error } = await plansApi.updatePlan(getState().auth.token, id, plan);
        if (status === 200) {
            const plan = data;
            return dispatch(updatePlanSuccess(plan));
        } else {
            return dispatch(updatePlanFail(error));
        }
    };
};


export const selectPlan = (plan) => {
    return {
        type: actionTypes.SELECT_PLAN,
        plan: plan
    }
}

export const deselectPlan = () => {
    return {
        type: actionTypes.DESELECT_PLAN,
    }
}