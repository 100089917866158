import React from "react";
import { Menu, Item } from "react-contexify";
import PropTypes from "prop-types";
import * as _ from 'lodash';

import "react-contexify/dist/ReactContexify.min.css";
import "../../../styles/context-menu-css-override.css";

import { TableStyles } from "../CommonTableComponents";

const getNameColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "name",
            Header: "Name",
            accessor: (u) => _.get(u, ['fields', 'name', 'mapValue', 'fields', 'he', 'stringValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656",
                fontFamily: "InterUI-Medium"
            },
            Cell: props =>props.value,
            maxWidth: 200,
            resizable: false
        }
    ];
};

const getRepeatsColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Repeats",
            Header: "Reps/Time",
            accessor: (u) => _.get(u, ['fields', 'reps', 'integerValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656",
                fontFamily: "InterUI-Medium"
            },
            Cell: props => props.value,
            maxWidth: 100,
            resizable: false
        }
    ];
};

const getTypeColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Type",
            Header: "Type",
            accessor: (u) => _.get(u, ['fields', 'type', 'stringValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656",
                fontFamily: "InterUI-Medium"
            },
            Cell: props => props.value,
            maxWidth: 80,
            resizable: false
        }
    ];
};

const getSetsColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Sets",
            Header: "Sets",
            accessor: (u) => _.get(u, ['fields', 'sets', 'integerValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656",
                fontFamily: "InterUI-Medium"
            },
            Cell: props => props.value,
            maxWidth: 70,
            resizable: false
        }
    ];
};

const getDescriptionColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Description",
            Header: "Description",
            accessor: (u) => _.get(u, ['fields', 'description', 'mapValue', 'fields', 'he', 'stringValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656",
                fontFamily: "InterUI-Medium"
            },
            Cell: props => props.value,
            resizable: false
        }
    ];
};

const getVideoURLColumn = () => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "VideoURL",
            Header: "VideoURL",
            accessor: (u) => _.get(u, ['fields', 'video', 'stringValue']),
            style: {
                whiteSpace: "unset",
                wordBreak: "break-word",
                alignSelf: "center",
                color: "#565656",
                fontFamily: "InterUI-Medium"
            },
            Cell: props => (
                <a href={props.value} style={{ color: "#1264A3" }} target={"_blank"}>
                    Video
            </a>),
            maxWidth: 150,
            resizable: false
        }
    ];
};

const getActionColumn = ActionsComponent => {
    return [
        {
            ...TableStyles.generalColumnStyle,
            id: "Actions",
            Header: "Actions",
            accessor: "actions",
            Cell: props => <>{<ActionsComponent {...props} />}</>,
            maxWidth: 80,
            sortable: false,
            resizable: false
        }
    ];
};

export const ColumnDefinitions = {
    NameColumn: getNameColumn,
    RepeatsColumn: getRepeatsColumn,
    TypeColumn: getTypeColumn,
    SetsColumn: getSetsColumn,
    DescriptionColumn: getDescriptionColumn,
    VideoURLColumn: getVideoURLColumn,
    ActionColumn: getActionColumn
};

export const ActionsContextMenu = props => {
    return (
        <Menu id="action_menu" className={"context-menu"}>
            <Item
                className={"context-menu-item"}
                style={{ fontFamily: "InterUI-Regular" }}
                onClick={props.onMoveUp}>
                Move up
      </Item>
            <Item
                className={"context-menu-item"}
                style={{ fontFamily: "InterUI-Regular" }}
                onClick={props.onMoveDown}>
                Move down
      </Item>
            <Item
                className={"context-menu-item"}
                style={{ fontFamily: "InterUI-Regular" }}
                onClick={props.onEdit}>
                Edit
      </Item>
            <Item
                className={"context-menu-item"}
                style={{ fontFamily: "InterUI-Regular" }}
                onClick={props.onRemove}>
                Delete
      </Item>
        </Menu>
    );
};

ActionsContextMenu.propTypes = {
    onEdit: PropTypes.func,
    onRemove: PropTypes.func
};
