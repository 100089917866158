import React from 'react';
import Modal from '../../../components/common/PopupWindowLayout';

const MealDeletePopup = ({ onDelete, closeModal }) => {
	return (
		<Modal
			positiveButtonAction={onDelete}
			negativeButtonAction={closeModal}
			closeOnBackdrop={true}
			positiveButtonTitle="Delete Meal"
			negativeButtonTitle="Cancel"
			title="Are you sure?"
			popupType="delete"
			popupStyle={{
				color: "#1264A3",
				borderRadius: "2px",
				fontFamily: "InterUI-SemiBold"
			}}
			body={
				<div style={{ color: "#565656", fontFamily: "InterUI-Regular" }}>
					You are about to delete meal?
			</div>
			}>
		</Modal>
	);
}

export default MealDeletePopup;