import React, {useMemo} from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../../../styles/table-css-override.css";
import PropTypes from "prop-types";

import {ColumnDefinitions, ActionsContextMenu} from "./WorkoutsTableComponents";
import {
  TablePagination,
  TableStyles,
  ActionCell
} from "../CommonTableComponents";
// import LoadingComponent from "../../ui/LoadingComponent";

const WorkoutsTable = props => {
  // Table setup
  const tableDefaults = {
    ...props,
    defaultPageSize: props.defaultPageSize,
    minRows: props.minRows
      ? Math.min(props.data && props.data.length, props.minRows)
      : Math.min(props.data && props.data.length, 9),
    PaginationComponent: TablePagination
  };
  const nameColumn = useMemo(() => ColumnDefinitions.NameColumn(), [ColumnDefinitions.NameColumn]);

  const weekColumn = useMemo(() => ColumnDefinitions.WeekColumn(), [ColumnDefinitions.WeekColumn]);

  const dayColumn = useMemo(() => ColumnDefinitions.DayColumn(), [ColumnDefinitions.DayColumn]);

  // const imageColumn = useMemo(() => ColumnDefinitions.ImageColumn(), [ColumnDefinitions.ImageColumn]);

  const actionColumn = useMemo(() =>props.showActions? ColumnDefinitions.ActionColumn(props.ActionCellComponent ? props.ActionCellComponent : ActionCell) : [],
    [ColumnDefinitions.ActionColumn,props.showActions, props.ActionCellComponent]);

  const columns = [
    ...nameColumn,
    ...weekColumn,
    ...dayColumn,
    // ...imageColumn,
    ...actionColumn
  ];

  return (
    <>
      <ReactTable
        {...tableDefaults}
        style={TableStyles.tableStyle}
        data={props.data}
        columns={columns}
      />
      <ActionsContextMenu onEdit={props.onEdit} onRemove={props.onRemove} />
    </>
  );
};

WorkoutsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      workoutName: PropTypes.string,
      week: PropTypes.number,
      day: PropTypes.number,
      imageUrl: PropTypes.string
    })
  ),
  defaultPageSize: PropTypes.number,
  minRows: PropTypes.number,
  ActionCellComponent: PropTypes.element,
  showActions: PropTypes.bool,
  // Used only with default action component
  contextMenuEventHandlers: PropTypes.shape({
    onEdit: PropTypes.func,
    onRemove: PropTypes.func
  })
};

WorkoutsTable.defaultProps = {
  showActions: true
};

export default WorkoutsTable;
