import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../utilities";

const initialState = {
  user: null,
  error: null,
  loading: false
};

const userLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const getUserSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    user: payload,
    error: null,
    loading: false
  });
};

const getUserFail = (state, action) => {
  return updateObject(state, {
    user: null,
    error: action.error,
    loading: false
  });
};

const updateUserLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const updateUserSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    user: payload,
    error: null,
    loading: false
  });
};

const updateUserFail = (state, action) => {
  return updateObject(state, {
    user: null,
    error: action.error,
    loading: false
  });
};

const cleanUser = (state, action) => {
  return updateObject(state, initialState);
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_LOADING:return userLoading(state, action);
    case actionTypes.GET_USER_SUCCESS:return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAIL:return getUserFail(state, action);
    case actionTypes.CLEAN_USER: return cleanUser(state, action);
    case actionTypes.UPDATE_USER_LOADING:return updateUserLoading(state, action);
    case actionTypes.UPDATE_USER_SUCCESS:return updateUserSuccess(state, action);
    case actionTypes.UPDATE_USER_FAIL:return updateUserFail(state, action);

    default:
      return state;
  }
};

export default reducer;
