import React from 'react'
import styled from 'styled-components';
import Navigation from '../containers/Navigation/Navigation';

export const ContainerFluid = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
`;

export const Container = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
	@media (min-width: 768px) {
    	max-width: 720px;
	}
	@media (min-width: 576px) {
    	max-width: 540px;
	}	
`;

const Layout = ({children}) => {
	return (
		<>
			<Navigation></Navigation>
			{children}
		</>
	)
}

export default Layout; 
