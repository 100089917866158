import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../utilities";

const initialState = {
  users: [],
  nextPageToken: null,
  error: null,
  loading: false
};

const usersLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const getUsersSuccess = (state, action) => {
  let {payload} = action;
  state.users.forEach(e => {
    const index = payload.findIndex(u => {
      return u.name === e.name;
    });
    if (index >= 0) {
      payload.splice(index, 1);
    }
  });
  return updateObject(state, {
    users: [...state.users, ...payload],
    nextPageToken: action.nextPageToken,
    error: null,
    loading: false
  });
};

const getUsersFail = (state, action) => {
  return updateObject(state, {
    users: [],
    error: action.error,
    loading: false
  });
};

const deleteUserLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const deleteUserSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    users: payload,
    error: null,
    loading: false
  });
};

const deleteUserFail = (state, action) => {
  return updateObject(state, {
    users: [],
    error: action.error,
    loading: false
  });
};

const cleanUsers = (state, action) => {
  return updateObject(state, initialState);
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_LOADING:return usersLoading(state, action);
    case actionTypes.GET_USERS_SUCCESS:return getUsersSuccess(state, action);
    case actionTypes.GET_USERS_FAIL:return getUsersFail(state, action);
    case actionTypes.CLEAN_USERS: return cleanUsers(state, action);
    case actionTypes.DELETE_USER_LOADING:return deleteUserLoading(state, action);
    case actionTypes.DELETE_USER_SUCCESS:return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAIL:return deleteUserFail(state, action);

    default:
      return state;
  }
};

export default reducer;
