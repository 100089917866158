import axiosInstance from "../../axios";
import ServerRoutes from "./routes/index";
import { errorSchema } from "./schemas/common-schemas";

const fetchWorkout = async (token, id) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }

        const { status, data, error } = await axiosInstance.get(ServerRoutes.getWorkouts + '/' + id + '?pageSize=100', { headers: headers });
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const deleteWorkout = async (token, id) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const { status, data, error } = await axiosInstance.delete(ServerRoutes.getWorkouts + '/' + id, { headers: headers });
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const updateWorkout = async (token, id, workoutUpdate) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const { status, data, error } = await axiosInstance.patch(ServerRoutes.getWorkouts + '/' + id, workoutUpdate, { headers: headers });
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const addWorkout = async (token, newWorkout) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const { status, data, error } = await axiosInstance.post(ServerRoutes.getWorkouts, newWorkout, { headers: headers });
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const fetchWorkouts = async (token, planId) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const query = {
            "structuredQuery": {
                "from": [
                    {
                        "collectionId": "workouts",
                        "allDescendants": false
                    }
                ],
                "where": {
                    "fieldFilter": {
                        "field": {
                            "fieldPath": "pid"
                        },
                        "op": "EQUAL",
                        "value": {
                            "stringValue": planId
                        }
                    }
                }
            }
        };
        const { status, data, error } = await axiosInstance.post(ServerRoutes.globalQuery, query, { headers: headers });
        const result = data.map(d => d.document).filter(d => d !== undefined);

        return { data: result, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const WorkoutsApi = {
    fetchWorkouts: fetchWorkouts,
    fetchWorkout: fetchWorkout,
    deleteWorkout: deleteWorkout,
    updateWorkout: updateWorkout,
    addWorkout: addWorkout
};

export default WorkoutsApi;
