import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PageSection } from "../../../components/templates/PageSection";
import UsersTable from "../../../components/tables/Users/UsersTable";
import UsersStyle from "./UsersStyledComponents";
import { getUsersFromServer, cleanUsers } from "../../../store/actions/users/users-actions";
import Routes from "../routes";
import { UserIdPath } from "../../../store/actions/users/user-actions";
import FullPageInfinityScroll from "../../../components/common/FullPageInfinityScroll";

const Users = ({ history }) => {
    const dispatch = useDispatch();
    const users = useSelector(state => state.users);
    useEffect(() => {
        dispatch(getUsersFromServer(users.nextPageToken));
        return ()=> {
            if (history.location.pathname.indexOf(Routes.Users.users) < 0) {
				dispatch(cleanUsers());
			}
        }
    }, []);
    
    const onEdit = props => {
        const id = props.props.name.replace(UserIdPath, '');
        history.push(Routes.Users.updateUser + '/' + id);
    };

    return (
        <PageSection>
            {/* <UsersStyle.StyledInputNew placeholder="Search by name"></UsersStyle.StyledInputNew> */}
            <FullPageInfinityScroll 
            loading={users.loading} 
            hasMore={users.nextPageToken !== undefined && users.nextPageToken !== null} 
            loadMore={()=>dispatch(getUsersFromServer(users.nextPageToken))}>
                <UsersStyle.Table>
                    <UsersTable
                        loading={users.loading}
                        data={users.users}
                        onEdit={onEdit}>
                    </UsersTable>
                </UsersStyle.Table>
            </FullPageInfinityScroll>
        </PageSection>
    );
};

export default Users;
