import workoutsApi from "../../api/workouts-api";
import * as actionTypes from '../actionTypes';

const getWorkoutsLoading = () => {
    return {
        type: actionTypes.GET_WORKOUTS_LOADING
    };
};

const getWorkoutsSuccess = (plans) => {
    return {
        type: actionTypes.GET_WORKOUTS_SUCCESS,
        payload: plans
    };
};

const getWorkoutsFail = (err) => {
    return {
        type: actionTypes.GET_WORKOUTS_FAIL,
        error: err
    };
};

export const cleanWorkouts = () => {
    return {
        type: actionTypes.CLEAN_WORKOUTS
    }
}


export const getWorkouts = (planId) => {
    return async (dispatch, getState) => {
        dispatch(getWorkoutsLoading());
        const { status, data, error } = await workoutsApi.fetchWorkouts(getState().auth.token, planId);
        if (status === 200) {
            dispatch(getWorkoutsSuccess(data));
        } else {
            dispatch(getWorkoutsFail(error));
        }
    };
};


