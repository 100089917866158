import styled from "styled-components";
export const Main = styled.div`
  margin-top: 64px;
  padding:0;
  height: 100%;
  width: 90%;
  margin-left:5%;
`;


export const ButtonsTypesDiv = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  margin-top:100px;
  margin-bottom:40px;
`;

export const NewMealTitle = styled.div`
font-family: InterUI-Regular;
  font-size:2rem;
  margin-left:3%;


 `;
export const ButtonTypes = styled.div`
  background:none;
  border:none;
  border-bottom: ${(props) => props.selected ? '3px solid var(--primary-blue)' : "none"} ;
  cursor: pointer;
  display:flex;
  align-items:center;
  margin: 0 1%;
  font-family: InterUI-Regular;
  font-size:1rem;
`;

export const ButtonNew = styled.button`
  background: var(--primary-blue);
  display:flex;
  justify-content:center;
  align-items:center;
  padding:10px 20px;
  border:none;
  cursor: pointer;
  font-family: InterUI-Regular;
  text-decoration:none;
  font-size:1rem;
  color:white;
  &:active {
    background: var(--orange-shade-dark);
  }
  &:hover {
    background: var(--primary-blue);
  }
`;



export const NewMealMain = styled.form`
  display:flex;
  flex-direction:column;
  justify-content: flex-start;
  width:100%;
  
`;





export const Span = styled.span`
    margin: 0 auto;
    font-family: InterUI-Regular;
    text-align: center !important;
    color: red;
`;



