import axiosInstance from "../../axios";
import ServerRoutes from "./routes/index";
import { errorSchema } from "./schemas/common-schemas";

const fetchUsers = async (token, nextPageToken) => {
	try {
		const headers = {
			Authorization: 'Bearer ' + token
		}
		let url = ServerRoutes.getUsers;
		if (nextPageToken) {
			url = url + '?pageToken=' + nextPageToken;
		}
		const { status, data, error } = await axiosInstance.get(url, { headers: headers });
		return { data: data, status: status, error: error };
	} catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message =
			"Some error happen, please try again. If the error returns please contact support";
		switch (e.response.status) {
			case 404:
				message = "user not found";
				break;
			case 500:
				message = "Server error. Please contact support";
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
};

const getUser = async (token, id) => {
	try {
		const headers = {
			Authorization: 'Bearer ' + token
		}
		const { status, data, error } = await axiosInstance.get(ServerRoutes.getUsers + '/' + id, { headers: headers });
		return { data: data, status: status, error: error };
	} catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message =
			"Some error happen, please try again. If the error returns please contact support";
		switch (e.response.status) {
			case 404:
				message = "user not found";
				break;
			case 500:
				message = "Server error. Please contact support";
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
};

const updateUser = async (token, id, updateUser) => {
	try {
		const headers = {
			Authorization: 'Bearer ' + token
		}
		const { status, data, error } = await axiosInstance.patch(ServerRoutes.getUsers + '/' + id, updateUser, { headers: headers });
		return { data: data, status: status, error: error };
	} catch (error) {
		console.error(JSON.stringify(error));
		const e = errorSchema.cast({ ...error });
		let message =
			"Some error happen, please try again. If the error returns please contact support";
		switch (e.response.status) {
			case 404:
				message = "user not found";
				break;
			case 500:
				message = "Server error. Please contact support";
				break;
			default:
				break;
		}
		return {
			error: message,
			status: e.response.status
		};
	}
};

const usersApi = {
	fetchUsers: fetchUsers,
	getUser: getUser,
	updateUser: updateUser
};

export default usersApi;