import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../utilities";

const initialState = {
  messages: [],
  error: null,
  loading: false
};

const messagesLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const getMessagesSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    messages: payload,
    error: null,
    loading: false
  });
};

const getMessagesFail = (state, action) => {
  return updateObject(state, {
    messages: [],
    error: action.error,
    loading: false
  });
};

const deleteMessageLoading = (state, action) => {
  return updateObject(state, {
    error: null
  });
};

const deleteMessageSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
};

const deleteMessageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const addMessageLoading = (state, action) => {
  return updateObject(state, {
    error: null
  });
};

const addMessageSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
};

const addMessageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MESSAGES_LOADING:return messagesLoading(state, action);
    case actionTypes.GET_MESSAGES_SUCCESS:return getMessagesSuccess(state, action);
    case actionTypes.GET_MESSAGES_FAIL:return getMessagesFail(state, action);
    case actionTypes.DELETE_MESSAGE_LOADING:return deleteMessageLoading(state, action);
    case actionTypes.DELETE_MESSAGE_SUCCESS:return deleteMessageSuccess(state, action);
    case actionTypes.DELETE_MESSAGE_FAIL:return deleteMessageFail(state, action);
    case actionTypes.ADD_MESSAGE_LOADING:return addMessageLoading(state, action);
    case actionTypes.ADD_MESSAGE_SUCCESS:return addMessageSuccess(state, action);
    case actionTypes.ADD_MESSAGE_FAIL:return addMessageFail(state, action);
    
    default:
      return state;
  }
};

export default reducer;
