import axiosInstance from "../../axios";
import ServerRoutes from "./routes/index";
import { errorSchema } from "./schemas/common-schemas";

const fetchFeeds = async (token) => {
    try {
        const url = ServerRoutes.getFeeds + '?auth=' + token + "&orderBy=\"rev\"&endAt=0&pageSize=100";
        const { status, data, error } = await axiosInstance.get(url);
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};


const deleteFeed = async (token, id) => {
    try {
        const url = ServerRoutes.updateFeed + id + '/.json?auth=' + token;
        const {status, data, error} = await axiosInstance.delete(url);
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const updateFeed = async (token, id, updateFeed) => {
    try {
        const url = ServerRoutes.updateFeed + '/' + id + "/.json?auth=" + token;
        const {status, data, error} = await axiosInstance.patch(url, updateFeed);
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const addFeed = async (token, newFeed) => {
    try {
        const url = ServerRoutes.getFeeds + '?auth=' + token;
        const { status, data, error } = await axiosInstance.post(url, newFeed);
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const feedsApi = {
    fetchFeeds: fetchFeeds,
    addFeed: addFeed,
    deleteFeed: deleteFeed,
    updateFeed: updateFeed
};

export default feedsApi;
