import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../utilities";

const initialState = {
    feeds: [],
    error: null,
    loading: false
};

const feedsLoading = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null
    });
};

const getFeedsSuccess = (state, action) => {
    const { payload } = action;
    return updateObject(state, {
        feeds: [...payload],
        error: null,
        loading: false
    });
};

const getFeedsFail = (state, action) => {
    return updateObject(state, {
        feeds: [],
        error: action.error,
        loading: false
    });
};

const deleteFeedLoading = (state, action) => {
    return updateObject(state, {
        error: null
    });
};

const deleteFeedSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false
    });
};

const deleteFeedFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateFeedLoading = (state, action) => {
    return updateObject(state, {
        error: null
    });
};

const updateFeedSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: false
    });
};

const updateFeedFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const addFeedLoading = (state, action) => {
    return updateObject(state, {
        error: null
    });
};

const addFeedSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
    });
};

const addFeedFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_FEEDS_LOADING: return feedsLoading(state, action);
        case actionTypes.GET_FEEDS_SUCCESS: return getFeedsSuccess(state, action);
        case actionTypes.GET_FEEDS_FAIL: return getFeedsFail(state, action);
        case actionTypes.DELETE_FEED_LOADING: return deleteFeedLoading(state, action);
        case actionTypes.DELETE_FEED_SUCCESS: return deleteFeedSuccess(state, action);
        case actionTypes.DELETE_FEED_FAIL: return deleteFeedFail(state, action);
        case actionTypes.UPDATE_FEED_LOADING: return updateFeedLoading(state, action);
        case actionTypes.UPDATE_FEED_SUCCESS: return updateFeedSuccess(state, action);
        case actionTypes.UPDATE_FEED_FAIL: return updateFeedFail(state, action);
        case actionTypes.ADD_FEED_LOADING: return addFeedLoading(state, action);
        case actionTypes.ADD_FEED_SUCCESS: return addFeedSuccess(state, action);
        case actionTypes.ADD_FEED_FAIL: return addFeedFail(state, action);

        default:
            return state;
    }
};

export default reducer;
