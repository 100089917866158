import styled from "styled-components";
import {StyledInput} from "../../../components/common/CommonStyledComponent";

const Users = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  max-width: 760px;
  padding: 10px;
  margin: 0 auto;
`;
const Table = styled.div`
  height: 100%;
  padding: 40px 40px 0 40px;
`;

const StyledInputNew = styled(StyledInput)`
  margin-left: 40px;
  margin-top: 31px;
  margin-bottom: 21px;
`;

export default {
  Users,
  Table,
  StyledInputNew
};
