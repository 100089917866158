import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Main, ButtonsTypesDiv, ButtonTypes, ButtonNew } from './NutritionsStyle'
import { Link } from 'react-router-dom'
import Routes from '../routes'
import { deleteMeal } from "../../../store/actions/nutritions/meal-actions";
import { useToasts } from "react-toast-notifications";
import MealDeletePopup from './MealDeletePopup'
import { getNutritions, cleanNutritions } from "../../../store/actions/nutritions/nutritions-actions";
import NutritionsTable from '../../../components/tables/Nutritions/NutritionsTable'
import { NutritionIdPath } from "../../../store/actions/nutritions/nutritions-actions"
import { FlexRow } from "../../../components/common/CommonStyledComponent";
const Nutritions = ({ history }) => {
    const [type, setType] = useState('breakfast')
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [nutritionId, setNutritionId] = useState(null);
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { nutritions, loading } = useSelector(state => state.nutritions);

    useEffect(() => {
        dispatch(getNutritions(type))

        return () => {
            dispatch(cleanNutritions());
        }
    }, [type])
    const onEdit = ({ props }) => {
        const id = props.name.replace(NutritionIdPath, '');
        history.push(Routes.Nutritions.updateMeal + '/' + id);
    };

    const onDelete = ({ props }) => {
        const name = props.name.replace(NutritionIdPath, '');
        setNutritionId(name);
        setShowDeletePopup(true);
    };

    const onDeletePopupButton = () => {
        dispatch(deleteMeal(nutritionId)).then(data => {
            if (!data.error) {
                dispatch(getNutritions(type));
                addToast("Deleted successfully", { appearance: "success", autoDismiss: true });
            } else {
                addToast("Error while deleting", { appearance: "error", autoDismiss: true });
            }
            setShowDeletePopup(false);
            setNutritionId(null);
        });
    }

    return (<Main>
        <ButtonsTypesDiv>
            <FlexRow>
                <ButtonNew as={Link} to={Routes.Nutritions.newMeal}>New</ButtonNew>
            </FlexRow>
            <FlexRow style={{flex: 2, justifyContent: 'center'}}>
                <ButtonTypes selected={type === 'breakfast'} onClick={() => setType('breakfast')}>Breakfast</ButtonTypes>
                <ButtonTypes selected={type === 'snack'} onClick={() => setType('snack')}>Snack</ButtonTypes>
                <ButtonTypes selected={type === 'lunch'} onClick={() => setType('lunch')}>Lunch</ButtonTypes>
                <ButtonTypes selected={type === 'dinner'} onClick={() => setType('dinner')}>Dinner</ButtonTypes>
            </FlexRow>

        </ButtonsTypesDiv>

        <NutritionsTable
            loading={loading}
            data={nutritions}
            onEdit={onEdit}
            onDelete={onDelete}
            defaultPageSize={20}
            minRows={20}
        />
        {showDeletePopup ? <MealDeletePopup onDelete={onDeletePopupButton} closeModal={() => setShowDeletePopup(false)} /> : null}
    </Main>)
}
export default Nutritions;