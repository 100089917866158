import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as _ from 'lodash';

import caretLeft from "../../../assets/images/icons/caret-left.svg";
import Routes from "../routes";
import { PageSection } from "../../../components/templates/PageSection";
import NewModalStyledComponents from '../../../components/common/NewModalStyledComponents'
import StyledSelect from "../../../components/ui/Select";
import { getUser, updateUserFromServer, cleanUser } from "../../../store/actions/users/user-actions";
import { FlexColumn, FlexRow } from "../../../components/common/CommonStyledComponent";

const options = [
    { value: "lose_fat", label: "Lose weight" },
    { value: "get_fitter", label: "Get fitter" },
    { value: "gain_muscle", label: "Gain muscles" }
];

const UpdateUser = (props) => {
    const userId = props.match.params.id;
    const dispatch = useDispatch();

    const user = useSelector(state => state.user.user);
    const { addToast } = useToasts();

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);

    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [height, setHeight] = useState(null);
    const [weight, setWeight] = useState(null);
    const [target, setTarget] = useState(options[0]);

    useEffect(() => {
        if (props.match.params.id) {
            dispatch(getUser(userId));
        }
        return () => {
            dispatch(cleanUser());
        }
    }, []);

    useEffect(() => {
        if (user && props.match.params.id) {
            setFirstName(_.get(user, ['fields', 'firstName', 'stringValue']));
            setLastName(_.get(user, ['fields', 'lastName', 'stringValue']));
            setDateOfBirth(new Date(_.get(user, ['fields', 'birthdate', 'timestampValue'])));
            setHeight(_.get(user, ['fields', 'height', 'mapValue', 'fields', 'value', 'integerValue']));
            setWeight(_.get(user, ['fields', 'weight', 'mapValue', 'fields', 'value', 'integerValue']));
            const t = _.get(user, ['fields', 'goal', 'stringValue']);
            const option = options.find(o => o.value === t);
            setTarget(option ? option : options[0]);
        }
    }, [user]);

    const onChanges = e => {
        if (e.target) {
            if (e.target.name === "firstName") {
                setFirstName(e.target.value);
            } else if (e.target.name === "lastName") {
                setLastName(e.target.value);
            } else if (e.target.name === "height") {
                setHeight(e.target.value);
            } else if (e.target.name === "weight") {
                setWeight(e.target.value);
            }
        }
    };

    const onChange = props => {
        setDateOfBirth(props);
    };

    const changeTarget = e => {
        setTarget(e);
    };

    const updateUserDetails = () => {
        const newUser = { 
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: dateOfBirth,
            height: height,
            weight: weight,
            target: target.value
         };
        dispatch(updateUserFromServer(userId, user, newUser)).then(data => {
            if (!data.error) {
                addToast("Update Successfully", { appearance: "success", autoDismiss: true });
                onBack();
            } else {
                addToast("Not updated!", { appearance: "error", autoDismiss: true });
            }
        });
    };

    const onBack = () => {
        if (props.history.length > 2) {
            props.history.goBack();
        }
        else {
            props.history.replace(Routes.Users.users);
        }
    }

    return (
        <PageSection>
            <NewModalStyledComponents.Form>
                <NewModalStyledComponents.Title>
                    <NewModalStyledComponents.LButtonNew style={{ color: "rgb(18,100,163)" }} onClick={onBack}>
                        <div>
                            <img style={{ paddingRight: "7px", paddingLeft: "16px" }} src={caretLeft} alt="caret-left" />
                            Back
                        </div>
                    </NewModalStyledComponents.LButtonNew>
                    <h3 style={{ paddingLeft: '2rem' }}>
                        Update user
                    </h3>
                </NewModalStyledComponents.Title>
                <FlexColumn style={{ padding: '0 10%' }}>
                    <NewModalStyledComponents.SubTitle>
                        First name
                    </NewModalStyledComponents.SubTitle>
                    <NewModalStyledComponents.StyledInputNew value={firstName || ""} onChange={onChanges} name="firstName" ></NewModalStyledComponents.StyledInputNew>
                    <NewModalStyledComponents.SubTitle>
                        Last name
                    </NewModalStyledComponents.SubTitle>
                    <NewModalStyledComponents.StyledInputNew value={lastName || ""} onChange={onChanges} name="lastName">
                    </NewModalStyledComponents.StyledInputNew>
                    <NewModalStyledComponents.SubTitle>
                        Birthday
                    </NewModalStyledComponents.SubTitle>
                    <DatePicker
                        className="red-border"
                        onChange={onChange}
                        selected={dateOfBirth}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormat="dd/MM/yyyy" />
                    <NewModalStyledComponents.SubTitle>
                        Height (cm)
                    </NewModalStyledComponents.SubTitle>
                    <NewModalStyledComponents.StyledInputNew
                        value={height || ""}
                        onChange={onChanges}
                        name="height">
                    </NewModalStyledComponents.StyledInputNew>
                    <NewModalStyledComponents.SubTitle>
                        Weight (kg)
                    </NewModalStyledComponents.SubTitle>
                    <NewModalStyledComponents.StyledInputNew
                        value={weight || ""}
                        onChange={onChanges}
                        name="weight">
                    </NewModalStyledComponents.StyledInputNew>
                    <NewModalStyledComponents.SubTitle>
                        Target
                    </NewModalStyledComponents.SubTitle>
                    <StyledSelect
                        isSearchable={false}
                        options={options}
                        value={target}
                        onChange={changeTarget}>
                    </StyledSelect>
                    <FlexRow style={{ justifyContent: 'center', padding: '20px 0' }}>
                        <NewModalStyledComponents.LButtoBlack
                            disabled={firstName && lastName && dateOfBirth && height && weight && target ? false : true}
                            onClick={updateUserDetails} >
                            Save
                        </NewModalStyledComponents.LButtoBlack>
                    </FlexRow>
                </FlexColumn>

            </NewModalStyledComponents.Form>
        </PageSection>
    );
};

export default withRouter(UpdateUser);
