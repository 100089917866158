import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const OutsideClick = (props) => {
	const wrappedRef = useRef(null);
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	const handleClickOutside = (event) => {
		if (wrappedRef.current && !wrappedRef.current.contains(event.target)) {
			props.callback();
		}
	}

	return (
		<div ref={wrappedRef}>{props.children}</div>
	);
}

OutsideClick.propTypes = {
	children: PropTypes.element.isRequired,
	callback: PropTypes.func.isRequired
};


export default OutsideClick;