import feedsApi from "../../api/feeds-api";
import * as actionTypes from '../actionTypes';


export const getFeedsLoading = () => {
    return {
        type: actionTypes.GET_FEEDS_LOADING
    };
};

export const getFeedsSuccess = feeds => {
    return {
        type: actionTypes.GET_FEEDS_SUCCESS,
        payload: feeds
    };
};

export const getFeedsFail = err => {
    return {
        type: actionTypes.GET_FEEDS_FAIL,
        error: err
    };
};

export const deleteFeedLoading = () => {
    return {
        type: actionTypes.DELETE_FEED_LOADING
    };
};

export const deleteFeedSuccess = feeds => {
    return {
        type: actionTypes.DELETE_FEED_SUCCESS,
        payload: feeds
    };
};

export const deleteFeedFail = err => {
    return {
        type: actionTypes.DELETE_FEED_FAIL,
        error: err
    };
};

export const updateFeedLoading = () => {
    return {
        type: actionTypes.UPDATE_FEED_LOADING
    };
};

export const updateFeedSuccess = feeds => {
    return {
        type: actionTypes.UPDATE_FEED_SUCCESS,
        payload: feeds
    };
};

export const updateFeedFail = err => {
    return {
        type: actionTypes.UPDATE_FEED_FAIL,
        error: err
    };
};

export const addFeedLoading = () => {
    return {
        type: actionTypes.ADD_FEED_LOADING
    };
};

export const addFeedSuccess = () => {
    return {
        type: actionTypes.ADD_FEED_SUCCESS,
    };
};

export const addFeedFail = (error) => {
    return {
        type: actionTypes.ADD_FEED_FAIL,
        error: error
    };
};

export const getFeeds = () => {
    return async (dispatch, getState) => {
        dispatch(getFeedsLoading());
        const { status, data, error } = await feedsApi.fetchFeeds(getState().auth.token);
        if (status === 200) {
            const feeds = Object.keys(data).map((k) => {
                return {
                    id: k,
                    ...data[k]
                }
            });
            dispatch(getFeedsSuccess(feeds));
        } else {
            dispatch(getFeedsFail(error));
        }
    };
};

export const deleteFeed = (id) => {
    return async (dispatch, getState) => {
        dispatch(deleteFeedLoading());
        const { status, data, error } = await feedsApi.deleteFeed(getState().auth.token, id);
        console.log(data);
        if (status === 200) {
            return dispatch(deleteFeedSuccess());
        } else {
            return dispatch(deleteFeedFail(error));
        }
    };
};

export const updateFeed = (id, updateFeed) => {
    return async (dispatch, getState) => {
        dispatch(updateFeedLoading());
        const { status, data, error } = await feedsApi.updateFeed(getState().auth.token, id, updateFeed);
        if (status === 200) {
            const feeds = data;
            return dispatch(updateFeedSuccess(feeds));
        } else {
            return dispatch(updateFeedFail(error));
        }
    };
};

export const addFeed = (newFeed) => {
    return async (dispatch, getState) => {
        dispatch(addFeedLoading());
        const { status, data, error } = await feedsApi.addFeed(getState().auth.token, newFeed);
        if (status === 200 && data.name) {
            return dispatch(addFeedSuccess());
        } else {
            return dispatch(addFeedFail(error));
        }
    };
};
