import React from "react";
import styled from "styled-components";
import caretLeft from "../../assets/images/icons/caret-left.svg";
import caretRight from "../../assets/images/icons/caret-right.svg";
import PropTypes from "prop-types";


const Pagination = (props) => {
	const getVisiblePages = (page, total) => {
		if (total < props.maximumToShow) {
			return Array.from({ length: total }, (v, k) => k + 1);
		}
		else {
			if (page % 5 >= 0 && page > 4 && page + 2 < total) {
				return [1, -1, page - 1, page, page + 1, -1, total];
			} else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
				return [1, -1, total - 3, total - 2, total - 1, total];
			} else {
				return [...Array.from({ length: props.maximumToShow - 2 }, (v, k) => k + 1), -1, total];
			}
		}
	}

	const nextPage = (page) => {
		if (page === props.page) {
			return
		}
		props.onPageChange(page);
	}

	const pages = getVisiblePages(props.page + 1, props.pages)
  
  return (
    <>
			<PageButton disabled={props.page === 0} onClick={() => nextPage(props.page - 1)}><img src={caretLeft} alt="<" /></PageButton>
			{pages.map((v, i) => {
				return v === -1 ?
					<PageButton key={'page_button' + i} disabled>...</PageButton> :
					<PageButton onClick={() => nextPage(v - 1)} key={'page_button_' + i} className={(v - 1) === props.page ? 'active' : ''}>{v}</PageButton>
			})}
			<PageButton disabled={props.page === props.pages - 1} onClick={() => nextPage(props.page + 1)}>
				{/* <img src={caretRight} alt=">" /> */}
				<svg fill="#565656" width="8px" height="12px"><use xlinkHref={`${caretRight}#UI-Elements`} /></svg>
			</PageButton>
		</>      
  );
};

Pagination.propTypes = {
  maximumToShow: PropTypes.number,
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  maximumToShow: 7
};

const PageButton = styled.button`
  border: 1px solid #eaeaea;
  height: 42px;
  min-width: 46px;
  font-family: InterUI-Regular;
  font-size: 16px;
  color: var(--neutral-60p);
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: var(--neutral-20p);
  }
  &:disabled {
    background: none;
  }
  &:focus {
    outline: none;
  }
  &.active {
    background: var(--primary-blue);
    color: white;
  }
`;

/**
 *
 * @param {{page: number, total: number}} metadata
 * @param {number} limit
 */

export const getPage = (metadata, limit) => {
	if (metadata.page - 1 > 0 && Math.ceil((metadata.total - 1) / limit) < metadata.page) {
		return metadata.page - 1;
	} else {
		return metadata.page;
	}
}

export default Pagination;
