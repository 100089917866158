import * as actionTypes from "../../actions/actionTypes";
import {updateObject} from "../../utilities";

const initialState = {
  exercise: null,
  error: null,
  loading: false
};

const exerciseLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const getExerciseSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    exercise: payload,
    error: null,
    loading: false
  });
};

const getExerciseFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const deleteExerciseLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const deleteExerciseSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    exercises: payload,
    error: null,
    loading: false
  });
};

const deleteExerciseFail = (state, action) => {
  return updateObject(state, {
    exercises: [],
    error: action.error,
    loading: false
  });
};

const updateExerciseLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const updateExerciseSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    exercises: payload,
    error: null,
    loading: false
  });
};

const updateExerciseFail = (state, action) => {
  return updateObject(state, {
    exercises: [],
    error: action.error,
    loading: false
  });
};

const addExerciseLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const addExerciseSuccess = (state, action) => {
  const {payload} = action;
  return updateObject(state, {
    exercises: payload,
    error: null,
    loading: false
  });
};

const addExerciseFail = (state, action) => {
  return updateObject(state, {
    exercises: [],
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EXERCISE_LOADING:return exerciseLoading(state, action);
    case actionTypes.GET_EXERCISE_SUCCESS:return getExerciseSuccess(state, action);
    case actionTypes.GET_EXERCISE_FAIL:return getExerciseFail(state, action);
    case actionTypes.DELETE_EXERCISE_LOADING:return deleteExerciseLoading(state, action);
    case actionTypes.DELETE_EXERCISE_SUCCESS:return deleteExerciseSuccess(state, action);
    case actionTypes.DELETE_EXERCISE_FAIL:return deleteExerciseFail(state, action);
    case actionTypes.UPDATE_EXERCISE_LOADING:return updateExerciseLoading(state, action);
    case actionTypes.UPDATE_EXERCISE_SUCCESS:return updateExerciseSuccess(state, action);
    case actionTypes.UPDATE_EXERCISE_FAIL:return updateExerciseFail(state, action);
    case actionTypes.ADD_EXERCISE_LOADING:return addExerciseLoading(state, action);
    case actionTypes.ADD_EXERCISE_SUCCESS:return addExerciseSuccess(state, action);
    case actionTypes.ADD_EXERCISE_FAIL:return addExerciseFail(state, action);
    default:
      return state;
  }
};

export default reducer;
