export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const RequestState = {
    prepare: 'prepare',
    start: 'start',
    processing: 'processing',
    finish: 'finish',
    error: 'error',
    canceled: 'canceled'
}

export const Operation = {
    create: 'create',
    read: 'read',
    delete: 'delete',
    update: 'update'
}