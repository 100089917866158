import axiosInstance from "../../axios";
import ServerRoutes from "./routes/index";
import { errorSchema } from "./schemas/common-schemas";

const fetchPlanQuery = {
    "structuredQuery": {
        "select": {
            "fields": [
                {
                    "fieldPath": "name"
                },
                {
                    "fieldPath": "description"
                },
                {
                    "fieldPath": "image"
                },
                {
                    "fieldPath": "video"
                },
                {
                    "fieldPath": "level"
                },
                {
                    "fieldPath": "location"
                },
                {
                    "fieldPath": "trainingsPerWeek"
                },
                {
                    "fieldPath": "weeks"
                }
            ]
        },
        "from": [
            {
                "collectionId": "plans",
                "allDescendants": false
            }
        ],
        "orderBy": [
            {
                "field": {
                    "fieldPath": "name.he"
                },
                "direction": "ASCENDING"
            }
        ],
        offset: 0,
        limit: 100
    }
};


const fetchPlans = async (token, letterSearch) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const { status, data, error } = await axiosInstance.post(ServerRoutes.globalQuery, fetchPlanQuery, { headers: headers });
        const result = data.map(d=> d.document).filter(d => d !== undefined);;
        
        return { data: result, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};
const addPlan = async (token, newPlan) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const { status, data, error } = await axiosInstance.post(ServerRoutes.getPlans, newPlan, { headers: headers });
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const fetchPlan = async id => {
    try {
        const {status, data, error} = await axiosInstance.get(ServerRoutes.devices);
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const deletePlan = async (token, id) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const { status, data, error } = await axiosInstance.delete(ServerRoutes.getPlans + '/' + id, { headers: headers });
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "devices not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const updatePlan = async (token, id, updatePlan) => {
    try {
        const headers = {
            Authorization: 'Bearer ' + token
        }
        const { status, data, error } = await axiosInstance.patch(ServerRoutes.getPlans + '/' + id, updatePlan, { headers: headers });
        return { data: data, status: status, error: error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message =
            "Some error happen, please try again. If the error returns please contact support";
        switch (e.response.status) {
            case 404:
                message = "plan not found";
                break;
            case 500:
                message = "Server error. Please contact support";
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status
        };
    }
};

const plansApi = {
    fetchPlans: fetchPlans,
    fetchPlan: fetchPlan,
    addPlan: addPlan,
    deletePlan: deletePlan,
    updatePlan: updatePlan
};

export default plansApi;
